import { v4 as uuidV4 } from 'uuid';
import { ApplicationJson, ContentType, Header, Method, StatusCode } from '../../constants/http';
import { DataResponse, FailureOperation, SuccessResponse } from '../../models/common';
import {
    createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault,
    tryParseErrorResponse,
} from '../../utilities/failure';
import { trackTrace } from '../../utilities/telemetry/channel';
import { getSpanId, getTraceId } from '../../utilities/telemetry/helpers';
import { formatTraceparentHeader } from '../../utilities/tracing';
import { DefaultRetryOptions, RetryWrapperOptions, requestAndRetry } from './request-and-retry';

type RequestInitType = Omit<RequestInit, 'accept' | 'contentType' | 'acceptLanguage' | 'body'>;

export interface FetchOptions<TValue = undefined> extends Omit<RequestInitType, 'mode'> {
    accept?: ContentType;
    acceptLanguage?: string;
    activityId?: string;
    body?: BodyInit;
    contentType?: ContentType;
    retryOptions?: FetchRetryOptions<TValue>;
    removeCorrelationHeaders?: boolean;
    accessToken?: string;
}

export interface FetchRetryOptions<TValue = undefined> extends RetryWrapperOptions<TValue> {
    statusCodes?: StatusCode[];
}

// We want to retry on all TypeErrors. These are transport layer errors.
const retryOnExceptionPredicate = (err: unknown) => err instanceof TypeError;

const createHeaders = (options: FetchOptions<Response> | undefined): Headers => {
    const headers = new Headers(options?.headers);

    if (options?.accessToken) {
        headers.append(Header.Authorization, `Bearer ${options.accessToken}`);
    }

    if (options?.accept) {
        headers.append(Header.Accept, options.accept);
    }

    if (options?.acceptLanguage) {
        headers.append(Header.AcceptLanguage, options.acceptLanguage);
    }

    if (options?.activityId) {
        headers.append(Header.ActivityId, options.activityId);
    }

    if (options?.contentType) {
        headers.append(Header.ContentType, options.contentType);
    }

    if (!options?.removeCorrelationHeaders) {
        // Add custom traceparent header
        headers.append(Header.Traceparent, formatTraceparentHeader(getTraceId(), getSpanId()));

        // Manage our own client request ID (so we know if the request hits the server)
        headers.append(Header.ClientRequestId, uuidV4());
    }

    return headers;
};

const createRetryPredicate = (statusCodes?: StatusCode[]): ((response: Response) => boolean) | undefined =>
    statusCodes !== undefined
        ? (response) => statusCodes.findIndex((statusCode) => statusCode === response.status) > -1
        : undefined;

const createOnRetry =
    (url: string): ((err: unknown, tries: number, retries: number, waitTimeInMs: number) => void) =>
    (_err, tries, retries, waitTimeInMs) =>
        trackTrace(
            `Retrying failed request for url ${url}, tries: ${tries}, retries: ${retries}, wait time (ms): ${waitTimeInMs}`
        );

export interface HandleFetchResponseOptions {
    successStatusCodes?: number[];
}

export interface FetchAndHandleResponseOptions<TValue = undefined> extends FetchOptions<TValue> {
    handleResponseOptions?: HandleFetchResponseOptions;
}

// TODO: use this for graph and ARM calls [Task #1913148]
export const fetchAndHandleResponse = async <TData = void>(
    url: string,
    method: Method,
    operation: FailureOperation,
    options?: FetchAndHandleResponseOptions<Response>
): Promise<DataResponse<TData>> => {
    const response = await fetchRequest(url, method, options);

    return await handleFetchResponse(response, operation, options?.handleResponseOptions);
};

export const fetchAndHandleTextStreamResponse = async (
    url: string,
    method: Method,
    operation: FailureOperation,
    options?: FetchAndHandleResponseOptions<Response>
): Promise<DataResponse<string>> => {
    const response = await fetchRequest(url, method, options);

    return await handleFetchResponse(response, operation, options?.handleResponseOptions, getTextStreamResponseData);
};

// TODO: in future, consider whether we want to make application/json the default Accept
export const fetchRequest = async (
    url: string,
    method: Method,
    options?: FetchOptions<Response>
): Promise<Response> => {
    const retryOptions: FetchRetryOptions<Response> = options?.retryOptions ?? DefaultRetryOptions<Response>();
    const { statusCodes } = retryOptions;
    const retryOnResponsePredicate = createRetryPredicate(statusCodes);
    const onRetry = createOnRetry(url);

    const result = await requestAndRetry(
        {
            ...retryOptions,
            retryOnResponsePredicate,
            retryOnExceptionPredicate,
            onRetry,
        },
        () =>
            fetch(url, {
                ...(options?.body ? { body: options.body } : {}),
                headers: createHeaders(options),
                method,
                mode: 'cors',
            })
    );

    return result;
};

const getTextStreamResponseData = async (response: Response, headers: Headers): Promise<string | undefined> => {
    // get any content that is octet stream content
    const responseContentType = headers?.get(Header.ContentType);

    return responseContentType?.includes(ContentType.ApplicationOctetStream) ? await response.text() : undefined;
};

export const handleFetchResponse = async <TData = void>(
    response: Response,
    failureOperation: FailureOperation,
    handleResponseOptions?: HandleFetchResponseOptions,
    getResponseData?: (response: Response, headers: Headers) => Promise<TData | undefined>
): Promise<DataResponse<TData>> => {
    const { status, headers } = response;
    const { successStatusCodes } = handleResponseOptions ?? {};
    const succeeded = successStatusCodes ? successStatusCodes.includes(status) : status > 199 && status < 300;

    if (!succeeded) {
        const errorResponse = await tryParseErrorResponse(response);
        return createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault(errorResponse, failureOperation, status);
    }

    let data: TData | undefined;

    if (getResponseData) {
        data = await getResponseData(response, headers);
    } else {
        // Default to JSON - get any content that is JSON content
        const responseContentType = headers?.get(Header.ContentType);
        data = responseContentType?.includes(ApplicationJson) ? ((await response.json()) as TData) : undefined;
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    // Justification: TypeGuard pain
    if (data !== undefined) {
        return {
            data,
            succeeded: true,
        } as any as SuccessResponse<TData>;
    }

    return {
        succeeded: true,
    } as any as SuccessResponse<TData>;
    /* eslint-enable @typescript-eslint/no-explicit-any */
};
