import { ApiVersion } from '../../../constants/azure';
import { ContentType, Method } from '../../../constants/http';
import { getTokensFromCustomizationGroupDataPlaneUri } from '../../../ids/customization-group';
import { getTokensFromCustomizationTaskDefinitionDataPlaneUri } from '../../../ids/customization-task-definition';
import { getTokensFromCustomizationTaskLogDataPlaneUri } from '../../../ids/customization-task-log';
import { getTokensFromDevBoxDataPlaneUri } from '../../../ids/dev-box';
import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import {
    createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault,
    tryParseErrorResponse,
} from '../../../utilities/failure';
import {
    CustomizationGroupContract,
    CustomizationTaskDefinitionContract,
    CustomizationTaskListContract,
    CustomizationTaskListValidationOperationResponseContract,
    PutCustomizationGroupBodyContract,
} from '../../contracts/customization';
import { FetchOptions, fetchRequest } from '../fetch-request';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type ListCustomizationGroupsResponse = DataResponse<CustomizationGroupContract[]>;
export type GetCustomizationGroupResponse = DataResponse<CustomizationGroupContract>;
export type CreateCustomizationGroupResponse = DataResponse<CustomizationGroupContract>;
export type ListCustomizationTaskDefinitionsResponse = DataResponse<CustomizationTaskDefinitionContract[]>;
export type GetCustomizationTaskDefinitionResponse = DataResponse<CustomizationTaskDefinitionContract>;
export type ValidateCustomizationTasksResponse = DataResponse<CustomizationTaskListValidationOperationResponseContract>;
export type GetCustomizationTaskLogResponse = DataResponse<Blob>;

export const listCustomizationGroups = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListCustomizationGroupsResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListCustomizationGroups,

        whenUsingBetaClient: (client) => {
            const options = { include: 'tasks', ...getCommonOptions(accessToken, activityId) };
            return client.devBoxes.listCustomizationGroups(projectName, user, devBoxName, options);
        },

        whenUsingStableClient: (client) => {
            const options = { include: 'tasks', ...getCommonOptions(accessToken, activityId) };
            return client.devBoxes.listCustomizationGroups(projectName, user, devBoxName, options);
        },
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: name and uri should always be defined on responses.
    const customizationGroups = data.map<CustomizationGroupContract>((value) => ({
        ...value,
        uri: value.uri!,
        name: value.name!,
        tasks: value.tasks?.map((task) => ({ ...task, logUri: task.logUri!, id: task.id! })),
    }));
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: customizationGroups, succeeded: true };
};

export const getCustomizationGroup = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetCustomizationGroupResponse> => {
    const { devBoxName, devCenter, projectName, customizationGroupName, user } =
        getTokensFromCustomizationGroupDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetCustomizationGroup,

        whenUsingBetaClient: (client) =>
            client.devBoxes.getCustomizationGroup(
                projectName,
                user,
                devBoxName,
                customizationGroupName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.devBoxes.getCustomizationGroup(
                projectName,
                user,
                devBoxName,
                customizationGroupName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: name and uri should always be defined on responses.
    const customizationGroup: CustomizationGroupContract = {
        ...data,
        name: data.name!,
        uri: data.uri!,
        tasks: data.tasks?.map((task) => ({ ...task, logUri: task.logUri!, id: task.id! })),
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: customizationGroup, succeeded: true };
};

export const createCustomizationGroup = async (
    id: string,
    accessToken: string,
    body: PutCustomizationGroupBodyContract,
    activityId?: string
): Promise<CreateCustomizationGroupResponse> => {
    const { devBoxName, devCenter, projectName, customizationGroupName, user } =
        getTokensFromCustomizationGroupDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.CreateCustomizationGroup,

        whenUsingBetaClient: (client) =>
            client.devBoxes.createCustomizationGroup(
                projectName,
                user,
                devBoxName,
                customizationGroupName,
                body,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.devBoxes.createCustomizationGroup(
                projectName,
                user,
                devBoxName,
                customizationGroupName,
                body,
                getCommonOptions(accessToken, activityId)
            ),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: name and uri should always be defined on responses.
    const customizationGroup: CustomizationGroupContract = {
        ...data,
        name: data.name!,
        uri: data.uri!,
        tasks: data.tasks?.map((task) => ({ ...task, name: task.name, logUri: task.logUri!, id: task.id! })),
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: customizationGroup, succeeded: true };
};

export const listCustomizationTaskDefinitions = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListCustomizationTaskDefinitionsResponse> => {
    const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListCustomizationTaskDefinitions,

        whenUsingBetaClient: (client) =>
            client.devBoxes.listCustomizationTaskDefinitionsByProject(
                projectName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.devBoxes.listCustomizationTaskDefinitionsByProject(
                projectName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: uri should always be defined on responses.
    const customizationTaskDefinitions = data.map<CustomizationTaskDefinitionContract>((value) => ({
        ...value,
        uri: value.uri!,
    }));
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: customizationTaskDefinitions, succeeded: true };
};

export const getCustomizationTaskDefinition = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetCustomizationTaskDefinitionResponse> => {
    const { devCenter, projectName, catalogName, customizationTaskName } =
        getTokensFromCustomizationTaskDefinitionDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetCustomizationTaskDefinition,

        whenUsingBetaClient: (client) =>
            client.devBoxes.getCustomizationTaskDefinitions(
                projectName,
                catalogName,
                customizationTaskName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.devBoxes.getCustomizationTaskDefinitions(
                projectName,
                catalogName,
                customizationTaskName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: uri should always be defined on responses.
    const customizationTaskDefinition: CustomizationTaskDefinitionContract = {
        ...data,
        uri: data.uri!,
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: customizationTaskDefinition, succeeded: true };
};

export const validateCustomizationTasks = async (
    projectId: string,
    accessToken: string,
    body: CustomizationTaskListContract,
    activityId?: string
): Promise<ValidateCustomizationTasksResponse> => {
    const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(projectId);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.ValidateCustomizationTasks,

        whenUsingBetaClient: (client) =>
            client.devBoxes.validateCustomizationTasksAction(
                projectName,
                body,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.devBoxes.validateCustomizationTasksAction(
                projectName,
                body,
                getCommonOptions(accessToken, activityId)
            ),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: operationLocation should always be defined on responses.
    const validateCustomizationTasksResult: CustomizationTaskListValidationOperationResponseContract = {
        ...data,
        operationLocation: data.operationLocation!,
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: validateCustomizationTasksResult, succeeded: true };
};

export const getCustomizationTaskLog = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetCustomizationTaskLogResponse> => {
    const { devCenter, projectName, user, devBoxName, customizationGroupName, customizationTaskId } =
        getTokensFromCustomizationTaskLogDataPlaneUri(id);

    const options: FetchOptions<Response> = {
        activityId,
        contentType: ContentType.TextPlain,
        accessToken,
    };

    const result = await fetchRequest(
        `${devCenter}/projects/${projectName}/users/${user}/devboxes/${devBoxName}/customizationGroups/${customizationGroupName}/logs/${customizationTaskId}?api-version=${ApiVersion.DevCenterDataPlaneStable}`,
        Method.GET,
        options
    );

    if (result.status !== 200) {
        const errorResponse = await tryParseErrorResponse(result);
        return createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault(
            errorResponse,
            FailureOperation.GetCustomizationTaskLog
        );
    }

    return {
        data: await result.blob(),
        succeeded: true,
    };
};
