import { SagaIterator } from 'redux-saga';
import { call, cancelled, put } from 'redux-saga/effects';
import { Severity } from '../../../constants/telemetry';
import {
    ValidateCustomizationTasksResponse,
    validateCustomizationTasks,
} from '../../../data/services/data-plane-api/customization';
import { ClientError, FailureOperation, FailureResponse, isFailureResponse } from '../../../models/common';
import { LongRunningOperationStatus } from '../../../models/long-running-operation';
import { createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault } from '../../../utilities/failure';
import { trackTrace } from '../../../utilities/telemetry/channel';
import {
    clearValidationResult,
    validateCustomizationTasks as validateCustomizationTasksActionCreator,
    validateCustomizationTasksError,
    validateCustomizationTasksFailed,
    validateCustomizationTasksSuccess,
} from '../../actions/customization/customization-action-creators';
import {
    CustomizationActionType,
    ValidateCustomizationTasksAction,
} from '../../actions/customization/customization-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { pollOperationStatus } from '../../actions/long-running-operation/long-running-operation-action-creators';
import { createCancelableSaga } from '../../effects/cancelable';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeLatest } from '../../effects/take';

export function* validateCustomizationTasksSaga(action: ValidateCustomizationTasksAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { projectId, tasks } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ValidateCustomizationTasksResponse = yield call(
            validateCustomizationTasks,
            projectId,
            accessToken,
            { tasks },
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(validateCustomizationTasksFailed({ failure: response }, meta));
            return;
        }

        const { data } = response;
        const { operationLocation, resourceId } = data;

        const result: ValidateCustomizationTasksResponse = yield putAndAwait(
            pollOperationStatus({ operationLocation }, meta)
        );

        if (isFailureResponse(result)) {
            yield put(validateCustomizationTasksFailed({ failure: result }, meta));
            return;
        }

        const { data: resultData } = result;
        const { status, error, result: validateCustomizationsResult } = resultData;
        switch (status) {
            case LongRunningOperationStatus.Succeeded:
                if (!validateCustomizationsResult) {
                    const message =
                        'Unexpected state: successfully completed operation but no result from validating customization tasks';
                    yield call(trackTrace, message, {
                        properties: { resource: resourceId },
                        severity: Severity.Warning,
                    });

                    const failure = FailureResponse({
                        message,
                        operation: FailureOperation.ValidateCustomizationTasks,
                    });

                    yield put(validateCustomizationTasksFailed({ failure }, meta));
                    break;
                }

                yield put(validateCustomizationTasksSuccess({ result: validateCustomizationsResult }, meta));

                break;
            case LongRunningOperationStatus.Failed:
            default:
                const failure = createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault(
                    error,
                    FailureOperation.ValidateCustomizationTasks
                );

                yield put(validateCustomizationTasksFailed({ failure }, meta));
                break;
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ValidateCustomizationTasks);
        yield put(validateCustomizationTasksError({ error }, meta));
    } finally {
        if (yield cancelled()) {
            // Dispatching `clearValidationResult` on cancelled as it resets the validation state and result after saga has been cancelled
            yield put(clearValidationResult());
        }
    }
}

export function* validateCustomizationTasksListenerSaga(): SagaIterator {
    yield takeLatest(
        validateCustomizationTasksActionCreator,
        createCancelableSaga(validateCustomizationTasksSaga, CustomizationActionType.ClearValidationResult)
    );
}
