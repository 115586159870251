import { environmentOperationAdapter, environmentOperationLogAdapter } from '../adapters/environment-operation-adapter';
import { StoreState } from '../store/store-state';
import { createMapFromEntitiesSelector } from './common';

/**
 * Entity state selectors
 */

const environmentOperationSelectors = environmentOperationAdapter.getSelectors<StoreState>(
    (store) => store.environmentOperationsStore.data.operations
);

const environmentOperationLogSelectors = environmentOperationLogAdapter.getSelectors<StoreState>(
    (store) => store.environmentOperationsStore.data.logUris
);

/**
 * Composed selectors
 */

export const getEnvironmentOperations = createMapFromEntitiesSelector(environmentOperationSelectors.selectAll);

export const getEnvironmentOperationLogs = createMapFromEntitiesSelector(environmentOperationLogSelectors.selectAll);
