import * as React from 'react';
import { SurfaceContextWithProperties } from '../../../types/contexts';
import { createSurfaceContextWithPropertiesProvider } from '../../common/surface-context-provider';
import { DeploymentLogsPanelProps } from './deployment-logs-panel';

export type DeploymentLogsPanelContextProperties = Omit<DeploymentLogsPanelProps, 'isOpen' | 'onDismiss'>;

export const DeploymentLogsPanelContext = React.createContext<
    SurfaceContextWithProperties<DeploymentLogsPanelContextProperties>
>({ isOpen: false });

export const DeploymentLogsPanelContextProvider =
    createSurfaceContextWithPropertiesProvider<DeploymentLogsPanelContextProperties>(DeploymentLogsPanelContext);
