import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import { discoverEnvironmentOperationsForEnvironmentListenerSaga } from './discover-environment-operations-for-environment';
import { listEnvironmentOperationsListenerSaga } from './list-environment-operations';
import { getEnvironmentOperationLogListenerSaga } from './get-environment-operation-log';

export function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
    yield all([
        fork(discoverEnvironmentOperationsForEnvironmentListenerSaga),
        fork(getEnvironmentOperationLogListenerSaga),
        fork(listEnvironmentOperationsListenerSaga),
    ]);
}
