import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity } from '../../models/common';
import {
    discoverEnvironmentOperationsForEnvironment,
    discoverEnvironmentOperationsForEnvironmentError,
    discoverEnvironmentOperationsForEnvironmentFailed,
    discoverEnvironmentOperationsForEnvironmentSuccess,
    getEnvironmentOperationLog,
    getEnvironmentOperationLogError,
    getEnvironmentOperationLogFailed,
    getEnvironmentOperationLogSuccess,
    listEnvironmentOperations,
    listEnvironmentOperationsError,
    listEnvironmentOperationsFailed,
    listEnvironmentOperationsSuccess,
} from '../actions/environment-operation/environment-operation-action-creators';
import { environmentOperationAdapter, environmentOperationLogAdapter } from '../adapters/environment-operation-adapter';
import {
    EnvironmentOperationDataStore,
    EnvironmentOperationStatusStore,
    EnvironmentOperationsStore,
} from '../store/environment-operation-store';
import { getPayload } from '../utilities/payload-action';
import { createIndexedStatusReducer } from './indexed-status-reducer';
import { createStatusReducer } from './status-reducer';

export const environmentOperationReducer = combineReducers<EnvironmentOperationsStore>({
    data: createReducer(EnvironmentOperationDataStore(), (builder) => {
        builder
            .addCase(listEnvironmentOperationsSuccess, (store, action) => {
                const { id, result } = getPayload(action);

                environmentOperationAdapter.setOne(store.operations, Entity(id, result));
            })
            .addCase(getEnvironmentOperationLogSuccess, (store, action) => {
                const { id, result } = getPayload(action);

                environmentOperationLogAdapter.setOne(store.logUris, Entity(id, result));
            });
    }),

    status: combineReducers<EnvironmentOperationStatusStore>({
        discoverEnvironmentOperationsForEnvironment: createStatusReducer({
            inProgress: discoverEnvironmentOperationsForEnvironment,
            error: discoverEnvironmentOperationsForEnvironmentError,
            failed: discoverEnvironmentOperationsForEnvironmentFailed,
            success: discoverEnvironmentOperationsForEnvironmentSuccess,
        }),

        getEnvironmentOperationLog: createIndexedStatusReducer({
            inProgress: getEnvironmentOperationLog,
            error: getEnvironmentOperationLogError,
            failed: getEnvironmentOperationLogFailed,
            success: getEnvironmentOperationLogSuccess,
        }),

        listEnvironmentOperations: createIndexedStatusReducer({
            inProgress: listEnvironmentOperations,
            error: listEnvironmentOperationsError,
            failed: listEnvironmentOperationsFailed,
            success: listEnvironmentOperationsSuccess,
        }),
    }),
});
