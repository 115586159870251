import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import {
    CreateCustomizationGroupResponse,
    GetCustomizationGroupResponse,
    createCustomizationGroup,
} from '../../../data/services/data-plane-api/customization';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { CustomizationGroupStatus, CustomizationTask } from '../../../models/customization';
import { createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../utilities/telemetry/helpers';
import {
    createCustomizationGroupAccepted,
    createCustomizationGroup as createCustomizationGroupActionCreator,
    createCustomizationGroupError,
    createCustomizationGroupFailed,
    createCustomizationGroupSuccess,
    getCustomizationTaskLog,
    pollNonTerminalCustomizationGroup,
} from '../../actions/customization/customization-action-creators';
import {
    CreateCustomizationGroupAcceptedAction,
    CreateCustomizationGroupAction,
} from '../../actions/customization/customization-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery, takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';

export function* createCustomizationGroupSaga(action: CreateCustomizationGroupAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id, name, tasks } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: CreateCustomizationGroupResponse = yield call(
            createCustomizationGroup,
            id,
            accessToken,
            { name, tasks },
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(createCustomizationGroupFailed({ failure: response, id }, meta));

            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.CreateCustomizationGroup,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, response.code)
            );

            return;
        }

        yield put(createCustomizationGroupAccepted({ id, result: response.data }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.CreateCustomizationGroup,
            startTime,
            AsyncOutcome.Success,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.CreateCustomizationGroup);
        yield put(createCustomizationGroupError({ error, id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.CreateCustomizationGroup,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* createCustomizationGroupAcceptedSaga(action: CreateCustomizationGroupAcceptedAction): SagaIterator {
    const { meta, payload } = action;
    const { id } = payload;

    try {
        const response: GetCustomizationGroupResponse = yield putAndAwait(pollNonTerminalCustomizationGroup({ id }));

        if (isFailureResponse(response)) {
            yield put(createCustomizationGroupFailed({ failure: response, id }, meta));
            return;
        }

        const { data } = response;
        const status = data.status;
        switch (status) {
            case CustomizationGroupStatus.Succeeded:
                yield put(createCustomizationGroupSuccess({ id, result: data }, meta));

                const customizationTasks: CustomizationTask[] | undefined = data.tasks;

                // Get all the customization task logs into the store on creation success
                if (customizationTasks) {
                    yield all(
                        customizationTasks.map((task) =>
                            put(
                                getCustomizationTaskLog({
                                    id: task.logUri,
                                })
                            )
                        )
                    );
                }

                break;
            default:
                // No error body in response, so just pass undefined to function
                const failure = createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault(
                    undefined,
                    FailureOperation.CreateCustomizationGroup
                );

                yield put(createCustomizationGroupFailed({ failure, id }, meta));
                break;
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.CreateCustomizationGroup);
        yield put(createCustomizationGroupError({ error, id }, meta));
    }
}

export function* createCustomizationGroupListenerSaga(): SagaIterator {
    yield takeLeading(createCustomizationGroupActionCreator, createCustomizationGroupSaga);
}

export function* createCustomizationGroupAcceptedListenerSaga(): SagaIterator {
    yield takeEvery(createCustomizationGroupAccepted, createCustomizationGroupAcceptedSaga);
}
