import {
    IDropdown,
    IDropdownProps,
    ISelectableDroppableTextProps,
    SearchBox,
    Separator,
    makeStyles as legacyMakeStyles,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { getLocalizationConfiguration } from '../../../../language/languages';
import { AppSemanticColor } from '../../../../themes/app-semantic-colors';
import { useStackStyles } from '../../../../themes/styles/flexbox-styles';
import { RequiredProperty } from '../../../../types/required-property';
import { caseInsensitiveLocaleAwareIncludes, isNotUndefinedOrWhiteSpace } from '../../../../utilities/string';
import { getSemanticColor } from '../../../../utilities/styles';
import { DefaultRenderFunction } from '../../types';
import {
    DropdownOption as BaseDropdownOption,
    Dropdown,
    DropdownDividerOption,
    DropdownHeaderOption,
    DropdownProps,
    isDropdownDividerOption,
    isDropdownHeaderOption,
} from './dropdown';

const messages = defineMessages({
    searchPlaceholder: {
        id: 'ValueDropdown_Search_Placeholder',
        defaultMessage: 'Search',
        description: 'Placeholder for search for value dropdown',
    },
    searchAriaLabel: {
        id: 'ValueDropdown_Search_AriaLabel',
        defaultMessage: 'Search',
        description: 'Aria label for search for value dropdown',
    },
});

/**
 * Styles
 */

const useSeparatorMessageStyles = makeStyles({
    root: {
        maxHeight: '1px',
    },
});

const useNoResultsTextStyles = legacyMakeStyles((theme) => ({
    root: {
        backgroundColor: getSemanticColor(theme, AppSemanticColor.transparentBackground),
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        width: '100%',
        minHeight: 36,
        lineHeight: 20,
        textAlign: 'left',
    },
}));

/**
 * End Styles
 */

/* eslint-disable @typescript-eslint/ban-types */
// Justification: Record and string map types are not generic enough
type ValueDropdownValue = object | number;
/* eslint-enable @typescript-eslint/ban-types */

export type DropdownOption<TValue extends ValueDropdownValue, TData = undefined> = BaseDropdownOption<TValue, TData>;

interface ValueDropdownBaseProps<TValue extends ValueDropdownValue, TData = undefined>
    extends Omit<DropdownProps<TValue, TData>, 'selectedKey'> {
    value: TValue | undefined;
    getOptionKey: (value: TValue) => string;
    getIsOptionHidden?: (value: TValue) => boolean;
    getIsOptionDisabled?: (value: TValue) => boolean;
}

/**
 * Props for options that can use simple text based rendering when displaying each option.
 * getOptionText is required to resolve what this text should be per option.
 */
type BasicRenderValueDropdownBaseProps<TValue extends ValueDropdownValue, TData> = Omit<
    ValueDropdownBaseProps<TValue, TData>,
    'options'
> & { getOptionText: (value: TValue) => string; onRenderOption?: never };

/**
 * Props for options that utilize custom rendering functions when displaying each option.
 * onRenderValue is required and onRenderOption or onRenderTitle can provide alternative rendering for the selected value if required.
 */
type RichRenderValueDropdownBaseProps<TValue extends ValueDropdownValue, TData = undefined> = RequiredProperty<
    Omit<ValueDropdownBaseProps<TValue, TData>, 'options'>,
    'onRenderOption'
> & { getOptionText?: never };

export type ValueDropdownOption<TValue> = TValue | DropdownDividerOption | DropdownHeaderOption;

// This type union requires that either getOptionText or onRenderValue (not both) is provided
export type ValueDropdownProps<TValue extends ValueDropdownValue, TData = undefined> = (
    | BasicRenderValueDropdownBaseProps<TValue, TData>
    | RichRenderValueDropdownBaseProps<TValue, TData>
) & {
    options: ValueDropdownOption<TValue>[];
    isSearchable?: boolean;
    /** Used in combination with `isSearchable`, will limit the number of results that appear in the dropdown */
    searchPlaceholder?: string;
    /** Used in combination with `isSearchable`, aria label for the search bar */
    searchAriaLabel?: string;
    /** Used in combination with `isSearchable`, custom function for how search behaves */
    isSearchMatch?: (value: string, search: string) => boolean;
    locale?: string;
};

export type ValueDropdownWrapperProps<TValue extends ValueDropdownValue, TData = undefined> = Omit<
    ValueDropdownProps<TValue, TData>,
    'getOptionKey' | 'getOptionText' | 'onRenderOption' | 'onRenderValue' | 'options'
> & { options: TValue[] };

/* eslint-disable prefer-arrow/prefer-arrow-functions */
// Justification: arrow functions have limitations with the use of generics when using JSX

/**
 * This dropdown can be used for options that utilize custom rendering functions and options that use simple text based rendering.
 * If onRenderValue is provided, it will be used to render the options. Otherwise, getOptionText must be provided to
 * resolve the display text for option values.
 * onRenderTitle and onRenderOption will override onRenderValue for their respective displays.
 * @param props Required to provide either onRenderOption or getOptionText.
 * @returns A dropdown input.
 */
export function ValueDropdown<TValue extends ValueDropdownValue, TData = undefined>(
    props: ValueDropdownProps<TValue, TData>
): ReturnType<React.FC<ValueDropdownBaseProps<TValue, TData>>> {
    const {
        value,
        getOptionKey,
        getIsOptionHidden,
        getIsOptionDisabled,
        options: values,
        onRenderValue,
        isSearchable,
        searchPlaceholder,
        searchAriaLabel,
        onRenderList: providedOnRenderList,
        isSearchMatch,
        locale,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const separatorMessageStyles = useSeparatorMessageStyles();
    const noResultsTextStyles = useNoResultsTextStyles();
    const stackStyles = useStackStyles();

    const [search, setSearch] = React.useState('');

    const { getOptionText } = props as BasicRenderValueDropdownBaseProps<TValue, TData>;
    const { onRenderOption } = props as RichRenderValueDropdownBaseProps<TValue, TData>;

    const selectedKey: string | undefined = React.useMemo(() => {
        return value ? getOptionKey(value) : undefined;
    }, [value, getOptionKey]);

    const options: DropdownOption<TValue, TData>[] = React.useMemo(
        () =>
            values
                .map((value) => {
                    if (isDropdownDividerOption(value) || isDropdownHeaderOption(value)) {
                        return value;
                    }

                    return {
                        value,
                        disabled: getIsOptionDisabled?.(value) ?? false,
                        hidden: getIsOptionHidden?.(value) ?? false,
                        text: getOptionText?.(value) ?? '',
                        key: getOptionKey(value),
                    };
                })
                .filter((value: DropdownOption<TValue>) =>
                    isSearchable && isNotUndefinedOrWhiteSpace(search)
                        ? isSearchMatch
                            ? isSearchMatch(value.text, search)
                            : caseInsensitiveLocaleAwareIncludes(value.text, search.trim(), locale)
                        : value
                ),
        [values, getOptionKey, getOptionText, search, locale, isSearchable]
    );

    const onSearchChange = React.useCallback((_event, newValue) => {
        setSearch(newValue);
    }, []);

    const searchTip: JSX.Element | undefined = React.useMemo(() => {
        if (!isSearchable) {
            return undefined;
        }

        if (options.length === 0) {
            return (
                <div className={mergeClasses(stackStyles.item, noResultsTextStyles.root)}>
                    <FormattedMessage
                        id="ValueDropdown_NoResults_Text"
                        defaultMessage="No results"
                        description="Text for no results"
                    />
                </div>
            );
        }

        return undefined;
    }, [isSearchable, options, noResultsTextStyles]);

    const onRenderSearchableList = React.useCallback(
        (
            props?: IDropdownProps,
            defaultRender?: DefaultRenderFunction<ISelectableDroppableTextProps<IDropdown, HTMLDivElement>>
        ): JSX.Element | null => {
            return (
                <>
                    <SearchBox
                        autoFocus
                        placeholder={searchPlaceholder ?? formatMessage(messages.searchPlaceholder)}
                        ariaLabel={searchAriaLabel ?? formatMessage(messages.searchAriaLabel)}
                        onChange={onSearchChange}
                    />
                    <Separator styles={separatorMessageStyles} />
                    {!!providedOnRenderList
                        ? providedOnRenderList(props, defaultRender)
                        : defaultRender
                        ? defaultRender(props)
                        : null}
                    {searchTip}
                </>
            );
        },
        [
            searchTip,
            searchPlaceholder,
            searchAriaLabel,
            providedOnRenderList,
            onSearchChange,
            separatorMessageStyles,
            formatMessage,
        ]
    );

    const onRenderList = React.useMemo(
        () => (isSearchable ? onRenderSearchableList : providedOnRenderList),
        [isSearchable, onRenderSearchableList, providedOnRenderList]
    );

    return (
        <Dropdown<TValue, TData>
            {...props}
            selectedKey={selectedKey}
            options={options}
            onRenderOption={onRenderOption}
            onRenderValue={onRenderValue ?? onRenderOption}
            /* eslint-disable @typescript-eslint/no-explicit-any */
            // Justification: onRenderList needs to be able to take undefined for all dropdowns to work
            onRenderList={onRenderList as any as undefined}
            /* eslint-enable @typescript-eslint/no-explicit-any */
        />
    );
}

export function ValueDropdownContainer<TValue extends ValueDropdownValue, TData = undefined>(
    props: ValueDropdownProps<TValue, TData>
): ReturnType<React.FC<ValueDropdownProps<TValue, TData>>> {
    const { locale } = getLocalizationConfiguration();

    return <ValueDropdown<TValue, TData> {...props} locale={locale} />;
}
/* eslint-enable prefer-arrow/prefer-arrow-functions */

export default ValueDropdownContainer;
