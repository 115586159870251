import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { EventName, PerformanceMetric } from '../../../constants/telemetry';
import { GetDevBoxResponse, StartDevBoxResponse, startDevBox } from '../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, FailureResponse, isFailureResponse } from '../../../models/common';
import { getDevBoxState } from '../../../utilities/dev-box';
import { createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault } from '../../../utilities/failure';
import { trackEvent, trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../utilities/telemetry/helpers';
import {
    pollNonTerminalDevBox,
    resumeDevBox,
    resumeDevBoxAccepted,
    resumeDevBoxError,
    resumeDevBoxFailed,
    resumeDevBoxSuccess,
    startDevBoxAccepted,
    startDevBox as startDevBoxActionCreator,
    startDevBoxError,
    startDevBoxFailed,
    startDevBoxSuccess,
} from '../../actions/dev-box/dev-box-action-creators';
import {
    ResumeDevBoxAcceptedAction,
    ResumeDevBoxAction,
    StartDevBoxAcceptedAction,
    StartDevBoxAction,
} from '../../actions/dev-box/dev-box-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery, takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { DevBoxState } from '../../store/dev-box-state';

export function* startOrResumeDevBoxSaga(action: ResumeDevBoxAction | StartDevBoxAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;
    const isResume = resumeDevBox.match(action);

    const metric = isResume ? PerformanceMetric.ResumeDevBox : PerformanceMetric.StartDevBox;
    const operation = isResume ? FailureOperation.ResumeDevBox : FailureOperation.StartDevBox;
    const acceptedActionCreator = isResume ? resumeDevBoxAccepted : startDevBoxAccepted;
    const errorActionCreator = isResume ? resumeDevBoxError : startDevBoxError;
    const failedActionCreator = isResume ? resumeDevBoxFailed : startDevBoxFailed;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: StartDevBoxResponse = yield call(startDevBox, id, accessToken, activityId);

        if (isFailureResponse(response)) {
            // Ensure the operation is correct
            const failure = FailureResponse({ ...response, operation });
            yield put(failedActionCreator({ failure, id }, meta));

            yield call(
                trackTimedPerformanceMetric,
                metric,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, response.code)
            );
            return;
        }

        yield put(acceptedActionCreator({ id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            metric,
            startTime,
            AsyncOutcome.Success,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, operation);
        yield put(errorActionCreator({ error, id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            metric,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* startOrResumeDevBoxAcceptedSaga(
    action: ResumeDevBoxAcceptedAction | StartDevBoxAcceptedAction
): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;
    const isResume = resumeDevBox.match(action);

    const operation = isResume ? FailureOperation.ResumeDevBox : FailureOperation.StartDevBox;
    const errorActionCreator = isResume ? resumeDevBoxError : startDevBoxError;
    const failedActionCreator = isResume ? resumeDevBoxFailed : startDevBoxFailed;
    const successActionCreator = isResume ? resumeDevBoxSuccess : startDevBoxSuccess;

    try {
        const response: GetDevBoxResponse = yield putAndAwait(pollNonTerminalDevBox({ id }, meta));

        if (isFailureResponse(response)) {
            // Ensure the operation is correct
            const failure = FailureResponse({ ...response, operation });
            yield put(failedActionCreator({ failure, id }, meta));
            return;
        }

        const { data } = response;
        const state = getDevBoxState(data);
        switch (state) {
            case DevBoxState.Running:
                yield put(successActionCreator({ id, result: data }, meta));
                break;
            default:
                // Unexpected state: log cases where we're falling back on the default failure message. This means we're
                // in a failed state, but there are no error details.
                if (!data.error) {
                    trackEvent(EventName.DevBoxInFailedStateWithNoError, {
                        activityId,
                        properties: {
                            actionType: action.type,
                            id,
                            provisioningState: data.provisioningState,
                        },
                    });
                }

                const failure = createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault(
                    data.error,
                    FailureOperation.StartDevBox
                );

                yield put(failedActionCreator({ failure, id, result: data }, meta));
                break;
        }
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.StartDevBox);
        yield put(errorActionCreator({ error, id }, meta));
    }
}

export function* startOrResumeDevBoxListenerSaga(): SagaIterator {
    yield takeLeading([startDevBoxActionCreator, resumeDevBox], startOrResumeDevBoxSaga);
}

export function* startOrResumeDevBoxAcceptedListenerSaga(): SagaIterator {
    yield takeEvery([startDevBoxAccepted, resumeDevBoxAccepted], startOrResumeDevBoxAcceptedSaga);
}
