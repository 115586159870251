import {
    AzureCoreFoundationsErrorResponse as GeneratedAzureCoreFoundationsErrorResponseStable,
    AzureCoreFoundationsError as GeneratedAzureCoreFoundationsErrorStable,
    OperationState as GeneratedOperationStateStable,
    OperationStatus as GeneratedOperationStatusStable,
} from 'devcenter-internal-stable';
import { UnionMap } from '../../types/union-map';
import { isString } from '../../utilities/string';

// Note: this type is used in both Control Plane and Data Plane contexts, even though the type originates from the Data
// Plane SDK. The types are indeed the same between the two contexts and our bet is things will stay that way. If the
// two definitions drift, we should create separate types for Control Plane and Data Plane.
export type AzureCoreFoundationsErrorContract = GeneratedAzureCoreFoundationsErrorResponseStable;
export type AzureCoreFoundationsErrorBodyContract = GeneratedAzureCoreFoundationsErrorStable;

export type OperationStatusContract = GeneratedOperationStatusStable;

export type OperationStateContract = GeneratedOperationStateStable;

export const OperationStateContract: UnionMap<OperationStateContract> = {
    NotStarted: 'NotStarted',
    Running: 'Running',
    Succeeded: 'Succeeded',
    Failed: 'Failed',
    Canceled: 'Canceled',
};

interface LongRunningOperationResponseHeaders {
    operationLocation?: string;
}

export type LongRunningOperationResponseContract = OperationStatusContract & LongRunningOperationResponseHeaders;

export const isAzureCoreFoundationsErrorContract = (value: unknown): value is AzureCoreFoundationsErrorContract => {
    const azureCoreFoundationsError = value as AzureCoreFoundationsErrorContract;

    if (!azureCoreFoundationsError.error) {
        return false;
    }

    const error = azureCoreFoundationsError.error as AzureCoreFoundationsErrorBodyContract;

    return isString(error.code) && isString(error.message);
};
