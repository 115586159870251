import {
    EnvironmentAction as GeneratedEnvironmentActionStable,
    EnvironmentOutputType as GeneratedEnvironmentOutputTypeStable,
    EnvironmentProvisioningState as GeneratedEnvironmentProvisioningStateStable,
    Environment as GeneratedEnvironmentStable,
} from 'devcenter-internal-stable';
import { UnionMap } from '../../types/union-map';

/**
 * Data Plane SDK shim models
 */

export type EnvironmentOutputTypeContract = GeneratedEnvironmentOutputTypeStable;
export const EnvironmentOutputTypeContract: UnionMap<EnvironmentOutputTypeContract> = {
    string: 'string',
    array: 'array',
    number: 'number',
    boolean: 'boolean',
    object: 'object',
};

export type StableEnvironmentContract = GeneratedEnvironmentStable;

// Note: making a distinction here between request and response contracts, which are treated as the same by code gen.
//      - *EnvironmentContract: response contract, in which readonly props (e.g. name, uri) are always available
//      - *PutEnvironmentBodyContract: PUT request contract, in which readonly props aren't required
export type EnvironmentContract = GeneratedEnvironmentStable;
export type PutEnvironmentBodyContract = GeneratedEnvironmentStable;

export type EnvironmentParameters = {
    [name: string]: boolean | number | string;
};

export type EnvironmentActionContract = GeneratedEnvironmentActionStable;

export type EnvironmentProvisioningStateContract = GeneratedEnvironmentProvisioningStateStable;

export const EnvironmentProvisioningStateContract: UnionMap<EnvironmentProvisioningStateContract> = {
    Canceled: 'Canceled',
    Creating: 'Creating',
    Failed: 'Failed',
    Running: 'Running',
    Succeeded: 'Succeeded',
    Accepted: 'Accepted',
    Deleting: 'Deleting',
    Updating: 'Updating',
    Preparing: 'Preparing',
    Syncing: 'Syncing',
    MovingResources: 'MovingResources',
    StorageProvisioningFailed: 'StorageProvisioningFailed',
    TransientFailure: 'TransientFailure',
};

//Allowing null value for expirationDate as this is how property is cleared on PATCH
export interface EnviromentUpdateContract {
    expirationDate: Date | null | undefined;
}
