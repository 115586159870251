import { AddDevBoxPanelContext } from '../../components/add-dev-box-panel/contexts';
import { AddEnvironmentPanelContext } from '../../components/add-environment-panel/contexts';
import { CustomizationDetailsPanelContext } from '../../components/customization-details-panel/contexts';
import { DeploymentLogsPanelContext } from '../../components/environment/deployment-logs-panel/context';
import { DevBoxDetailsPanelContext } from '../../components/dev-box/dev-box-details-panel/context';
import { DevBoxSupportPanelContext } from '../../components/dev-box/dev-box-support-panel/context';
import { DevMenuPanelContext } from '../../components/dev-menu-panel/contexts';
import { EnvironmentDetailsPanelContext } from '../../components/environment/environment-details-panel/context';
import { EnvironmentExpirationDateOffsetUnit } from '../../components/environment/models';
import { ErrorDetailsPanelContext } from '../../components/error-details-panel/contexts';
import { HelpMenuPanelContext } from '../../components/help-menu-panel/contexts';
import { RedeployEnvironmentPanelContext } from '../../components/redeploy-environment-panel/contexts';
import { UserSettingsPanelContext } from '../../components/user-settings/user-settings-menu-panel/contexts';
import { PanelName } from '../../constants/app';
import { EnvironmentProvisioningState } from '../../constants/environment';
import { FailureResponse } from '../../models/common';
import { HibernateSupport } from '../../models/pool';
import {
    defaultCloseSurfaceHandler,
    defaultOpenSurfaceHandler,
    defaultOpenSurfaceHandlerWithProperties,
    surfaceContextHook,
    surfaceContextWithPropertiesHook,
} from './common';

const defaultClosePanelHandler = defaultCloseSurfaceHandler;
const defaultOpenPanelHandler = defaultOpenSurfaceHandler;
const defaultOpenPanelHandlerWithProperties = defaultOpenSurfaceHandlerWithProperties;

const defaultCloseAddDevBoxPanel = defaultClosePanelHandler(PanelName.AddDevBox);
const defaultCloseAddEnvironmentPanel = defaultClosePanelHandler(PanelName.AddEnvironment);
const defaultCloseDevMenuPanel = defaultClosePanelHandler(PanelName.DevMenu);
const defaultCloseErrorDetailsPanel = defaultClosePanelHandler(PanelName.ErrorDetails);
const defaultCloseHelpMenuPanel = defaultClosePanelHandler(PanelName.HelpMenu);
const defaultCloseDevBoxDetailsPanel = defaultClosePanelHandler(PanelName.DevBoxDetails);
const defaultOpenAddDevBoxPanel = defaultOpenPanelHandler(PanelName.AddDevBox);
const defaultOpenAddEnvironmentPanel = defaultOpenPanelHandler(PanelName.AddEnvironment);
const defaultOpenDevMenuPanel = defaultOpenPanelHandler(PanelName.DevMenu);
const defaultOpenErrorDetailsPanel = defaultOpenPanelHandlerWithProperties(PanelName.ErrorDetails);
const defaultOpenHelpMenuPanel = defaultOpenPanelHandler(PanelName.HelpMenu);
const defaultOpenDevBoxDetailsPanel = defaultOpenPanelHandlerWithProperties(PanelName.DevBoxDetails);
const defaultCloseRedeployEnvironmentPanel = defaultClosePanelHandler(PanelName.RedeployEnvironment);
const defaultOpenRedeployEnvironmentPanel = defaultOpenPanelHandlerWithProperties(PanelName.RedeployEnvironment);
const defaultCloseEnvironmentDetailsPanel = defaultClosePanelHandler(PanelName.EnvironmentDetails);
const defaultOpenEnvironmentDetailsPanel = defaultOpenPanelHandlerWithProperties(PanelName.EnvironmentDetails);
const defaultCloseCustomizationDetailsPanel = defaultClosePanelHandler(PanelName.CustomizationDetails);
const defaultOpenCustomizationDetailsPanel = defaultOpenPanelHandlerWithProperties(PanelName.CustomizationDetails);
const defaultOpenDevBoxSupportPanel = defaultOpenPanelHandlerWithProperties(PanelName.Support);
const defaultCloseDevBoxSupportPanel = defaultClosePanelHandler(PanelName.Support);
const defaultOpenUserSettingsPanel = defaultOpenPanelHandler(PanelName.UserSettings);
const defaultCloseUserSettingsPanel = defaultClosePanelHandler(PanelName.UserSettings);
const defaultOpenDeploymentLogsPanel = defaultOpenPanelHandler(PanelName.DeploymentLogs);
const defaultCloseDeploymentLogsPanel = defaultClosePanelHandler(PanelName.DeploymentLogs);

export const useAddDevBoxPanelContext = surfaceContextHook(
    AddDevBoxPanelContext,
    defaultOpenAddDevBoxPanel,
    defaultCloseAddDevBoxPanel
);

export const useAddEnvironmentPanelContext = surfaceContextHook(
    AddEnvironmentPanelContext,
    defaultOpenAddEnvironmentPanel,
    defaultCloseAddEnvironmentPanel
);

export const useDevMenuPanelContext = surfaceContextHook(
    DevMenuPanelContext,
    defaultOpenDevMenuPanel,
    defaultCloseDevMenuPanel
);

export const useErrorDetailsPanelContext = surfaceContextWithPropertiesHook(
    ErrorDetailsPanelContext,
    defaultOpenErrorDetailsPanel,
    defaultCloseErrorDetailsPanel,
    {
        failure: FailureResponse(),
    }
);

export const useHelpMenuPanelContext = surfaceContextHook(
    HelpMenuPanelContext,
    defaultOpenHelpMenuPanel,
    defaultCloseHelpMenuPanel
);

export const useDevBoxDetailsPanelContext = surfaceContextWithPropertiesHook(
    DevBoxDetailsPanelContext,
    defaultOpenDevBoxDetailsPanel,
    defaultCloseDevBoxDetailsPanel,
    {
        name: '',
        poolName: '',
        hibernateSupport: HibernateSupport.Disabled,
    }
);

export const useDevBoxSupportPanelContext = surfaceContextWithPropertiesHook(
    DevBoxSupportPanelContext,
    defaultOpenDevBoxSupportPanel,
    defaultCloseDevBoxSupportPanel,
    {
        poolDisplayName: '',
        poolName: '',
        devBoxName: '',
        devBoxUniqueID: '',
    }
);

export const useEnvironmentDetailsPanelContext = surfaceContextWithPropertiesHook(
    EnvironmentDetailsPanelContext,
    defaultOpenEnvironmentDetailsPanel,
    defaultCloseEnvironmentDetailsPanel,
    {
        name: '',
        environmentDefinitionName: '',
    }
);

export const useRedeployEnvironmentPanelContext = surfaceContextWithPropertiesHook(
    RedeployEnvironmentPanelContext,
    defaultOpenRedeployEnvironmentPanel,
    defaultCloseRedeployEnvironmentPanel,
    {
        environment: {
            key: '',
            resource: {
                catalogName: '',
                environmentDefinitionName: '',
                environmentType: '',
                name: '',
                uri: '',
            },
            state: EnvironmentProvisioningState.Succeeded,
            ownerIsReady: true,
            environmentDefinition: { description: '', parameters: [] },
            ownerDisplayName: '',
            isCardContentReady: true,
            projectName: '',
            expirationDateOffset: {
                offset: 0,
                unit: EnvironmentExpirationDateOffsetUnit.Seconds,
            },
            devCenterName: '',
            environmentOperations: [],
        },
    }
);

export const useDeploymentLogsPanelContext = surfaceContextWithPropertiesHook(
    DeploymentLogsPanelContext,
    defaultOpenDeploymentLogsPanel,
    defaultCloseDeploymentLogsPanel,
    {
        environmentOperations: [
            {
                kind: '',
                operationUri: '',
                operationId: '',
                status: '',
                createdByObjectId: '',
                startTime: undefined,
                endTime: undefined,
                logBlobUri: '',
            },
        ],
        name: '',
    }
);

export const useCustomizationDetailsPanelContext = surfaceContextWithPropertiesHook(
    CustomizationDetailsPanelContext,
    defaultOpenCustomizationDetailsPanel,
    defaultCloseCustomizationDetailsPanel,
    {
        customizationGroup: {
            name: '',
            uri: '',
        },
        customizationGroups: [
            {
                name: '',
                uri: '',
            },
        ],
    }
);

export const useUserSettingsPanelContext = surfaceContextHook(
    UserSettingsPanelContext,
    defaultOpenUserSettingsPanel,
    defaultCloseUserSettingsPanel
);
