import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { CustomizationGroupName } from '../../../components/add-dev-box-panel/models';
import { CreateDevBoxErrorCode } from '../../../constants/dev-box';
import { EventName, PerformanceMetric } from '../../../constants/telemetry';
import { PutCustomizationTaskBodyContract } from '../../../data/contracts/customization';
import { CreateDevBoxResponse, GetDevBoxResponse, createDevBox } from '../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { PutCustomizationTask } from '../../../models/customization';
import { ResourceProvisioningState } from '../../../models/resource-manager';
import { IntrinsicTaskBody } from '../../../utilities/customization-task';
import { createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault } from '../../../utilities/failure';
import { trackEvent, trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../utilities/telemetry/helpers';
import { createCustomizationGroup } from '../../actions/customization/customization-action-creators';
import {
    addDevBox,
    addDevBoxAccepted,
    addDevBoxError,
    addDevBoxFailed,
    addDevBoxOperationError,
    addDevBoxOperationFailed,
    addDevBoxOperationSuccess,
    pollNonTerminalDevBox,
} from '../../actions/dev-box/dev-box-action-creators';
import { AddDevBoxAcceptedAction, AddDevBoxAction } from '../../actions/dev-box/dev-box-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { getRemoteConnection } from '../../actions/remote-connection/remote-connection-action-creators';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery, takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';

export function* addDevBoxSaga(action: AddDevBoxAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { customizationGroupId, id, name, poolName, taskList, intrinsicTasks } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: CreateDevBoxResponse = yield call(
            createDevBox,
            id,
            name,
            { uri: id, name, poolName },
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            const { code } = response;

            // Note: for reporting purposes, don't mark QuotaExceeded as failures. (We still show them as errors to users)
            const outcome = code === CreateDevBoxErrorCode.QuotaExceeded ? AsyncOutcome.Success : AsyncOutcome.Failed;

            yield put(addDevBoxFailed({ failure: response, id }, meta));
            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.AddDevBox,
                startTime,
                outcome,
                createOptionsForDataPlaneResourceMetric(id, activityId, code)
            );
            return;
        }

        const combinedTaskList: PutCustomizationTaskBodyContract[] = [];

        // Add intrinsic tasks to the combined task list
        if (intrinsicTasks && intrinsicTasks.length > 0) {
            // Append the corresponding intrinsic task body to the combined task list
            combinedTaskList.push(...intrinsicTasks.map((task) => IntrinsicTaskBody[task]));
        }

        // Add adhoc tasks to the combined task list
        if (taskList && taskList.length > 0) {
            combinedTaskList.push(...taskList);
        }

        // If the taskList exists and the customization group id exists, then we will kick off the customization
        if (customizationGroupId && combinedTaskList && combinedTaskList.length > 0) {
            yield put(
                createCustomizationGroup(
                    {
                        id: customizationGroupId,
                        name: CustomizationGroupName,
                        tasks: combinedTaskList as PutCustomizationTask[],
                    },
                    meta
                )
            );
        }

        const { data } = response;
        yield put(addDevBoxAccepted({ id, result: data }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.AddDevBox,
            startTime,
            AsyncOutcome.Success,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.AddDevBox);
        yield put(addDevBoxError({ error, id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.AddDevBox,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* addDevBoxAcceptedSaga(action: AddDevBoxAcceptedAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const response: GetDevBoxResponse = yield putAndAwait(pollNonTerminalDevBox({ id }, meta));

        if (isFailureResponse(response)) {
            yield put(addDevBoxOperationFailed({ failure: response, id }, meta));
            return;
        }

        const { data } = response;
        const { provisioningState, error: azureCoreFoundationsError } = data;

        const actions: Action<string>[] = [];
        switch (provisioningState) {
            case ResourceProvisioningState.Succeeded:
                actions.push(
                    // Let the remote connection request commence its own activity
                    getRemoteConnection({ id }),
                    addDevBoxOperationSuccess({ id, result: data }, meta)
                );
                break;
            case ResourceProvisioningState.Failed:
            default:
                // Unexpected state: log cases where we're falling back on the default failure message. This means we're
                // in a failed state, but there are no error details.
                if (!azureCoreFoundationsError) {
                    trackEvent(EventName.DevBoxInFailedStateWithNoError, {
                        activityId,
                        properties: {
                            actionType: action.type,
                            id,
                            provisioningState,
                        },
                    });
                }

                const failure = createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault(
                    azureCoreFoundationsError,
                    FailureOperation.AddDevBox
                );

                actions.push(addDevBoxOperationFailed({ failure, id, result: data }, meta));
                break;
        }

        yield all(actions.map((action) => put(action)));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.AddDevBox);
        yield put(addDevBoxOperationError({ error, id }, meta));
    }
}

export function* addDevBoxListenerSaga(): SagaIterator {
    yield takeLeading(addDevBox, addDevBoxSaga);
}

export function* addDevBoxAcceptedListenerSaga(): SagaIterator {
    yield takeEvery(addDevBoxAccepted, addDevBoxAcceptedSaga);
}
