import {
    GetEnvironmentOperationLogResponse,
    ListEnvironmentOperationsResponse,
} from '../../../data/services/data-plane-api/environment-operation';
import { AggregatedResult } from '../../../models/common';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import {
    EnvironmentOperationActionType,
    GetEnvironmentOperationLogSuccessPayload,
    ListEnvironmentOperationsSuccessPayload,
} from './environment-operation-actions';

/**
 * Action creators
 */

export const discoverEnvironmentOperationsForEnvironment = createActionCreatorWithoutPayload<AggregatedResult>(
    EnvironmentOperationActionType.DiscoverEnvironmentOperationsForEnvironment,
    { async: true, startsActivity: true }
);

export const discoverEnvironmentOperationsForEnvironmentError = createActionCreator<ErrorPayload>(
    EnvironmentOperationActionType.DiscoverEnvironmentOperationsForEnvironmentError
);

export const discoverEnvironmentOperationsForEnvironmentFailed = createActionCreator<FailedPayload>(
    EnvironmentOperationActionType.DiscoverEnvironmentOperationsForEnvironmentFailed
);

export const discoverEnvironmentOperationsForEnvironmentSuccess = createActionCreatorWithoutPayload(
    EnvironmentOperationActionType.DiscoverEnvironmentOperationsForEnvironmentSuccess
);

export const getEnvironmentOperationLog = createActionCreator<IndexedPayload, GetEnvironmentOperationLogResponse>(
    EnvironmentOperationActionType.GetEnvironmentOperationLog,
    { async: true, startsActivity: true }
);

export const getEnvironmentOperationLogError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentOperationActionType.GetEnvironmentOperationLogError
);

export const getEnvironmentOperationLogFailed = createActionCreator<FailedPayload & IndexedPayload>(
    EnvironmentOperationActionType.GetEnvironmentOperationLogFailed
);

export const getEnvironmentOperationLogSuccess = createActionCreator<GetEnvironmentOperationLogSuccessPayload>(
    EnvironmentOperationActionType.GetEnvironmentOperationLogSuccess
);

export const listEnvironmentOperations = createActionCreator<IndexedPayload, ListEnvironmentOperationsResponse>(
    EnvironmentOperationActionType.ListEnvironmentOperations,
    { async: true, startsActivity: true }
);

export const listEnvironmentOperationsError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentOperationActionType.ListEnvironmentOperationsError
);

export const listEnvironmentOperationsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    EnvironmentOperationActionType.ListEnvironmentOperationsFailed
);

export const listEnvironmentOperationsSuccess = createActionCreator<ListEnvironmentOperationsSuccessPayload>(
    EnvironmentOperationActionType.ListEnvironmentOperationsSuccess
);
