import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { listTenants } from '../../../data/services/resource-manager/tenant';
import { getLocalizationConfiguration, LocalizationConfiguration } from '../../../language/languages';
import { ClientError, DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { Tenant } from '../../../models/resource-manager';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForAzureResourceManagerPayload } from '../../actions/identity/identity-actions';
import {
    listTenants as listTenantsActionCreator,
    listTenantsError,
    listTenantsFailed,
    listTenantsSuccess,
} from '../../actions/tenant/tenant-action-creators';
import { ListTenantsAction } from '../../actions/tenant/tenant-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLatest } from '../../effects/take';

export function* listTenantsSaga(action: ListTenantsAction): SagaIterator {
    const { meta } = action;
    const { activityId } = meta ?? {};

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForAzureResourceManagerPayload(), meta)
        );

        const { language, locale }: LocalizationConfiguration = yield call(getLocalizationConfiguration);
        const response: DataResponse<Tenant[]> = yield call(listTenants, accessToken, language, locale, activityId);

        if (isFailureResponse(response)) {
            yield put(listTenantsFailed({ failure: response }, meta));
        } else {
            const { data } = response;
            yield put(listTenantsSuccess({ result: data }, meta));
        }

        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListTenants);
        yield put(listTenantsError({ error }, meta));
        yield rejectAction(action, error);
    }
}

export function* listTenantsListenerSaga(): SagaIterator {
    yield takeLatest(listTenantsActionCreator, listTenantsSaga);
}
