import {
    EnvironmentDeleteOperation as GeneratedEnvironmentDeleteOperationStable,
    EnvironmentDeployOperation as GeneratedEnvironmentDeployOperationStable,
    EnvironmentOperationKind as GeneratedEnvironmentOperationKindStable,
    EnvironmentOperation as GeneratedEnvironmentOperationStable,
} from 'devcenter-internal-stable';
import { UnionMap } from '../../types/union-map';

/**
 * Data Plane SDK shim models
 */

export type EnvironmentDeleteOperationContract = GeneratedEnvironmentDeleteOperationStable;
export type EnvironmentDeployOperationContract = GeneratedEnvironmentDeployOperationStable;

export type EnvironmentOperationContract = GeneratedEnvironmentOperationStable;

export type EnvironmentOperationKindContract = GeneratedEnvironmentOperationKindStable;

export const EnvironmentOperationKindContract: UnionMap<EnvironmentOperationKindContract> = {
    Delete: 'Delete',
    Deploy: 'Deploy',
};

/**
 * Type guards
 */

export const isEnvironmentOperationKindContract = (value: unknown): value is EnvironmentOperationKindContract =>
    value === EnvironmentOperationKindContract.Delete || value === EnvironmentOperationKindContract.Deploy;
