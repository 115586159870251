import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { EventName, PerformanceMetric } from '../../../constants/telemetry';
import { DeleteDevBoxResponse, GetDevBoxResponse, deleteDevBox } from '../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault } from '../../../utilities/failure';
import { trackEvent, trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../utilities/telemetry/helpers';
import {
    deleteDevBoxAccepted,
    deleteDevBox as deleteDevBoxActionCreator,
    deleteDevBoxError,
    deleteDevBoxFailed,
    deleteDevBoxSuccess,
    pollNonTerminalDevBox,
} from '../../actions/dev-box/dev-box-action-creators';
import { DeleteDevBoxAcceptedAction, DeleteDevBoxAction } from '../../actions/dev-box/dev-box-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery, takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';

export function* deleteDevBoxSaga(action: DeleteDevBoxAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: DeleteDevBoxResponse = yield call(deleteDevBox, id, accessToken, activityId);

        if (isFailureResponse(response)) {
            yield put(deleteDevBoxFailed({ failure: response, id }, meta));

            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.DeleteDevBox,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, response.code)
            );

            return;
        }

        yield put(deleteDevBoxAccepted({ id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DeleteDevBox,
            startTime,
            AsyncOutcome.Success,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DeleteDevBox);
        yield put(deleteDevBoxError({ error, id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DeleteDevBox,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* deleteDevBoxAcceptedSaga(action: DeleteDevBoxAcceptedAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const response: GetDevBoxResponse = yield putAndAwait(pollNonTerminalDevBox({ id }, meta));

        if (isFailureResponse(response)) {
            const { statusCode } = response;
            const action =
                statusCode === 404
                    ? deleteDevBoxSuccess({ id }, meta)
                    : deleteDevBoxFailed({ failure: response, id }, meta);
            yield put(action);
            return;
        }

        const { data } = response;

        // Unexpected state: log cases where we're falling back on the default failure message. This means we're in a
        // failed state, but there are no error details.
        if (!data.error) {
            trackEvent(EventName.DevBoxInFailedStateWithNoError, {
                activityId,
                properties: {
                    actionType: action.type,
                    id,
                    provisioningState: response.data.provisioningState,
                },
            });
        }

        const failure = createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault(
            data.error,
            FailureOperation.DeleteDevBox
        );
        yield put(deleteDevBoxFailed({ failure, id, result: data }, meta));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.DeleteDevBox);
        yield put(deleteDevBoxError({ error, id }, meta));
    }
}

export function* deleteDevBoxListenerSaga(): SagaIterator {
    yield takeLeading(deleteDevBoxActionCreator, deleteDevBoxSaga);
}

export function* deleteDevBoxAcceptedListenerSaga(): SagaIterator {
    yield takeEvery(deleteDevBoxAccepted, deleteDevBoxAcceptedSaga);
}
