import {
    DevBoxProvisioningState as GeneratedDevBoxProvisioningStateStable,
    DevBoxSnapshot as GeneratedDevBoxSnapshotStable,
    DevBox as GeneratedDevBoxStable,
    RemoteConnection as GeneratedRemoteConnectionStable,
} from 'devcenter-internal-stable';
import { RequiredProperty } from '../../types/required-property';
import { UnionMap } from '../../types/union-map';

/**
 * Data Plane SDK shim models
 */

export type StableDevBoxContract = GeneratedDevBoxStable;

// Note: making a distinction here between request and response contracts, which are treated as the same by code gen.
//      - DevBoxContract: response contract, in which readonly props (e.g. name, uri) are always available
//      - PutDevBoxBodyContract: PUT request contract, in which readonly props aren't required
export type DevBoxContract = RequiredProperty<GeneratedDevBoxStable, 'projectName'>;
export type PutDevBoxBodyContract = GeneratedDevBoxStable;

export type RemoteConnectionContract = GeneratedRemoteConnectionStable;

export type DevBoxSnapshotContract = GeneratedDevBoxSnapshotStable;

export type DevBoxProvisioningStateContract = GeneratedDevBoxProvisioningStateStable;

export const DevBoxProvisioningStateContract: UnionMap<DevBoxProvisioningStateContract> = {
    Canceled: 'Canceled',
    Creating: 'Creating',
    Failed: 'Failed',
    Succeeded: 'Succeeded',
    Deleting: 'Deleting',
    Updating: 'Updating',
    Starting: 'Starting',
    Stopping: 'Stopping',
    InGracePeriod: 'InGracePeriod',
    NotProvisioned: 'NotProvisioned',
    Provisioning: 'Provisioning',
    ProvisionedWithWarning: 'ProvisionedWithWarning',
};
