import { EnvironmentOperation, EnvironmentOperationLogUri } from '../../../models/environment';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, ResultPayload } from '../core-actions';
import {
    discoverEnvironmentOperationsForEnvironment,
    getEnvironmentOperationLog,
    listEnvironmentOperations,
} from './environment-operation-action-creators';

/**
 * Action types
 */

type EnvironmentOperationActionName =
    | ActionName<'DiscoverEnvironmentOperationsForEnvironment', 'Error' | 'Failed' | 'Success'>
    | ActionName<'GetEnvironmentOperationLog', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListEnvironmentOperations', 'Error' | 'Failed' | 'Success'>;

export type EnvironmentOperationActionType =
    | ActionType<'DISCOVER_ENVIRONMENT_OPERATIONS_FOR_ENVIRONMENT', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'GET_ENVIRONMENT_OPERATION_LOG', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_ENVIRONMENT_OPERATIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const EnvironmentOperationActionType: UnionValueMap<
    EnvironmentOperationActionName,
    EnvironmentOperationActionType
> = {
    DiscoverEnvironmentOperationsForEnvironment: 'DISCOVER_ENVIRONMENT_OPERATIONS_FOR_ENVIRONMENT',
    DiscoverEnvironmentOperationsForEnvironmentError: 'DISCOVER_ENVIRONMENT_OPERATIONS_FOR_ENVIRONMENT_ERROR',
    DiscoverEnvironmentOperationsForEnvironmentFailed: 'DISCOVER_ENVIRONMENT_OPERATIONS_FOR_ENVIRONMENT_FAILED',
    DiscoverEnvironmentOperationsForEnvironmentSuccess: 'DISCOVER_ENVIRONMENT_OPERATIONS_FOR_ENVIRONMENT_SUCCESS',
    GetEnvironmentOperationLog: 'GET_ENVIRONMENT_OPERATION_LOG',
    GetEnvironmentOperationLogError: 'GET_ENVIRONMENT_OPERATION_LOG_ERROR',
    GetEnvironmentOperationLogFailed: 'GET_ENVIRONMENT_OPERATION_LOG_FAILED',
    GetEnvironmentOperationLogSuccess: 'GET_ENVIRONMENT_OPERATION_LOG_SUCCESS',
    ListEnvironmentOperations: 'LIST_ENVIRONMENT_OPERATIONS',
    ListEnvironmentOperationsError: 'LIST_ENVIRONMENT_OPERATIONS_ERROR',
    ListEnvironmentOperationsFailed: 'LIST_ENVIRONMENT_OPERATIONS_FAILED',
    ListEnvironmentOperationsSuccess: 'LIST_ENVIRONMENT_OPERATIONS_SUCCESS',
};

/**
 * Payloads
 */

export type ListEnvironmentOperationsSuccessPayload = IndexedPayload & ResultPayload<EnvironmentOperation[]>;

export type GetEnvironmentOperationLogSuccessPayload = IndexedPayload & ResultPayload<EnvironmentOperationLogUri>;

/**
 * Action shorthands
 */

export type DiscoverEnvironmentOperationsForEnvironmentAction = ReturnType<
    typeof discoverEnvironmentOperationsForEnvironment
>;

export type ListEnvironmentOperationsAction = ReturnType<typeof listEnvironmentOperations>;

export type GetEnvironmentOperationLogAction = ReturnType<typeof getEnvironmentOperationLog>;
