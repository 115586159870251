import { ThemeProvider } from '@fluentui/react';
import * as React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { IntlProvider } from 'react-intl';
import { IPollingConfig, useOnlineEffect } from 'react-network-detect';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import ErrorBoundary from './components/error-boundary';
import { FeatureFlagName } from './constants/features';
import { EventName, Severity } from './constants/telemetry';
import { Head } from './head';
import { useActionCreator } from './hooks/action-creator';
import { useFeatureFlag } from './hooks/features';
import { useCurrentFluent1Theme } from './hooks/styling';
import { LocaleMessageData } from './language/languages';
import commonMessages from './language/messages';
import { SimpleLayout } from './layouts/simple-layout';
import ErrorPage from './pages/error';
import LoadingPage from './pages/loading';
import OfflinePage from './pages/offline';
import SessionExpiredPage from './pages/session-expired';
import UnauthenticatedLandingPage from './pages/unauthenticated-landing-page';
import { initializeApplication } from './redux/actions/application/application-action-creators';
import { tryExpireSignIn } from './redux/actions/identity/identity-action-creators';
import {
    getHasFatalError,
    getIsApplicationInitialized,
    getIsApplicationInitializing,
} from './redux/selector/application-selectors';
import { getAuthenticationState, getIsSessionExpiring, getIsSignedIn } from './redux/selector/identity-selectors';
import { AuthenticationState } from './redux/store/identity-state';
import { Routes as PageRoutes } from './routes';
import HomeSubApplication from './sub-applications/home/home';
import { SingleDevCenterSubApplication } from './sub-applications/single-dev-center/single-dev-center';
import { getUseDefaultMessages } from './utilities/app';
import { isFeatureFlagEnabled } from './utilities/features';
import { trackEvent, trackTrace } from './utilities/telemetry/channel';

type AppProps = {
    locale: string;
    messages?: LocaleMessageData;
};

interface ConfiguredRoutesProps {
    hasFatalError: boolean;
    isAppInitializing: boolean;
    isSessionExpiring: boolean;
    isSignedIn: boolean;
    hasIdentityErrorBasedExpiry: boolean;
    isSingleDevCenterModeEnabled: boolean;
}

// Amount of time in minutes that the user can be idle before they must sign in again. Currently 30m.
const idleTimeoutInMinutes = 30 * 60 * 1000;

// In dev, we don't want to continually console log missing translation errors when using default messages
/* eslint-disable @typescript-eslint/no-empty-function */
let onIntlError: (() => void) | undefined = undefined;
if (process.env.NODE_ENV === 'development') {
    const useDefaultMessages = getUseDefaultMessages();
    onIntlError = useDefaultMessages ? () => {} : undefined;
}
/* eslint-enable @typescript-eslint/no-empty-function */

const onlinePollingConfig: IPollingConfig = {
    enabled: true,
    url: `https://${window.location.host}/`,
    timeout: 5000,
    interval: 60000,
};

const ConfiguredRoutes: React.FC<ConfiguredRoutesProps> = (props: ConfiguredRoutesProps) => {
    const {
        hasFatalError,
        isAppInitializing,
        isSessionExpiring,
        isSignedIn,
        hasIdentityErrorBasedExpiry,
        isSingleDevCenterModeEnabled,
    } = props;

    const { isOnline } = useOnlineEffect(onlinePollingConfig);

    React.useEffect(() => {
        trackEvent(EventName.ONLINE_STATUS_CHANGE, {
            properties: {
                isOnline: `${isOnline}`,
                navigatorOnLine: `${navigator.onLine}`,
            },
        });
    }, [isOnline]);

    if (!isOnline) {
        return (
            <Routes>
                <Route path="*" element={<OfflinePage />} />
            </Routes>
        );
    }

    if (hasFatalError) {
        return (
            <Routes>
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        );
    }

    // After the fatal error we should check if there is an identity error and redirect to the expiry page to refresh regardless of the route
    if (hasIdentityErrorBasedExpiry) {
        trackTrace('Encountered an identity error that requires routing to the expiry page', {
            severity: Severity.Warning,
        });

        return (
            <Routes>
                <Route path="*" element={<SessionExpiredPage hasIdentityError={true} />} />
            </Routes>
        );
    }

    if (isSessionExpiring) {
        return (
            <Routes>
                <Route path="*" element={<LoadingPage message={commonMessages.sessionExpiringMessageText} />} />
            </Routes>
        );
    }

    if (isAppInitializing) {
        return <SimpleLayout id="initializing" />;
    }

    if (!isSignedIn) {
        return (
            <Routes>
                <Route path={PageRoutes.SessionExpired} element={<SessionExpiredPage hasIdentityError={false} />} />
                <Route path="*" element={<UnauthenticatedLandingPage />} />
            </Routes>
        );
    }

    return (
        <Routes>
            {isSingleDevCenterModeEnabled ? (
                <Route path={PageRoutes.SingleDevCenter} element={<SingleDevCenterSubApplication />} />
            ) : (
                <></>
            )}
            <Route path="*" element={<HomeSubApplication />} />
        </Routes>
    );
};

export const App: React.FC<AppProps> = (props) => {
    const { locale, messages } = props;

    // Application theme initialization
    const fluent1Theme = useCurrentFluent1Theme();

    // Feature hooks
    const isSingleDevCenterModeEnabled = useFeatureFlag(FeatureFlagName.SingleDevCenterMode);

    // Application state hooks
    const hasFatalError = useSelector(getHasFatalError);
    const isInitialized = useSelector(getIsApplicationInitialized);
    const isInitializing = useSelector(getIsApplicationInitializing);
    const isSessionExpiring = useSelector(getIsSessionExpiring);
    const isSignedIn = useSelector(getIsSignedIn);
    const authenticationState = useSelector(getAuthenticationState);

    // Action hooks
    const initialize = useActionCreator(initializeApplication);
    const expireSignInOnIdle = useActionCreator(tryExpireSignIn);

    // Check if the authentication state has an identity error that requires a redirection to the expiry page.
    const hasIdentityErrorBasedExpiry = React.useMemo(
        () => authenticationState === AuthenticationState.IdentityErrorBasedExpiry,
        [authenticationState]
    );

    // Effect hooks
    React.useEffect(() => {
        if (!isInitialized) {
            initialize();
        }
    }, [initialize, isInitialized]);

    // Callback hooks
    const onIdle = React.useCallback(() => {
        expireSignInOnIdle();
    }, [expireSignInOnIdle]);

    // Idle detection
    const idleTimerOptions = React.useMemo(
        () =>
            isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
                ? undefined
                : { element: document, onIdle, timeout: idleTimeoutInMinutes },
        [onIdle]
    );

    useIdleTimer(idleTimerOptions);

    return (
        <ThemeProvider applyTo="body" theme={fluent1Theme}>
            <IntlProvider locale={locale} key={locale} messages={messages} onError={onIntlError}>
                <Head />

                <ErrorBoundary>
                    <ConfiguredRoutes
                        hasFatalError={hasFatalError}
                        isAppInitializing={isInitializing}
                        isSessionExpiring={isSessionExpiring}
                        isSignedIn={isSignedIn}
                        hasIdentityErrorBasedExpiry={hasIdentityErrorBasedExpiry}
                        isSingleDevCenterModeEnabled={isSingleDevCenterModeEnabled}
                    />
                </ErrorBoundary>
            </IntlProvider>
        </ThemeProvider>
    );
};

export default App;
