import {
    ActionState,
    DevBoxFailureReason,
    DevBoxProvisioningState,
    PowerState,
    terminalDevBoxActionStates,
    terminalDevBoxProvisioningStates,
} from '../constants/dev-box';
import { DevBox, FailureOnDevBox } from '../models/dev-box';
import { DevBoxState, terminalDevBoxStates } from '../redux/store/dev-box-state';
import { createFailureResponseFromAzureCoreFoundationsErrorBody } from './failure';

export const isTerminalDevBoxProvisioningState = (state: string | undefined): state is DevBoxProvisioningState =>
    !!state && terminalDevBoxProvisioningStates.indexOf(state as DevBoxProvisioningState) !== -1;

export const isTerminalDevBoxActionState = (state: string | undefined): state is ActionState =>
    !!state && terminalDevBoxActionStates.indexOf(state as ActionState) !== -1;

export const isDevBoxInTerminalState = (state: DevBoxState | undefined): boolean =>
    !!state && terminalDevBoxStates.indexOf(state) !== -1;

export const getDevBoxState = (devBox: DevBox): DevBoxState => {
    const { provisioningState, actionState, powerState } = devBox;

    // Note: ProvisionedWithWarning implies it's not a failure state, but all errorDetails we've seen associated with
    // this state indicate unrecoverable failures.
    if (
        provisioningState === DevBoxProvisioningState.Failed ||
        provisioningState === DevBoxProvisioningState.ProvisionedWithWarning
    ) {
        return DevBoxState.Failed;
    }

    if (provisioningState && !isTerminalDevBoxProvisioningState(provisioningState)) {
        switch (provisioningState) {
            case DevBoxProvisioningState.Provisioning:
            case DevBoxProvisioningState.Creating:
                return DevBoxState.Creating;
            case DevBoxProvisioningState.Deleting:
                return DevBoxState.Deleting;
        }
    }

    if (!isTerminalDevBoxActionState(actionState)) {
        switch (actionState) {
            case ActionState.Starting:
                return DevBoxState.Starting;
            case ActionState.Stopping:
                return DevBoxState.Stopping;
            case ActionState.Restarting:
                return DevBoxState.Restarting;
            case ActionState.Repairing:
                return DevBoxState.Repairing;
            case ActionState.Restoring:
                return DevBoxState.Restoring;
        }
    }

    if (!!powerState) {
        switch (powerState) {
            case PowerState.Running:
                return DevBoxState.Running;
            case PowerState.Hibernated:
                return DevBoxState.Hibernated;
            case PowerState.Stopped:
            case PowerState.Deallocated:
            case PowerState.PoweredOff:
            case PowerState.Unknown:
                return DevBoxState.Stopped;
        }
    }

    switch (actionState) {
        case ActionState.Unknown:
        case ActionState.Stopped:
            return DevBoxState.Stopped;
        case ActionState.Started:
            return DevBoxState.Running;
        case ActionState.Failed:
            return DevBoxState.Failed;
        case ActionState.Repaired:
            return DevBoxState.Repaired;
        case ActionState.Restored:
            return DevBoxState.Restored;
    }

    // If we reach this point, we are not sure of the state of the dev box so return failed instead.
    // Note: This is the final state if any of the other states are not recognized from the provisioning state,
    // action state, or power state. If a new state is added, it should be added to the switch statements above.
    return DevBoxState.Failed;
};

export const getFailureOnDevBoxFromDevBox = (devBox: DevBox): FailureOnDevBox | undefined => {
    const { error, provisioningState } = devBox;

    // No failure occurred if there's no error on the environment
    if (!error) {
        return undefined;
    }

    switch (provisioningState) {
        case DevBoxProvisioningState.Failed:
            return {
                failure: createFailureResponseFromAzureCoreFoundationsErrorBody(error),
                reason: DevBoxFailureReason.ProvisioningFailed,
            };
        case DevBoxProvisioningState.ProvisionedWithWarning:
            return {
                failure: createFailureResponseFromAzureCoreFoundationsErrorBody(error),
                reason: DevBoxFailureReason.ProvisionedWithWarning,
            };

        default:
            return undefined;
    }
};
