import { IIconProps, MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { PropsWithChildren } from '../../types/props-with-children';
import { MessageBannerActions } from './message-banner-actions';

type MessageBannerProps = PropsWithChildren<{
    iconProps?: IIconProps;
    onDismiss?: () => void;
    onSeeDetails?: () => void;
    messageBarType?: MessageBarType;
}>;

export const MessageBanner: React.FC<MessageBannerProps> = (props: MessageBannerProps) => {
    const { children, iconProps, onDismiss, onSeeDetails, messageBarType } = props;

    const messageBannerActions = React.useMemo(() => {
        return <MessageBannerActions onSeeDetails={onSeeDetails} />;
    }, [onSeeDetails]);

    return (
        <MessageBar
            actions={messageBannerActions}
            delayedRender={false}
            messageBarIconProps={iconProps}
            messageBarType={messageBarType}
            onDismiss={onDismiss}
        >
            {children}
        </MessageBar>
    );
};

export default MessageBanner;
