import {
    Button,
    Divider,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    FluentProvider,
    makeStyles,
    mergeClasses,
    OverlayDrawer,
    tokens,
    typographyStyles,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDeploymentLogsPanelContext } from '../../../hooks/context/panels';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { EnvironmentOperationViewModel } from '../models';
import { getGroupedOperationsByDate } from './selectors';
import { DeploymentLogsDetails } from './deployment-logs-details';

export interface DeploymentLogsPanelProps {
    environmentOperations: EnvironmentOperationViewModel[];
    isOpen: boolean;
    name: string;
    onDismiss: () => void;
}

const messages = defineMessages({
    deploymentLogsPanelCloseButtonAriaLabel: {
        id: 'DeploymentLogsPanel_Close_AriaLabel',
        defaultMessage: 'Close deployment logs panel',
        description: 'Aria label for the deployment logs panel close button.',
    },
});

/**
 * Styles
 */

const useHeaderStyles = makeStyles({
    root: {
        paddingBottom: '12px',
    },
});

const useBodyStyles = makeStyles({
    root: {
        gap: '16px',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    },
    divider: {
        paddingTop: '5px',
        paddingBottom: '4px',
    },
});

const useItemStyles = makeStyles({
    subTitleText: typographyStyles.subtitle2,
    infoText: {
        ...typographyStyles.caption1,
        color: tokens.colorNeutralForeground2,
    },
});

/**
 * END Styles
 */

const DeploymentLogsPanelComponent: React.FC<DeploymentLogsPanelProps> = (props: DeploymentLogsPanelProps) => {
    const { environmentOperations, isOpen, name, onDismiss } = props;
    const operationsCount = environmentOperations.length;

    // Intl hooks
    const { formatMessage } = useIntl();

    const theme = useCurrentFluent2Theme();

    // Style hooks
    const stackStyles = useStackWithFullWidthItemStyles();
    const bodyStyles = useBodyStyles();
    const headerStyles = useHeaderStyles();
    const itemStyles = useItemStyles();

    // Memoized data

    const numOperationsCompleted = React.useMemo(() => ({ operationsCount }), [operationsCount]);

    const infoText = React.useMemo(() => {
        return (
            <FormattedMessage
                id="DeploymentLogsPanel_OperationsCompleted_Count"
                defaultMessage="{operationsCount} operations completed"
                description="Number of operations in deployment logs"
                values={numOperationsCompleted}
            />
        );
    }, [numOperationsCompleted]);

    // Format date into MM/DD/YY and group operations by date
    const groupedOperations = React.useMemo(
        () => getGroupedOperationsByDate(environmentOperations),
        [environmentOperations]
    );

    return (
        <FluentProvider theme={theme}>
            <OverlayDrawer open={isOpen} position="end" size="medium" onOpenChange={onDismiss}>
                <DrawerHeader className={headerStyles.root}>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label={formatMessage(messages.deploymentLogsPanelCloseButtonAriaLabel)}
                                icon={<Dismiss24Regular />}
                                onClick={onDismiss}
                            />
                        }
                    >
                        <FormattedMessage
                            id="DeploymentLogsPanel_Header_Text"
                            defaultMessage="Deployment Logs"
                            description="Header text for the deployment logs panel"
                        />
                    </DrawerHeaderTitle>
                </DrawerHeader>

                <DrawerBody role="treegrid">
                    <div className={mergeClasses(stackStyles.root, bodyStyles.root)}>
                        {/* Subheading (Environment name and number of operations) */}
                        <div className={mergeClasses(stackStyles.item, bodyStyles.item)}>
                            <div className={itemStyles.subTitleText}>{name}</div>
                            <div className={itemStyles.infoText}>{infoText}</div>
                        </div>

                        <Divider className={bodyStyles.divider} />

                        {/* Displays operations grouped by date */}
                        {groupedOperations.map(([date, operations]) => (
                            <DeploymentLogsDetails key={date} date={date} operations={operations} />
                        ))}
                    </div>
                </DrawerBody>
            </OverlayDrawer>
        </FluentProvider>
    );
};

export const DeploymentLogsPanelContextWrapper: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useDeploymentLogsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <DeploymentLogsPanelComponent {...properties} isOpen={isOpen} onDismiss={closePanel} />;
};

export default DeploymentLogsPanelContextWrapper;
