export const doAsync = <TFunc extends () => ReturnType<TFunc>>(fn: TFunc): Promise<ReturnType<TFunc>> =>
    new Promise((resolve, reject) => {
        // Note: setTimeout with 0ms delay ensures execution is deferred but still occurs as soon as possible.
        setTimeout(() => {
            try {
                const result = fn();
                resolve(result);
            } catch (err) {
                reject(err);
            }
        }, 0);
    });
