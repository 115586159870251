import {
    FontWeights,
    GroupHeader,
    IGroupHeaderProps,
    ITheme,
    Icon,
    Link,
    makeStyles as legacyMakeStyles,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { Duration, Interval } from 'luxon';
import { FluentIconNames } from './fluent-icon-names';
import { useDynamicMakeStyles } from '../../hooks/styling';
import { useHorizontalStackStyles } from '../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../utilities/styles';

export interface CustomizedGroupListHeaderProps {
    statusIcon?: JSX.Element;
    groupHeaderProps?: IGroupHeaderProps;
    hasParameters: boolean;
    startTime?: Date;
    endTime?: Date;
    logUrl?: string;
    logFileName?: string;
}

/**
 * Styles
 */

const groupHeaderStylesFactory = (isCollapsed: boolean, hasParameters: boolean) =>
    legacyMakeStyles((theme: ITheme) => ({
        expand: {
            selectors: {
                ':hover': {
                    backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                },
            },
            display: hasParameters ? '' : 'none',
        },
        expandIsCollapsed: {
            color: isCollapsed ? getSemanticColor(theme, 'expandIcon') : getSemanticColor(theme, 'activeExpandIcon'),
        },
        groupHeaderContainer: {
            backgroundColor: isCollapsed
                ? getSemanticColor(theme, 'collapsedCustomizationTaskBackground')
                : getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
            margin: isCollapsed ? '6px 0' : '6px 0 0 0',
            fontWeight: isCollapsed ? FontWeights.regular : FontWeights.bold,
            selectors: {
                ':hover': {
                    backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                },
            },
            padding: hasParameters ? '0' : '0 0 0 35px',
        },
    }));

const timeStylesFactory = () =>
    legacyMakeStyles((theme: ITheme) => ({
        root: {
            color: getSemanticColor(theme, 'customizationTaskTimeText'),
            paddingRight: '2px',
            marginLeft: 'auto',
        },
    }));

const useDownloadContainerStyles = makeStyles({
    root: {
        marginLeft: '',
    },
});

const useGroupHeaderTitleStyles = makeStyles({
    root: {
        gap: '8px',
        alignItems: 'center',
        width: '100%',
    },
});

const useTitleTextStyles = makeStyles({
    collapsed: {
        fontWeight: FontWeights.regular,
    },
    expanded: {
        fontWeight: FontWeights.bold,
    },
});

const useDownloadButtonStyles = legacyMakeStyles((theme: ITheme) => ({
    root: {
        color: getSemanticColor(theme, 'downloadButtonText'),
        selectors: {
            ':hover': {
                textDecoration: 'none',
            },
        },
    },
}));

const useDownloadIconStyles = makeStyles({
    root: {
        padding: '0 8px 0 0',
    },
});

/**
 * End Styles
 */

export const CustomizedGroupListHeader: React.FC<CustomizedGroupListHeaderProps> = (
    props: CustomizedGroupListHeaderProps
) => {
    const { statusIcon, groupHeaderProps, hasParameters, startTime, endTime, logUrl, logFileName } = props;
    const { group } = groupHeaderProps ?? {};

    const isCollapsed = group?.isCollapsed ?? true;

    // Style hooks

    const useGroupHeaderStyles = useDynamicMakeStyles(
        groupHeaderStylesFactory,
        group?.isCollapsed ?? true,
        hasParameters
    );
    const useTimeStyles = useDynamicMakeStyles(timeStylesFactory);

    const groupHeaderStyles = useGroupHeaderStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const groupHeaderTitleStyles = useGroupHeaderTitleStyles();
    const titleTextStyles = useTitleTextStyles();
    const timeStyles = useTimeStyles();
    const downloadContainerStyles = useDownloadContainerStyles();
    const downloadButtonStyles = useDownloadButtonStyles();
    const downloadIconStyles = useDownloadIconStyles();

    const timeTaken = React.useMemo(
        () =>
            !!endTime && !!startTime
                ? Interval.fromDateTimes(startTime, endTime).toDuration(['minutes', 'seconds'])
                : 0,
        [startTime, endTime]
    );

    const completionTime = React.useMemo(() => {
        if (startTime === undefined || endTime === undefined) {
            return <></>;
        }

        const minutes = Math.round((timeTaken as Duration).minutes);
        const seconds = Math.round((timeTaken as Duration).seconds);

        const formattedSeconds = <FormattedNumber value={seconds} style="unit" unit="second" unitDisplay="narrow" />;

        if (minutes !== 0) {
            const formattedMinutes = (
                <FormattedNumber value={minutes} style="unit" unit="minute" unitDisplay="narrow" />
            );
            return (
                <>
                    {formattedMinutes} {formattedSeconds}
                </>
            );
        }

        return formattedSeconds;
    }, [startTime, endTime, timeTaken]);

    const onRenderTitle = React.useCallback(
        (props?: IGroupHeaderProps): JSX.Element => {
            return (
                <div className={mergeClasses(horizontalStackStyles.root, groupHeaderTitleStyles.root)}>
                    {!!statusIcon && <div className={horizontalStackStyles.item}>{statusIcon}</div>}
                    <div
                        className={mergeClasses(
                            horizontalStackStyles.item,
                            isCollapsed ? titleTextStyles.collapsed : titleTextStyles.expanded
                        )}
                    >
                        {props?.group?.name}
                    </div>
                    {startTime && endTime && (
                        <div className={mergeClasses(horizontalStackStyles.item, timeStyles.root)}>
                            {completionTime}
                        </div>
                    )}
                    {startTime && endTime && (
                        <div className={mergeClasses(horizontalStackStyles.item, downloadContainerStyles.root)}>
                            <Link
                                href={logUrl}
                                download={logFileName}
                                target="_blank"
                                rel="noreferrer"
                                styles={downloadButtonStyles}
                            >
                                <Icon iconName={FluentIconNames.Download} styles={downloadIconStyles} />
                            </Link>
                        </div>
                    )}
                </div>
            );
        },
        [statusIcon, timeStyles, startTime, endTime, completionTime, titleTextStyles, isCollapsed]
    );

    return <GroupHeader onRenderTitle={onRenderTitle} styles={groupHeaderStyles} {...groupHeaderProps} />;
};
