import { MessageBarButton } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useHorizontalStackStyles } from '../../themes/styles/flexbox-styles';

interface MessageBannerActionsProps {
    onSeeDetails?: () => void;
}

const messages = defineMessages({
    seeDetailsButtonAriaLabel: {
        id: 'MessageBannerActions_SeeDetailsButton_AriaLabel',
        defaultMessage: 'See details',
        description: 'Aria label of the "See details" button in the info banner',
    },
});

/**
 * Styles
 */

const useBannerStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

/**
 * End Styles
 */

export const MessageBannerActions: React.FC<MessageBannerActionsProps> = React.memo(
    (props: MessageBannerActionsProps) => {
        const { onSeeDetails } = props;

        // Style hooks
        const horizontalStackStyles = useHorizontalStackStyles();
        const bannerStyles = useBannerStyles();

        // Intl hooks
        const { formatMessage } = useIntl();

        const showSeeDetailsButton = !!onSeeDetails;

        if (!showSeeDetailsButton) {
            return <></>;
        }

        return (
            <div className={mergeClasses(horizontalStackStyles.root, bannerStyles.root)}>
                {onSeeDetails && (
                    <div className={horizontalStackStyles.item}>
                        <MessageBarButton
                            ariaLabel={formatMessage(messages.seeDetailsButtonAriaLabel)}
                            onClick={onSeeDetails}
                        >
                            <FormattedMessage
                                id="MessageBannerActions_SeeDetailsButton_Text"
                                defaultMessage="See details"
                                description="Text of the 'See details' button in the info banner"
                            />
                        </MessageBarButton>
                    </div>
                )}
            </div>
        );
    }
);

export default MessageBannerActions;
