import { ApiVersion } from '../../../constants/azure';
import { ContentType, Method } from '../../../constants/http';
import { getTokensFromEnvironmentDataPlaneUri } from '../../../ids/environment';
import {
    createEnvironmentOperationDataPlaneUri,
    getTokensFromEnvironmentOperationDataPlaneUri,
} from '../../../ids/environment-operation';
import { DataResponse, FailureOperation } from '../../../models/common';
import { EnvironmentOperationLogBlob } from '../../../models/environment';
import {
    createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault,
    tryParseErrorResponse,
} from '../../../utilities/failure';
import { EnvironmentOperationContract } from '../../contracts/environment-operation';
import { FetchOptions, fetchRequest } from '../fetch-request';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type GetEnvironmentOperationLogResponse = DataResponse<EnvironmentOperationLogBlob>;
export type GetEnvironmentOperationResponse = DataResponse<EnvironmentOperationContract>;
export type ListEnvironmentOperationsResponse = DataResponse<EnvironmentOperationContract[]>;

export const getEnvironmentOperation = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetEnvironmentOperationResponse> => {
    const { devCenter, environmentName, environmentOperationId, projectName, user } =
        getTokensFromEnvironmentOperationDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetEnvironmentOperation,

        whenUsingBetaClient: (client) =>
            client.environments.getOperation(
                projectName,
                user,
                environmentName,
                environmentOperationId,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.environments.getOperation(
                projectName,
                user,
                environmentName,
                environmentOperationId,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const listEnvironmentOperations = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListEnvironmentOperationsResponse> => {
    const { devCenter, environmentName, projectName, user } = getTokensFromEnvironmentDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListEnvironmentOperations,

        whenUsingBetaClient: (client) =>
            client.environments.listOperations(
                projectName,
                user,
                environmentName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.environments.listOperations(
                projectName,
                user,
                environmentName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const getEnvironmentOperationLog = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetEnvironmentOperationLogResponse> => {
    const { devCenter, environmentName, environmentOperationId, projectName, user } =
        getTokensFromEnvironmentOperationDataPlaneUri(id);

    const options: FetchOptions<Response> = {
        activityId,
        contentType: ContentType.TextPlain,
        accessToken,
    };

    const environmentOperationLogApiUri = createEnvironmentOperationDataPlaneUri({
        devCenter,
        environmentName,
        environmentOperationId,
        projectName,
        user,
    });

    const result = await fetchRequest(
        `${environmentOperationLogApiUri}/logs?api-version=${ApiVersion.DevCenterDataPlaneStable}`,
        Method.GET,
        options
    );

    if (result.status !== 200) {
        const errorResponse = await tryParseErrorResponse(result);
        return createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault(
            errorResponse,
            FailureOperation.GetEnvironmentOperationLog
        );
    }

    return {
        data: {
            operationId: environmentOperationId,
            logBlob: await result.blob(),
        },
        succeeded: true,
    };
};
