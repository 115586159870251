import {
    CalendarEdit20Regular,
    CloudArrowUp20Regular,
    Delete20Regular,
    Info20Regular,
    Notepad20Regular,
} from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import environmentCardPreviewImage from '../../../content/images/wallpaper-environment.jpg';
import { ExtendedIcon } from '../../../icons/initialize-extended-icons';
import { Failure } from '../../../models/common';
import { Tenant } from '../../../models/resource-manager';
import { getSelectedTenant } from '../../../redux/selector/tenant-selector';
import { isNotUndefinedOrWhiteSpace } from '../../../utilities/string';
import { ConfirmationDialogProperties } from '../../common/confirmation-dialog/contexts';
import { FluentIconNames } from '../../common/fluent-icon-names';
import { OldMetadataItemViewModel } from '../../common/metadata/models';
import { RedeployEnvironmentPanelContextProperties } from '../../redeploy-environment-panel/contexts';
import ResourceCard from '../../resource-card/resource-card';
import { ResourceCardAction } from '../../resource-card/resource-card-action';
import { ChangeEnvironmentExpirationDialogProperties } from '../change-expiration-dialog/contexts';
import { EnvironmentDetailsPanelContextProperties } from '../environment-details-panel/context';
import { DeploymentLogsPanelContextProperties } from '../deployment-logs-panel/context';
import { EnvironmentViewModel } from '../models';
import { RedeployEnvironmentDialogProperties } from '../redeploy-environment-dialog/contexts';
import {
    EnvironmentTypeMetadata,
    ExpirationDateOffsetMetadata,
    getShimmeredEnvironmentMetadata,
    OwnerMetadata,
    ResourceGroupMetadata,
} from './environment-metadata';
import EnvironmentStateMessage from './environment-state-message';
import EnvironmentStatusImage from './environment-status-image';
import {
    getAreActionsDisabled,
    getEnvironmentBannerViewModel,
    getMetadata,
    getResourceCardError,
    getSecondaryActions,
    getUseTranslucentPreviewImage,
} from './selectors';

interface EnvironmentCardProps {
    environment: EnvironmentViewModel;
    onDeleteSubmitted: (resourceId: string) => void;
    onSeeErrorDetails: (failure: Failure) => void;
    onOpenConfirmationDialog: (properties: ConfirmationDialogProperties) => void;
    onOpenRedeployDialog: (properties: RedeployEnvironmentDialogProperties) => void;
    onOpenRedeployEnvironmentPanel: (properties: RedeployEnvironmentPanelContextProperties) => void;
    onClearRedeployEnvironmentFailure: () => void;
    onOpenChangeEnvironmentExpirationDialog: (properties: ChangeEnvironmentExpirationDialogProperties) => void;
    onOpenEnvironmentDetailsPanel: (props: EnvironmentDetailsPanelContextProperties) => void;
    onOpenDeploymentLogsPanel: (props: DeploymentLogsPanelContextProperties) => void;
}

interface EnvironmentCardComponentProps extends EnvironmentCardProps {
    selectedTenant?: Tenant;
}

const messages = defineMessages({
    deleteDialogPrimaryButtonAriaLabel: {
        id: 'EnvironmentCard_DeleteDialogPrimaryButton_AriaLabel',
        defaultMessage: 'Delete the environment',
        description: 'Aria label for the submit button to confirm deleting the environment',
    },
    deleteDialogPrimaryButtonText: {
        id: 'EnvironmentCard_DeleteDialogPrimaryButton_Text',
        defaultMessage: 'Delete',
        description: 'Confirmation button to delete the environment',
    },
    deleteDialogSubText: {
        id: 'EnvironmentCard_DeleteDialogSubText_Text',
        defaultMessage:
            'This environment and all its data will be permanently removed. Are you sure you want to delete {name}?',
        description:
            'Informs the user about the effects of deleting the environment and confirms. {name} should not be localized, it is the name of the environment.',
    },
    deleteDialogTitle: {
        id: 'EnvironmentCard_DeleteDialogTitle_Text',
        defaultMessage: 'Delete environment',
        description: 'Header text for environment delete dialog',
    },
    deleteMenuItemAriaLabel: {
        id: 'EnvironmentCard_DeleteMenuItem_AriaLabel',
        defaultMessage: 'Delete environment',
        description: 'Aria label for "Delete" contextual menu item in environment card\'s settings button.',
    },
    environmentTypeIconAriaLabel: {
        id: 'EnvironmentCard_EnvironmentTypeIcon_AriaLabel',
        defaultMessage: 'Environment type',
        description: 'Aria label for the environment type icon in the environment card',
    },
    ownerIconAriaLabel: {
        id: 'EnvironmentCard_OwnerIcon_AriaLabel',
        defaultMessage: 'Owner',
        description: 'Aria label for the owner icon in the environment card',
    },
    expirationDateIconAriaLabel: {
        id: 'EnvironmentCard_ExpirationDateIcon_AriaLabel',
        defaultMessage: 'Expiration date',
        description: 'Aria label for the expiration date icon in the environment card',
    },
    redeployMenuItemAriaLabel: {
        id: 'EnvironmentCard_RedeployMenuItem_AriaLabel',
        defaultMessage: 'Redeploy environment',
        description: 'Aria label for "Redeploy" contextual menu item in environment card\'s settings button.',
    },
    resourceGroupIconAriaLabel: {
        id: 'EnvironmentCard_ResourceGroupIcon_AriaLabel',
        defaultMessage: 'Resources',
        description: 'Aria label for the resources icon in the environment card',
    },
    redeployDialogSubText: {
        id: 'EnvironmentCard_RedeployDialogSubText_Text',
        defaultMessage:
            'The environment definition {environmentDefinitionName} has no parameters, and will be redeployed immediately.',
        description:
            'Informs the user that their environment will be redeployed. {environmentDefinitionName} should not be localized, it is the name of the environment definition.',
    },
    redeployDialogTitle: {
        id: 'EnvironmentCard_RedeployDialogTitle_Text',
        defaultMessage: 'Redeploy {environmentName}',
        description:
            'Header text for environment redeploy dialog. {environmentName} should not be localized, it is the name of the environment to be redeployed.',
    },
    changeExpirationDateMenuItemAriaLabel: {
        id: 'EnvironmentCard_ChangeExpirationMenuItem_AriaLabel',
        defaultMessage: 'Change expiration',
        description: 'Aria label for "Change expiration" contextual menu item in environment card\'s settings button.',
    },
    moreInfoMenuItemAriaLabel: {
        id: 'EnvironmentCard_MoreInfoMenuItem_AriaLabel',
        defaultMessage: 'More info',
        description: 'Aria label for "More info" contextual menu item in environment card\'s settings button.',
    },
    deploymentLogsItemAriaLabel: {
        id: 'EnvironmentCard_DeploymentLogsItem_AriaLabel',
        defaultMessage: 'Deployment logs',
        description: 'Aria label for "Deployment logs" contextual menu in environment card\'s settings button.',
    },
});

export const EnvironmentCardComponent: React.FC<EnvironmentCardComponentProps> = React.memo(
    (props: EnvironmentCardComponentProps) => {
        const {
            environment,
            onDeleteSubmitted,
            onSeeErrorDetails,
            selectedTenant,
            onOpenConfirmationDialog,
            onOpenRedeployEnvironmentPanel,
            onClearRedeployEnvironmentFailure,
            onOpenRedeployDialog,
            onOpenChangeEnvironmentExpirationDialog,
            onOpenEnvironmentDetailsPanel,
            onOpenDeploymentLogsPanel,
        } = props;

        const {
            resource,
            state,
            projectName: projectResourceName,
            projectDisplayName,
            ownerDisplayName,
            environmentDefinition,
            ownerIsReady,
            isCardContentReady,
            failureOnEnvironment,
            expirationDateOffset,
            environmentOperations,
        } = environment;
        const { failure } = failureOnEnvironment ?? {};
        const { environmentDefinitionName, environmentType, expirationDate, name, resourceGroupId, uri } = resource;
        const environmentDefinitionParameters = environmentDefinition?.parameters ?? [];

        // Intl hooks
        const { formatMessage } = useIntl();

        // Note: putting these memoizers here since they're used within callbacks
        const resourceCardError = React.useMemo(
            () => getResourceCardError(failureOnEnvironment, state),
            [failureOnEnvironment, state]
        );

        const hasFailure = !!failure;

        const isEnvironmentDefinitionReady = !!environmentDefinition;
        const isShimmered = !isCardContentReady;

        // Callback hooks
        const onDeleteDialogPrimaryButtonClicked = React.useCallback(() => {
            onDeleteSubmitted(uri);
        }, [onDeleteSubmitted, uri]);

        const stateElement = <EnvironmentStateMessage state={state} />;
        const statusImageElement = <EnvironmentStatusImage name={name} state={state} />;

        const onSeeErrorDetailsClicked = React.useCallback(() => {
            if (!hasFailure) {
                return;
            }

            onSeeErrorDetails(failure);
        }, [hasFailure, failure, onSeeErrorDetails]);

        // Memoized data
        const areActionsDisabled = React.useMemo(() => getAreActionsDisabled(state), [state]);
        const deleteDialogSubTextValues = React.useMemo(() => ({ name }), [name]);
        const useTranslucentPreviewImage = React.useMemo(() => getUseTranslucentPreviewImage(state), [state]);
        const hasParameters = environmentDefinitionParameters.length > 0;

        const projectName = React.useMemo(
            () => (isNotUndefinedOrWhiteSpace(projectDisplayName) ? projectDisplayName : projectResourceName),
            [projectDisplayName, projectResourceName]
        );

        // Resource card actions
        // NOTE: Icon names used below ('Delete', 'Redeploy', etc.) can be found at https://github.com/microsoft/fluentui-system-icons/blob/main/icons_regular.md

        // Delete action
        const onDeleteMenuItemClicked = React.useCallback(() => {
            onOpenConfirmationDialog({
                onPrimaryButtonSubmitted: onDeleteDialogPrimaryButtonClicked,
                primaryButtonAriaLabel: formatMessage(messages.deleteDialogPrimaryButtonAriaLabel),
                primaryButtonText: formatMessage(messages.deleteDialogPrimaryButtonText),
                subText: formatMessage(messages.deleteDialogSubText, deleteDialogSubTextValues),
                title: formatMessage(messages.deleteDialogTitle),
            });
        }, [onOpenConfirmationDialog, onDeleteDialogPrimaryButtonClicked, deleteDialogSubTextValues, formatMessage]);

        const deleteMenuItem = React.useMemo(
            () => (
                <ResourceCardAction
                    ariaLabel={formatMessage(messages.deleteMenuItemAriaLabel)}
                    onClick={onDeleteMenuItemClicked}
                    icon={<Delete20Regular />}
                    key="delete"
                >
                    <FormattedMessage
                        id="EnvironmentCard_DeleteMenuItem_Text"
                        defaultMessage="Delete"
                        description='Text for "Delete" contextual menu item in environment cards settings button.'
                    />
                </ResourceCardAction>
            ),
            [formatMessage, onDeleteMenuItemClicked]
        );

        // Redeploy action
        const onOpenRedeployConfirmationDialog = React.useCallback(() => {
            onOpenRedeployDialog({
                environment: resource,
            });
        }, [onOpenRedeployDialog, resource]);

        const onRedeployMenuItemClicked = React.useCallback(() => {
            onClearRedeployEnvironmentFailure();

            if (hasParameters) {
                onOpenRedeployEnvironmentPanel({ environment });
            } else {
                onOpenRedeployConfirmationDialog();
            }
        }, [
            onClearRedeployEnvironmentFailure,
            hasParameters,
            onOpenRedeployEnvironmentPanel,
            onOpenRedeployConfirmationDialog,
            environment,
        ]);

        const redeployMenuItem = React.useMemo(
            () => (
                <ResourceCardAction
                    ariaLabel={formatMessage(messages.redeployMenuItemAriaLabel)}
                    onClick={onRedeployMenuItemClicked}
                    icon={<CloudArrowUp20Regular />}
                    key="deploy"
                >
                    <FormattedMessage
                        id="EnvironmentCard_RedeployMenuItem_Text"
                        defaultMessage="Redeploy"
                        description='Text for "Redeploy" contextual menu item in environment cards settings button.'
                    />
                </ResourceCardAction>
            ),
            [formatMessage, onRedeployMenuItemClicked]
        );

        // Change delete expiration action
        const onChangeEnvironmentExpirationMenuItemItemClicked = React.useCallback(() => {
            onOpenChangeEnvironmentExpirationDialog({ environment: resource });
        }, [onOpenChangeEnvironmentExpirationDialog, resource]);

        const changeExpirationMenuItem = React.useMemo(
            () => (
                <ResourceCardAction
                    ariaLabel={formatMessage(messages.changeExpirationDateMenuItemAriaLabel)}
                    onClick={onChangeEnvironmentExpirationMenuItemItemClicked}
                    icon={<CalendarEdit20Regular />}
                    key="changeExpiration"
                >
                    <FormattedMessage
                        id="EnvironmentCard_ChangeExpirationMenuItem_Text"
                        defaultMessage="Change expiration"
                        description='Text for "Change expiration" contextual menu item in environment cards settings button.'
                    />
                </ResourceCardAction>
            ),
            [formatMessage, onChangeEnvironmentExpirationMenuItemItemClicked]
        );

        // Show more info action
        const onMoreInfoMenuItemClicked = React.useCallback(() => {
            onOpenEnvironmentDetailsPanel({
                expirationDate,
                environmentDefinitionName,
                name,
            });
        }, [onOpenEnvironmentDetailsPanel, expirationDate, environmentDefinitionName, name]);

        const moreInfoMenuItem = React.useMemo(
            () => (
                <ResourceCardAction
                    ariaLabel={formatMessage(messages.moreInfoMenuItemAriaLabel)}
                    onClick={onMoreInfoMenuItemClicked}
                    icon={<Info20Regular />}
                    key="moreInfo"
                >
                    <FormattedMessage
                        id="EnvironmentCard_MoreInfoMenuItem_Text"
                        defaultMessage="More info"
                        description='Text for "More info" contextual menu item in environment card settings button.'
                    />
                </ResourceCardAction>
            ),
            [formatMessage, onMoreInfoMenuItemClicked]
        );

        // Show deployment logs action
        const onDeploymentLogsItemClicked = React.useCallback(() => {
            onOpenDeploymentLogsPanel({ environmentOperations, name });
        }, [onOpenDeploymentLogsPanel, environmentOperations, name]);

        const deploymentLogsItem = React.useMemo(
            () => (
                <ResourceCardAction
                    ariaLabel={formatMessage(messages.deploymentLogsItemAriaLabel)}
                    onClick={onDeploymentLogsItemClicked}
                    icon={<Notepad20Regular />}
                    key="deploymentLogs"
                >
                    <FormattedMessage
                        id="EnvironmentCard_DeploymentLogsMenuItem_Text"
                        defaultMessage="Deployment logs"
                        description='Text for "Deployment logs" contextual menu item in environment card settings button.'
                    />
                </ResourceCardAction>
            ),
            [formatMessage, onDeploymentLogsItemClicked]
        );

        const secondaryActions = React.useMemo(
            () =>
                getSecondaryActions(
                    deleteMenuItem,
                    redeployMenuItem,
                    changeExpirationMenuItem,
                    state,
                    isEnvironmentDefinitionReady,
                    moreInfoMenuItem,
                    deploymentLogsItem
                ),
            [
                deleteMenuItem,
                redeployMenuItem,
                changeExpirationMenuItem,
                state,
                isEnvironmentDefinitionReady,
                moreInfoMenuItem,
                deploymentLogsItem,
            ]
        );

        // Resource card metadata
        const environmentTypeMetadata: OldMetadataItemViewModel = React.useMemo(
            () => ({
                description: formatMessage(messages.environmentTypeIconAriaLabel),
                icon: ExtendedIcon.WebEnvironment,
                key: 'environment-type',
                primary: false,
                value: <EnvironmentTypeMetadata environmentType={environmentType} />,
            }),
            [environmentType, formatMessage]
        );

        const ownerMetadata: OldMetadataItemViewModel = React.useMemo(
            () => ({
                description: formatMessage(messages.ownerIconAriaLabel),
                icon: 'Contact',
                key: 'owner',
                primary: false,
                value: <OwnerMetadata ownerDisplayName={ownerDisplayName} ownerIsReady={ownerIsReady} />,
            }),
            [ownerDisplayName, ownerIsReady, formatMessage]
        );

        const expirationDateMetadata: OldMetadataItemViewModel | undefined = React.useMemo(
            () =>
                !!expirationDateOffset?.offset && !!expirationDateOffset?.unit
                    ? {
                          description: formatMessage(messages.expirationDateIconAriaLabel),
                          icon: FluentIconNames.Calendar,
                          key: 'expiration-date',
                          primary: false,
                          value: <ExpirationDateOffsetMetadata expirationDateOffset={expirationDateOffset} />,
                      }
                    : undefined,
            [expirationDateOffset, formatMessage]
        );

        const resourceGroupMetadata: OldMetadataItemViewModel | undefined = React.useMemo(() => {
            if (!resourceGroupId) {
                return undefined;
            }

            return {
                description: formatMessage(messages.resourceGroupIconAriaLabel),
                icon: 'NavigateExternalInline',
                key: 'resource-group',
                primary: true,
                value: (
                    <ResourceGroupMetadata
                        resourceGroupId={resourceGroupId}
                        tenantDomain={selectedTenant?.defaultDomain}
                        environmentName={name}
                    />
                ),
            };
        }, [resourceGroupId, selectedTenant?.defaultDomain, name, formatMessage]);

        const metadata = React.useMemo(
            () =>
                getMetadata(
                    environmentTypeMetadata,
                    ownerMetadata,
                    resourceGroupMetadata,
                    expirationDateMetadata,
                    state
                ),
            [environmentTypeMetadata, ownerMetadata, resourceGroupMetadata, expirationDateMetadata, state]
        );

        const hasResourceGroup = !!resourceGroupId;
        const shimmeredEnvironmentMetadata = React.useMemo(
            () => getShimmeredEnvironmentMetadata(hasResourceGroup),
            [hasResourceGroup]
        );

        const cardBanner = React.useMemo(
            () => getEnvironmentBannerViewModel(resourceCardError, hasFailure ? onSeeErrorDetailsClicked : undefined),
            [resourceCardError, onSeeErrorDetailsClicked, hasFailure]
        );

        return (
            <ResourceCard
                areSecondaryActionsDisabled={areActionsDisabled}
                metadata={metadata}
                name={name}
                stateElement={stateElement}
                statusImageElement={statusImageElement}
                previewImageSrc={environmentCardPreviewImage}
                projectName={projectName}
                secondaryActions={secondaryActions}
                useTranslucentPreviewImage={useTranslucentPreviewImage}
                displayStateAtTheBottom
                isShimmered={isShimmered}
                shimmeredMetadata={shimmeredEnvironmentMetadata}
                cardBanner={cardBanner}
            />
        );
    }
);

export const EnvironmentCardContainer: React.FC<EnvironmentCardProps> = (props: EnvironmentCardProps) => {
    // Application state hooks
    const selectedTenant = useSelector(getSelectedTenant);

    return <EnvironmentCardComponent {...props} selectedTenant={selectedTenant} />;
};

export default EnvironmentCardContainer;
