import { getTokensFromDevBoxDataPlaneUri } from '../../../ids/dev-box';
import { getTokensFromProjectDataPlaneUri } from '../../../ids/project';
import { DataResponse, FailureOperation, isFailureResponse } from '../../../models/common';
import { LongRunningOperationResponseContract } from '../../contracts/common';
import {
    DevBoxContract,
    DevBoxSnapshotContract,
    PutDevBoxBodyContract,
    RemoteConnectionContract,
} from '../../contracts/dev-box';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type CreateDevBoxResponse = DataResponse<DevBoxContract>;
export type DeleteDevBoxResponse = DataResponse<LongRunningOperationResponseContract>;
export type GetDevBoxResponse = DataResponse<DevBoxContract>;
export type GetRemoteConnectionResponse = DataResponse<RemoteConnectionContract>;
export type ListDevBoxesResponse = DataResponse<DevBoxContract[]>;
export type ListSnapshotsResponse = DataResponse<DevBoxSnapshotContract[]>;
export type RepairDevBoxResponse = DataResponse<LongRunningOperationResponseContract>;
export type RestartDevBoxResponse = DataResponse<LongRunningOperationResponseContract>;
export type StartDevBoxResponse = DataResponse<LongRunningOperationResponseContract>;
export type StopDevBoxResponse = DataResponse<LongRunningOperationResponseContract>;
export type CaptureSnapshotResponse = DataResponse<LongRunningOperationResponseContract>;
export type RestoreSnapshotResponse = DataResponse<LongRunningOperationResponseContract>;
export type GetSnapshotResponse = DataResponse<DevBoxSnapshotContract>;

export const createDevBox = async (
    id: string,
    name: string,
    body: PutDevBoxBodyContract,
    accessToken: string,
    activityId?: string
): Promise<CreateDevBoxResponse> => {
    const { devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.AddDevBox,

        whenUsingBetaClient: (client) =>
            client.devBoxes.createDevBox(projectName, user, name, body, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.createDevBox(projectName, user, name, body, getCommonOptions(accessToken, activityId)),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: name, projectName, and uri should always be defined on responses.
    const devBox: DevBoxContract = {
        ...data,
        name: data.name!,
        projectName: data.projectName!,
        uri: data.uri!,
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: devBox, succeeded: true };
};

export const deleteDevBox = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<DeleteDevBoxResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.DeleteDevBox,

        whenUsingBetaClient: (client) =>
            client.devBoxes.deleteDevBox(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.deleteDevBox(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};

export const getDevBox = async (id: string, accessToken: string, activityId?: string): Promise<GetDevBoxResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetDevBox,

        whenUsingBetaClient: (client) =>
            client.devBoxes.getDevBoxByUser(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.getDevBoxByUser(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: name, projectName, and uri should always be defined on responses.
    const devBox: DevBoxContract = {
        ...data,
        name: data.name!,
        projectName: data.projectName!,
        uri: data.uri!,
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: devBox, succeeded: true };
};

export const getRemoteConnection = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetRemoteConnectionResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetRemoteConnection,

        whenUsingBetaClient: (client) =>
            client.devBoxes.getRemoteConnection(
                projectName,
                user,
                devBoxName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.devBoxes.getRemoteConnection(
                projectName,
                user,
                devBoxName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const listDevBoxes = async (
    id: string,
    user: string,
    accessToken: string,
    activityId?: string
): Promise<ListDevBoxesResponse> => {
    const { devCenter, projectName } = getTokensFromProjectDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListDevBoxes,

        whenUsingBetaClient: (client) =>
            client.devBoxes.listDevBoxesByUser(projectName, user, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.listDevBoxesByUser(projectName, user, getCommonOptions(accessToken, activityId)),
    });

    if (isFailureResponse(result)) {
        return result;
    }

    const { data } = result;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: name, projectName, and uri should always be defined on responses.
    const devBoxes = data.map<DevBoxContract>((value) => ({
        ...value,
        name: value.name!,
        projectName: value.projectName!,
        uri: value.uri!,
    }));
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    return { data: devBoxes, succeeded: true };
};

export const listSnapshots = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListSnapshotsResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.ListSnapshots,

        whenUsingBetaClient: (client) =>
            client.devBoxes.listSnapshots(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.listSnapshots(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};

export const repairDevBox = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<RepairDevBoxResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.RepairDevBox,

        whenUsingBetaClient: (client) =>
            client.devBoxes.repairDevBox(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.repairDevBox(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};

export const restartDevBox = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<RestartDevBoxResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.RestartDevBox,

        whenUsingStableClient: (client) =>
            client.devBoxes.restartDevBox(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),

        whenUsingBetaClient: (client) =>
            client.devBoxes.restartDevBox(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};

export const startDevBox = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<StartDevBoxResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.StartDevBox,

        whenUsingBetaClient: (client) =>
            client.devBoxes.startDevBox(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.startDevBox(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};

export const stopDevBox = async (
    id: string,
    accessToken: string,
    hibernate?: boolean,
    activityId?: string
): Promise<StopDevBoxResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.StopDevBox,

        whenUsingBetaClient: (client) =>
            client.devBoxes.stopDevBox(projectName, user, devBoxName, {
                ...getCommonOptions(accessToken, activityId),
                ...(hibernate !== undefined ? { hibernate } : {}),
            }),

        whenUsingStableClient: (client) =>
            client.devBoxes.stopDevBox(projectName, user, devBoxName, {
                ...getCommonOptions(accessToken, activityId),
                ...(hibernate !== undefined ? { hibernate } : {}),
            }),
    });

    return result;
};

export const captureSnapshot = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<CaptureSnapshotResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.CaptureSnapshot,

        whenUsingBetaClient: (client) =>
            client.devBoxes.captureSnapshot(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),

        whenUsingStableClient: (client) =>
            client.devBoxes.captureSnapshot(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};

export const restoreSnapshot = async (
    id: string,
    accessToken: string,
    snapshotId: string,
    activityId?: string
): Promise<RestoreSnapshotResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.RestoreSnapshot,

        whenUsingBetaClient: (client) =>
            client.devBoxes.restoreSnapshot(
                projectName,
                user,
                devBoxName,
                snapshotId,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.devBoxes.restoreSnapshot(
                projectName,
                user,
                devBoxName,
                snapshotId,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const getSnapshot = async (
    id: string,
    accessToken: string,
    snapshotId: string,
    activityId?: string
): Promise<GetSnapshotResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetSnapshot,

        whenUsingBetaClient: (client) =>
            client.devBoxes.getSnapshot(
                projectName,
                user,
                devBoxName,
                snapshotId,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingStableClient: (client) =>
            client.devBoxes.getSnapshot(
                projectName,
                user,
                devBoxName,
                snapshotId,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};
