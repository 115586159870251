import { EnvironmentProvisioningState } from '../../constants/environment';
import { Environment, FailureOnEnvironment } from '../../models/environment';
import { UnionMap } from '../../types/union-map';
import { EnvironmentDefinitionParameterViewModel } from './parameters-field/models';

export type EnvironmentExpirationDateOffsetUnit =
    | 'Years'
    | 'Months'
    | 'Weeks'
    | 'Days'
    | 'Hours'
    | 'Minutes'
    | 'Seconds';

export const EnvironmentExpirationDateOffsetUnit: UnionMap<EnvironmentExpirationDateOffsetUnit> = {
    Years: 'Years',
    Months: 'Months',
    Weeks: 'Weeks',
    Days: 'Days',
    Hours: 'Hours',
    Minutes: 'Minutes',
    Seconds: 'Seconds',
};

export type EnvironmentOperationStatus = 'Succeeded' | 'Failed' | 'Canceled' | 'Running' | 'NotStarted';

export const EnvironmentOperationStatus: UnionMap<EnvironmentOperationStatus> = {
    Succeeded: 'Succeeded',
    Failed: 'Failed',
    Canceled: 'Canceled',
    Running: 'Running',
    NotStarted: 'NotStarted',
};

export interface EnvironmentExpirationDateOffset {
    offset: number;
    unit: EnvironmentExpirationDateOffsetUnit;
}

export interface EnvironmentViewModel {
    key: string;
    resource: Environment;
    state: EnvironmentProvisioningState;
    ownerDisplayName: string | undefined;
    ownerIsReady: boolean;
    environmentDefinition: EnvironmentDefinitionViewModel | undefined;
    isCardContentReady: boolean;
    projectName: string;
    failureOnEnvironment?: FailureOnEnvironment;
    devCenterName: string;
    expirationDateOffset?: EnvironmentExpirationDateOffset;
    projectDisplayName?: string;
    environmentOperations: EnvironmentOperationViewModel[];
}

export interface EnvironmentDefinitionViewModel {
    description: string | undefined;
    parameters: EnvironmentDefinitionParameterViewModel[];
}

export interface EnvironmentOperationViewModel {
    kind: string;
    operationUri: string;
    operationId: string;
    status: string;
    createdByObjectId?: string;
    startTime?: Date;
    endTime?: Date;
    logBlobUri?: string;
}
