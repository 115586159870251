import {
    getScreenSelector,
    ScreenWidthMinLarge,
    ScreenWidthMinMedium,
    ScreenWidthMinSmall,
    ScreenWidthMinXLarge,
    ScreenWidthMinXXLarge,
    ScreenWidthMinXXXLarge,
} from '@fluentui/react';
import { UnionMap, UnionValueMap } from '../../types/union-map';

export type ScreenSize = 'Large' | 'Medium' | 'Small' | 'XLarge' | 'XSmall' | 'XXLarge';
export type ScreenSizeAboveExclusive = `Above${ScreenSize}`;
export type ScreenSizeAboveInclusive = `${Exclude<ScreenSize, 'XSmall'>}AndAbove`;
export type ScreenSizeBelowExclusive = `Below${Exclude<ScreenSize, 'XSmall'>}`;
export type ScreenSizeBelowInclusive = `${Exclude<ScreenSize, 'XXLarge'>}AndBelow`;
export type ScreenSizeBetween =
    | `Between${Extract<ScreenSize, 'Small'>}And${Exclude<ScreenSize, 'Small' | 'XSmall' | 'XXLarge'>}`
    | `Between${Extract<ScreenSize, 'Medium'>}And${Exclude<ScreenSize, 'Small' | 'Medium' | 'XSmall' | 'XXLarge'>}`
    | `Between${Extract<ScreenSize, 'Large'>}And${Exclude<
          ScreenSize,
          'Large' | 'Small' | 'Medium' | 'XSmall' | 'XXLarge'
      >}`
    | `Between${Extract<ScreenSize, 'XLarge'>}And${Exclude<
          ScreenSize,
          'Large' | 'Small' | 'Medium' | 'XLarge' | 'XSmall' | 'XXLarge'
      >}`;

export const ScreenSize: UnionMap<ScreenSize> = {
    Large: 'Large',
    Medium: 'Medium',
    Small: 'Small',
    XLarge: 'XLarge',
    XSmall: 'XSmall',
    XXLarge: 'XXLarge',
};

export const MaxWidth: UnionValueMap<ScreenSize, number> = {
    Large: ScreenWidthMinXLarge,
    Medium: ScreenWidthMinLarge,
    Small: ScreenWidthMinMedium,
    XSmall: ScreenWidthMinSmall,
    XLarge: ScreenWidthMinXXLarge,
    XXLarge: ScreenWidthMinXXXLarge,
};

export const MinWidth: UnionValueMap<Exclude<ScreenSize, 'XSmall'>, number> = {
    Large: ScreenWidthMinLarge,
    Medium: ScreenWidthMinMedium,
    Small: ScreenWidthMinSmall,
    XLarge: ScreenWidthMinXLarge,
    XXLarge: ScreenWidthMinXXLarge,
};

export const ScreenSizeMediaQueries: UnionValueMap<
    | ScreenSize
    | ScreenSizeAboveExclusive
    | ScreenSizeAboveInclusive
    | ScreenSizeBelowExclusive
    | ScreenSizeBelowInclusive
    | ScreenSizeBetween,
    string
> = {
    // Exact screen sizes
    Large: getScreenSelector(MinWidth.Large, MaxWidth.Large),
    Medium: getScreenSelector(MinWidth.Medium, MaxWidth.Medium),
    Small: getScreenSelector(MinWidth.Small, MaxWidth.Small),
    XLarge: getScreenSelector(MinWidth.XLarge, MaxWidth.XLarge),
    XSmall: getScreenSelector(undefined, MaxWidth.XSmall),
    XXLarge: getScreenSelector(MinWidth.XXLarge, undefined),

    // Screen sizes above (exclusive)
    AboveLarge: getScreenSelector(MaxWidth.Large, undefined),
    AboveMedium: getScreenSelector(MaxWidth.Medium, undefined),
    AboveSmall: getScreenSelector(MaxWidth.Small, undefined),
    AboveXLarge: getScreenSelector(MaxWidth.XLarge, undefined),
    AboveXSmall: getScreenSelector(MaxWidth.XSmall, undefined),
    AboveXXLarge: getScreenSelector(MaxWidth.XXLarge, undefined),

    // Screen sizes below (exclusive)
    BelowLarge: getScreenSelector(undefined, MinWidth.Large),
    BelowMedium: getScreenSelector(undefined, MinWidth.Medium),
    BelowSmall: getScreenSelector(undefined, MinWidth.Small),
    BelowXLarge: getScreenSelector(undefined, MinWidth.XLarge),
    BelowXXLarge: getScreenSelector(undefined, MinWidth.XXLarge),

    // Screen sizes above (inclusive)
    LargeAndAbove: getScreenSelector(MinWidth.Large, undefined),
    MediumAndAbove: getScreenSelector(MinWidth.Medium, undefined),
    SmallAndAbove: getScreenSelector(MinWidth.Small, undefined),
    XLargeAndAbove: getScreenSelector(MinWidth.XLarge, undefined),
    XXLargeAndAbove: getScreenSelector(MinWidth.XXLarge, undefined),

    // Screen sizes below (inclusive)
    LargeAndBelow: getScreenSelector(undefined, MaxWidth.Large),
    MediumAndBelow: getScreenSelector(undefined, MaxWidth.Medium),
    SmallAndBelow: getScreenSelector(undefined, MaxWidth.Small),
    XLargeAndBelow: getScreenSelector(undefined, MaxWidth.XLarge),
    XSmallAndBelow: getScreenSelector(undefined, MaxWidth.XSmall),

    // Screen sizes between
    BetweenLargeAndXLarge: getScreenSelector(MinWidth.Large, MaxWidth.XLarge),
    BetweenMediumAndLarge: getScreenSelector(MinWidth.Medium, MaxWidth.Large),
    BetweenMediumAndXLarge: getScreenSelector(MinWidth.Medium, MaxWidth.XLarge),
    BetweenSmallAndLarge: getScreenSelector(MinWidth.Small, MaxWidth.Large),
    BetweenSmallAndMedium: getScreenSelector(MinWidth.Small, MaxWidth.Medium),
    BetweenSmallAndXLarge: getScreenSelector(MinWidth.Small, MaxWidth.XLarge),
};
