import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListEnvironmentOperationsResponse } from '../../../data/services/data-plane-api/environment-operation';
import { ClientError, FailureOperation, isAggregatedFailure, isAggregatedSuccess } from '../../../models/common';
import { getEnvironmentDataplaneUrisArray } from '../../../redux/selector/environment-selectors';
import { combineResults, getErrorCodes } from '../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../utilities/failure';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import { DiscoverEnvironmentOperationsForEnvironmentAction } from '../../actions/environment-operation/environment-operation-actions';
import {
    discoverEnvironmentOperationsForEnvironment,
    discoverEnvironmentOperationsForEnvironmentError,
    discoverEnvironmentOperationsForEnvironmentFailed,
    discoverEnvironmentOperationsForEnvironmentSuccess,
    listEnvironmentOperations,
} from '../../actions/environment-operation/environment-operation-action-creators';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';

export function* discoverEnvironmentOperationsForEnvironmentSaga(
    action: DiscoverEnvironmentOperationsForEnvironmentAction
): SagaIterator {
    const startTime = new Date();

    try {
        const ids: string[] = yield select(getEnvironmentDataplaneUrisArray);

        // Retrieve environment operations for all found environments
        const responses: ListEnvironmentOperationsResponse[] = yield all(
            ids.map((id) => putAndAwait(listEnvironmentOperations({ id })))
        );

        const completeResult = combineResults(responses);

        if (isAggregatedFailure(completeResult)) {
            const { failures } = completeResult;
            const failure = aggregateFailureResponses(
                FailureOperation.DiscoverEnvironmentOperationsForEnvironment,
                ...failures
            );

            yield put(discoverEnvironmentOperationsForEnvironmentFailed({ failure }));
        } else {
            yield put(discoverEnvironmentOperationsForEnvironmentSuccess());
        }

        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverEnvironmentOperationsForEnvironment,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(
            err,
            FailureOperation.DiscoverEnvironmentOperationsForEnvironment
        );

        yield put(discoverEnvironmentOperationsForEnvironmentError({ error }));
        yield rejectAction(action, error);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.DiscoverEnvironmentOperationsForEnvironment,
            startTime,
            AsyncOutcome.Failed,
            { errorCodes: [error.code] }
        );
    }
}

export function* discoverEnvironmentOperationsForEnvironmentListenerSaga(): SagaIterator {
    yield takeLeading(discoverEnvironmentOperationsForEnvironment, discoverEnvironmentOperationsForEnvironmentSaga);
}
