import { detect } from 'detect-browser';
import 'js-detect-incognito-private-browsing';
import {
    BrowserName,
    OperatingSystem,
    OperatingSystemFamily,
    PlatformArchitecture,
    PlatformArchitectureFamily,
} from '../constants/browser';
import { ClientError, FailureOperation } from '../models/common';
import { areStringsEquivalent } from './string';
import { trackException } from './telemetry/channel';

/* eslint-disable @typescript-eslint/no-explicit-any */
// Justification: using any on window as js-detect-incognito-private-browsing adds a method to window
const inPrivateDetector = new (window as any).BrowsingModeDetector();
/* eslint-enable @typescript-eslint/no-explicit-any */
let isInPrivate: boolean | undefined = undefined;

export const getBrowserName = (): BrowserName => {
    const browser = detect();

    return browser?.name ? browser.name : 'unknown';
};

export const getSystemArchitecture = (): PlatformArchitecture => {
    const userAgent = navigator.userAgent.toLowerCase();
    // Check for ARM architecture
    if (userAgent.includes('arm') || userAgent.includes('aarch64')) {
        return PlatformArchitectureFamily.Arm64;
    }

    // Check for 32-bit (x86) architecture
    if (userAgent.includes('i686') || userAgent.includes('i386') || userAgent.includes('x86')) {
        return PlatformArchitectureFamily.x86;
    }

    // Check for 64-bit (x64) architecture
    if (userAgent.includes('x64') || userAgent.includes('win64') || userAgent.includes('wow64')) {
        return PlatformArchitectureFamily.x64;
    }

    // If architecture can't be determined, return unknown
    return PlatformArchitectureFamily.Unknown;
};

export const browserName = getBrowserName();

export const getIsInPrivate = async (): Promise<boolean> => {
    if (isInPrivate === undefined) {
        const promise = new Promise<boolean>((resolve, _reject) => {
            inPrivateDetector.do((browsingInIncognitoMode: boolean) => resolve(browsingInIncognitoMode));
        });

        isInPrivate = await promise;
    }

    return isInPrivate;
};

export const getOperatingSystem = (): OperatingSystem => {
    const browser = detect();

    return browser?.os ? browser.os : 'unknown';
};

export const operatingSystem = getOperatingSystem();

export const getOperatingSystemFamily = (): OperatingSystemFamily => {
    const os = getOperatingSystem();

    // Android cases
    if (areStringsEquivalent(os, 'android', true) || areStringsEquivalent(os, 'Android OS', true)) {
        return OperatingSystemFamily.Android;
    }

    // iOS cases
    if (areStringsEquivalent(os, 'iOS', true)) {
        return OperatingSystemFamily.IOS;
    }

    // Mac OS cases
    if (areStringsEquivalent(os, 'Mac OS', true)) {
        return OperatingSystemFamily.MacOS;
    }

    // Windows cases
    if (
        areStringsEquivalent(os, 'win32', true) ||
        areStringsEquivalent(os, 'Windows 3.11', true) ||
        areStringsEquivalent(os, 'Windows 95', true) ||
        areStringsEquivalent(os, 'Windows 98', true) ||
        areStringsEquivalent(os, 'Windows 2000', true) ||
        areStringsEquivalent(os, 'Windows XP', true) ||
        areStringsEquivalent(os, 'Windows Server 2003', true) ||
        areStringsEquivalent(os, 'Windows Vista', true) ||
        areStringsEquivalent(os, 'Windows 7', true) ||
        areStringsEquivalent(os, 'Windows 8', true) ||
        areStringsEquivalent(os, 'Windows 8.1', true) ||
        areStringsEquivalent(os, 'Windows 10', true) ||
        areStringsEquivalent(os, 'Windows ME', true) ||
        areStringsEquivalent(os, 'Windows CE', true) ||
        areStringsEquivalent(os, 'OS/2', true)
    ) {
        return OperatingSystemFamily.Windows;
    }

    return OperatingSystemFamily.Unknown;
};

export const operatingSystemFamily = getOperatingSystemFamily();

// Note: this only detects whether we're in an iframe. We may need to add further helpers in the future to identify when
// the iframe is cross-origin vs. not, for example.
export const isInIframe = (): boolean => {
    try {
        // See: https://developer.mozilla.org/en-US/docs/Web/API/Window/frameElement
        // frameElement is null if not in an iframe
        return window.frameElement !== null;
    } catch (err) {
        // A SecurityException is thrown in some browsers when accessing frameElement
        // across origins. If this happens we can assume we are in an iframe.
        // Added logging as well so we can see when this occurs.
        const error = new ClientError(err, FailureOperation.IsInIframe);
        trackException(error);

        return true;
    }
};

// This function is purposefully aligned to MSAL's isInIframe check:
//      https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/2eabbbd93e11ffd46eea22b0c9df0a9f3b083be0/lib/msal-browser/src/utils/BrowserUtils.ts#L101
// While we believe the above isInIframe check is correct, this is primarily used in the context of detecting whether
// we're in MSAL's redirect flow or not - so we use this function to precisely check that condition.
export const willFailMsalRedirectPreflightCheck = (): boolean => {
    try {
        return window.parent !== window;
    } catch (err) {
        // A just-in-case catch!
        const error = new ClientError(err, FailureOperation.WillFailMsalRedirectPreflightCheck);
        trackException(error);

        return true;
    }
};

export const openInNewWindow = (url: string): void => {
    /* eslint-disable security/detect-non-literal-fs-filename */
    // Justification: false positive
    window.open(url, '_blank', 'noreferrer');
    /* eslint-enable security/detect-non-literal-fs-filename */
};

export const openInSameWindow = (url: string): void => {
    /* eslint-disable security/detect-non-literal-fs-filename */
    // Justification: false positive
    window.open(url, '_self', 'noreferrer');
    /* eslint-enable security/detect-non-literal-fs-filename */
};
