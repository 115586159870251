import { CapitalizedUnionMap, UnionMap, UnionValueMap } from '../types/union-map';

type ApiVersionName =
    | 'Authorization'
    | 'Batch'
    | 'DevCenterControlPlane'
    | 'DevCenterDataPlaneBeta'
    | 'DevCenterDataPlaneStable'
    | 'DevCenterDataPlanePrivatePreview'
    | 'ResourceGraph'
    | 'Subscriptions'
    | 'Tenants'
    | 'AzureDevOps';

export const AuthorizationApiVersion = '2022-04-01';
export const BatchApiVersion = '2020-06-01';
export const DevCenterControlPlaneApiVersion = '2023-04-01';
export const DevCenterDataPlaneBetaApiVersion = '2024-10-01-preview';
export const DevCenterDataPlaneStableApiVersion = '2024-10-01-preview';
export const DevCenterDataPlanePrivatePreview = '2024-10-15-privatepreview';
export const ResourceGraphApiVersion = '2021-03-01';
export const SubscriptionsApiVersion = '2020-01-01';
export const TenantsApiVersion = '2020-01-01';
export const AzureDevOpsApiVersion = '7.0';

export const ApiVersion: UnionValueMap<ApiVersionName, string> = {
    Authorization: AuthorizationApiVersion,
    Batch: BatchApiVersion,
    DevCenterControlPlane: DevCenterControlPlaneApiVersion,
    DevCenterDataPlaneBeta: DevCenterDataPlaneBetaApiVersion,
    DevCenterDataPlaneStable: DevCenterDataPlaneStableApiVersion,
    DevCenterDataPlanePrivatePreview: DevCenterDataPlanePrivatePreview,
    ResourceGraph: ResourceGraphApiVersion,
    Subscriptions: SubscriptionsApiVersion,
    Tenants: TenantsApiVersion,
    AzureDevOps: AzureDevOpsApiVersion,
};

export type AzureErrorCode = 'AccessDenied';

export const AzureErrorCode: UnionMap<AzureErrorCode> = {
    AccessDenied: 'AccessDenied',
};

export const AzureResourceManagerHostname = 'management.azure.com';

type AzureSearchParameterName = 'ApiVersion';

export const ApiVersionSearchParameter = 'api-version';

export const AzureSearchParameter: UnionValueMap<AzureSearchParameterName, string> = {
    ApiVersion: ApiVersionSearchParameter,
};

type ProviderNamespaceName = 'MicrosoftAuthorization' | 'MicrosoftDevCenter';

export const MicrosoftAuthorizationNamespace = 'Microsoft.Authorization';
export const MicrosoftDevCenterNamespace = 'Microsoft.DevCenter';

export const ProviderNamespace: UnionValueMap<ProviderNamespaceName, string> = {
    MicrosoftAuthorization: MicrosoftAuthorizationNamespace,
    MicrosoftDevCenter: MicrosoftDevCenterNamespace,
};

type ResourceTypeName =
    | 'DevBoxes'
    | 'DevCenters'
    | 'Environments'
    | 'Permissions'
    | 'Pools'
    | 'Projects'
    | 'ProjectEnvironmentTypes'
    | 'Users';

export const DevBoxesResourceType = 'devboxes';
export const DevCentersResourceType = 'devcenters';
export const EnvironmentsResourceType = 'environments';
export const PermissionsResourceType = 'permissions';
export const PoolsResourceType = 'pools';
export const ProjectEnvironmentTypesResourceType = 'environmenttypes';
export const ProjectsResourceType = 'projects';
export const UsersResourceType = 'users';

export const ResourceType: UnionValueMap<ResourceTypeName, string> = {
    DevBoxes: DevBoxesResourceType,
    DevCenters: DevCentersResourceType,
    Environments: EnvironmentsResourceType,
    Permissions: PermissionsResourceType,
    Pools: PoolsResourceType,
    ProjectEnvironmentTypes: ProjectEnvironmentTypesResourceType,
    Projects: ProjectsResourceType,
    Users: UsersResourceType,
};

export const FullResourceType: UnionValueMap<ResourceTypeName, string> = {
    DevBoxes: `${ProviderNamespace.MicrosoftDevCenter}/${ResourceType.Projects}/${ResourceType.Users}/${ResourceType.DevBoxes}`,
    DevCenters: `${ProviderNamespace.MicrosoftDevCenter}/${ResourceType.DevCenters}`,
    Environments: `${ProviderNamespace.MicrosoftDevCenter}/${ResourceType.Projects}/${ResourceType.Users}/${ResourceType.Environments}`,
    Permissions: `${ProviderNamespace.MicrosoftAuthorization}/${ResourceType.Permissions}`,
    Pools: `${ProviderNamespace.MicrosoftDevCenter}/${ResourceType.Projects}/${ResourceType.Pools}`,
    ProjectEnvironmentTypes: `${ProviderNamespace.MicrosoftDevCenter}/${ResourceType.Projects}/${ResourceType.ProjectEnvironmentTypes}`,
    Projects: `${ProviderNamespace.MicrosoftDevCenter}/${ResourceType.Projects}`,
    Users: `${ProviderNamespace.MicrosoftDevCenter}/${ResourceType.Projects}/${ResourceType.Users}`,
};

type DevBoxActionsNames =
    | 'UserWrite'
    | 'UserRead'
    | 'UserDelete'
    | 'UserStart'
    | 'UserStop'
    | 'UserGetRemoteConnection'
    | 'UserCustomize';

export type DevBoxActions =
    | 'userWrite'
    | 'userRead'
    | 'userDelete'
    | 'userStart'
    | 'userStop'
    | 'userGetRdpFileContent'
    | 'userCustomize';

export const DevBoxActions: UnionValueMap<DevBoxActionsNames, DevBoxActions> = {
    UserWrite: 'userWrite',
    UserRead: 'userRead',
    UserDelete: 'userDelete',
    UserStart: 'userStart',
    UserStop: 'userStop',
    UserGetRemoteConnection: 'userGetRdpFileContent',
    UserCustomize: 'userCustomize',
};

export type EnvironmentAction =
    | 'adminAction'
    | 'adminDelete'
    | 'adminRead'
    | 'adminWrite'
    | 'userAction'
    | 'userDelete'
    | 'userRead'
    | 'userWrite';

export const EnvironmentAction: CapitalizedUnionMap<EnvironmentAction> = {
    AdminAction: 'adminAction',
    AdminDelete: 'adminDelete',
    AdminRead: 'adminRead',
    AdminWrite: 'adminWrite',
    UserAction: 'userAction',
    UserDelete: 'userDelete',
    UserRead: 'userRead',
    UserWrite: 'userWrite',
};
