import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { EventName, PerformanceMetric } from '../../../constants/telemetry';
import { GetDevBoxResponse, RestartDevBoxResponse, restartDevBox } from '../../../data/services/data-plane-api/dev-box';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { getDevBoxState } from '../../../utilities/dev-box';
import { createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault } from '../../../utilities/failure';
import { trackEvent, trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import { createOptionsForDataPlaneResourceMetric } from '../../../utilities/telemetry/helpers';
import {
    pollNonTerminalDevBox,
    restartDevBoxAccepted,
    restartDevBox as restartDevBoxActionCreator,
    restartDevBoxError,
    restartDevBoxFailed,
    restartDevBoxSuccess,
} from '../../actions/dev-box/dev-box-action-creators';
import { RestartDevBoxAcceptedAction, RestartDevBoxAction } from '../../actions/dev-box/dev-box-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery, takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';
import { DevBoxState } from '../../store/dev-box-state';

export function* restartDevBoxSaga(action: RestartDevBoxAction): SagaIterator {
    const startTime = new Date();
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: RestartDevBoxResponse = yield call(restartDevBox, id, accessToken, activityId);

        if (isFailureResponse(response)) {
            yield put(restartDevBoxFailed({ failure: response, id }, meta));

            yield call(
                trackTimedPerformanceMetric,
                PerformanceMetric.RestartDevBox,
                startTime,
                AsyncOutcome.Failed,
                createOptionsForDataPlaneResourceMetric(id, activityId, response.code)
            );

            return;
        }

        yield put(restartDevBoxAccepted({ id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.RestartDevBox,
            startTime,
            AsyncOutcome.Success,
            createOptionsForDataPlaneResourceMetric(id, activityId)
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.RestartDevBox);
        yield put(restartDevBoxError({ error, id }, meta));

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.RestartDevBox,
            startTime,
            AsyncOutcome.Error,
            createOptionsForDataPlaneResourceMetric(id, activityId, error.code)
        );
    }
}

export function* restartDevBoxAcceptedSaga(action: RestartDevBoxAcceptedAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const response: GetDevBoxResponse = yield putAndAwait(pollNonTerminalDevBox({ id }, meta));

        if (isFailureResponse(response)) {
            yield put(restartDevBoxFailed({ failure: response, id }, meta));
            return;
        }

        const { data } = response;
        const state = getDevBoxState(data);
        switch (state) {
            case DevBoxState.Running:
                yield put(restartDevBoxSuccess({ id, result: data }, meta));
                break;
            default:
                // Unexpected state: log cases where we're falling back on the default error message. This means we're
                // in a failed state, but there are no error details.
                if (!data.error) {
                    trackEvent(EventName.DevBoxInFailedStateWithNoError, {
                        activityId,
                        properties: {
                            actionType: action.type,
                            id,
                            provisioningState: data.provisioningState,
                            devBoxState: state,
                        },
                    });
                }

                const failure = createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault(
                    data.error,
                    FailureOperation.RestartDevBox
                );

                yield put(restartDevBoxFailed({ failure, id, result: data }, meta));
                break;
        }
    } catch (err) {
        const error = new ClientError(err, FailureOperation.RestartDevBox);
        yield put(restartDevBoxError({ error, id }, meta));
    }
}

export function* restartDevBoxListenerSaga(): SagaIterator {
    yield takeLeading(restartDevBoxActionCreator, restartDevBoxSaga);
}

export function* restartDevBoxAcceptedListenerSaga(): SagaIterator {
    yield takeEvery(restartDevBoxAccepted, restartDevBoxAcceptedSaga);
}
