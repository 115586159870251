import { EnvironmentFailureReason } from '../constants/environment';
import { OperationStateContract } from '../data/contracts/common';
import {
    EnviromentUpdateContract,
    EnvironmentContract,
    EnvironmentProvisioningStateContract,
    PutEnvironmentBodyContract,
} from '../data/contracts/environment';
import {
    EnvironmentOperationContract,
    EnvironmentOperationKindContract,
} from '../data/contracts/environment-operation';
import { Failure } from '../models/common';
import { UnionMap } from '../types/union-map';

/**
 * Application models
 */

export type Environment = EnvironmentContract;

export type PutEnvironmentBody = PutEnvironmentBodyContract;

export interface FailureOnEnvironment {
    failure: Failure;
    reason: EnvironmentFailureReason;
}

export type EnvironmentUpdateProperties = EnviromentUpdateContract;

export type EnvironmentOperation = EnvironmentOperationContract;

export type EnvironmentOperationKind = EnvironmentOperationKindContract;

export const EnvironmentOperationKind: UnionMap<EnvironmentOperationKind> = {
    ...EnvironmentOperationKindContract,
};

export type EnvironmentOperationStatus = OperationStateContract;

export const EnvironmentOperationStatus: UnionMap<EnvironmentOperationStatus> = {
    ...OperationStateContract,
};

export type EnvironmentResourceProvisioningState = EnvironmentProvisioningStateContract;

export const EnvironmentResourceProvisioningState: UnionMap<EnvironmentResourceProvisioningState> = {
    ...EnvironmentProvisioningStateContract,
};

export type EnvironmentOperationLogBlob = {
    operationId: string;
    logBlob: Blob;
};

export type EnvironmentOperationLogUri = {
    operationId: string;
    logBlobUri: string;
};
