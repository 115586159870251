import { SagaIterator } from '@redux-saga/types';
import { call, put } from 'redux-saga/effects';
import {
    GetEnvironmentOperationLogResponse,
    getEnvironmentOperationLog,
} from '../../../data/services/data-plane-api/environment-operation';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import { EnvironmentOperationLogUri } from '../../../models/environment';
import { createObjectUrlForBlob } from '../../../utilities/data';
import {
    getEnvironmentOperationLog as getEnvironmentOperationLogActionCreator,
    getEnvironmentOperationLogError,
    getEnvironmentOperationLogFailed,
    getEnvironmentOperationLogSuccess,
} from '../../actions/environment-operation/environment-operation-action-creators';
import { GetEnvironmentOperationLogAction } from '../../actions/environment-operation/environment-operation-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* getEnvironmentOperationLogSaga(action: GetEnvironmentOperationLogAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: GetEnvironmentOperationLogResponse = yield call(
            getEnvironmentOperationLog,
            id,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(getEnvironmentOperationLogFailed({ failure: response, id }, meta));
            yield resolveAction(action, response);
            return;
        }

        const logUrl = yield call(createObjectUrlForBlob, response.data.logBlob);

        const result: EnvironmentOperationLogUri = {
            operationId: response.data.operationId,
            logBlobUri: logUrl,
        };

        yield put(getEnvironmentOperationLogSuccess({ id, result }, meta));
        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.GetEnvironmentOperationLog);
        yield put(getEnvironmentOperationLogError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* getEnvironmentOperationLogListenerSaga(): SagaIterator {
    yield takeEvery(getEnvironmentOperationLogActionCreator, getEnvironmentOperationLogSaga);
}
