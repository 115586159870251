import {
    AccountInfo,
    AuthError,
    AuthenticationResult,
    Configuration,
    EndSessionRequest,
    IPublicClientApplication,
    LogLevel,
    PublicClientApplication,
    RedirectRequest,
    SilentRequest,
    SsoSilentRequest,
} from '@azure/msal-browser';
import { SearchParameter } from '../../constants/app';
import { IdentityErrorCode } from '../../constants/identity';
import { Metric, Property, Severity } from '../../constants/telemetry';
import { ClientError, DataResponse, FailureOperation, FailureResponse } from '../../models/common';
import Settings from '../../settings/settings';
import { browserName } from '../../utilities/browser';
import { getSingleDevCenterTenantIdFromCurrentLocation } from '../../utilities/single-dev-center';
import { areStringsEquivalent, isNotUndefinedOrWhiteSpace } from '../../utilities/string';
import { trackMetric, trackTrace } from '../../utilities/telemetry/channel';
import { DefaultRetryOptions, RetryOptions, requestAndRetry } from './request-and-retry';
import { getPreferredStorageTypeForBrowser } from './storage';

interface GetAccessTokenNotRedirectingData {
    accessToken: string;
    isRedirecting: false;
    scopes: string[];
}

interface GetAccessTokenRedirectingData {
    isRedirecting: true;
}

export type GetAccessTokenData = GetAccessTokenNotRedirectingData | GetAccessTokenRedirectingData;
export type GetAccessTokenResponse = DataResponse<GetAccessTokenData>;
export type TrySilentSingleSignOnResponse = DataResponse;

const defaultLoginRequest: RedirectRequest = {
    scopes: Settings.SignInScopes,
};

const defaultSsoRequest: SsoSilentRequest = {
    scopes: Settings.SignInScopes,
};

// Module's private state
let application: IPublicClientApplication | undefined = undefined;
let authenticationError: AuthError | undefined = undefined;
let isInitialized = false;

const attemptAcquireTokenSilent = async (request: SilentRequest): Promise<AuthenticationResult> => {
    if (!isInitialized) {
        throw new ClientError('Identity module must be initialized before use.');
    }

    if (application === undefined) {
        throw new ClientError('Unexpected state: Identity module is initialized, but application is not defined.');
    }

    return await application.acquireTokenSilent(request);
};

const canRedirectFromErrorCode = (errorCode: string) => {
    // The following error codes in the getAccessToken workflow indicate that redirection should be performed
    // (monitor_window_timeout: when this request fails after retrying, redirecting should unblock the user,
    //    because MSAL does not use a hidden iframe (which is what causes this error code) in the redirect flow)
    //    Documentation: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#monitor_window_timeout
    return (
        errorCode === IdentityErrorCode.InteractionRequired ||
        errorCode === IdentityErrorCode.InvalidGrant ||
        errorCode === IdentityErrorCode.LoginRequired ||
        errorCode === IdentityErrorCode.TokenRenewalError ||
        errorCode === IdentityErrorCode.NoTokensFound ||
        errorCode === IdentityErrorCode.MonitorWindowTimeout
    );
};

const authLoggerCallback = (level: LogLevel, message: string, containsPii: boolean): void => {
    if (containsPii) {
        return;
    }

    switch (level) {
        case LogLevel.Error:
        case LogLevel.Warning:
            trackTrace(`MSAL module log ${level}: ${message}`, { severity: Severity.Warning });
            return;
    }
};

const onRetryAcquireTokenSilent = (err: unknown, tries: number, retries: number, waitTimeInMs: number) => {
    trackTrace(
        `Retrying failed acquireTokenSilent request, tries: ${tries}, retries: ${retries}, wait time (ms): ${waitTimeInMs}`
    );

    // If there was any error associated with last try, log what that was (but not at normal error priority)
    if (err instanceof Error) {
        trackTrace(`Retrying after error thrown. Message from error: ${err.message}`);
    }
};

const retryAcquireTokenSilentOnExceptionPredicate = (err: unknown) => {
    // Don't retry if not an AuthError from MSAL
    if (!(err instanceof AuthError)) {
        return false;
    }

    const { errorCode } = err;

    switch (errorCode) {
        // post_request_failed: could be a transient transport layer issue, worth retrying
        // 50033: indicates AAD backend issue that SHOULD be transient. These are supposedly rare.
        //      More info:
        //          - https://learn.microsoft.com/en-us/azure/active-directory/develop/reference-error-codes#aadsts-error-codes
        //          - https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4019
        // monitor_window_timeout: happens when a redirect from a hidden iframe fails
        //      (MSAL uses this iframe to request a new refresh token when the token call fails)
        //      For the majority of users (from telemetry), this does not happen again on subsequent token requests, so retrying should succeed
        case IdentityErrorCode.PostRequestFailed:
        case IdentityErrorCode.RetryableError:
        case IdentityErrorCode.MonitorWindowTimeout:
            return true;

        // All others: don't retry
        default:
            return false;
    }
};

const getRequestedTenantIdFromCurrentLocation = (): string | undefined => {
    const tenantIdSearchParameter = getTenantIdSearchParameter();
    const singleDevCenterTenantId = getSingleDevCenterTenantIdFromCurrentLocation();

    return singleDevCenterTenantId ?? tenantIdSearchParameter;
};

const getTenantIdForSignIn = (): string | undefined => {
    const requestedTenantId = getRequestedTenantIdFromCurrentLocation();
    const activeAccount = getActiveAccount();

    return requestedTenantId ?? activeAccount?.tenantId;
};

const isSignedInTenant = (tenantId: string): boolean => {
    const activeAccount = getActiveAccount();
    const signedInTenantId = activeAccount?.tenantId;

    return areStringsEquivalent(tenantId, signedInTenantId, true);
};

export const attemptSilentSingleSignOn = async (): Promise<TrySilentSingleSignOnResponse> => {
    if (!isInitialized) {
        throw new ClientError('Identity module must be initialized before use.');
    }

    if (application === undefined) {
        throw new ClientError('Unexpected state: Identity module is initialized, but application is not defined.');
    }

    try {
        // If we've already got an account, attempt SSO with it (improves probability of success w/o redirect)
        const account = getActiveAccount();

        // Although we already include the account, MSAL SSO is more reliable with the loginHint.
        // More information on login hint: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#account-apis
        const loginHint = account?.username;

        const tenantId = getTenantIdForSignIn();
        const authority = getAuthority(tenantId);

        const request = { ...defaultSsoRequest, authority, account, loginHint };
        const result = await application.ssoSilent(request);

        // Ensure active account is set after SSO completion
        application.setActiveAccount(result.account);

        return { succeeded: true };
    } catch (error) {
        // AuthErrors can be handled and sent back as a normal return value
        if (error instanceof AuthError) {
            const { errorCode, errorMessage } = error;
            return FailureResponse({
                code: errorCode,
                message: errorMessage,
                operation: FailureOperation.AttemptSilentSingleSignOn,
            });
        }

        // Rethrow everything else
        throw new ClientError(error);
    }
};

export const didAuthenticationFail = (): boolean =>
    authenticationError !== undefined && authenticationError.errorCode !== IdentityErrorCode.LoginRequired;

export const getAccessToken = async (
    scopes: string[],
    onlyUseSilentRequests = false,
    retryOptions?: RetryOptions<AuthenticationResult>
): Promise<GetAccessTokenResponse> => {
    if (!isInitialized) {
        throw new ClientError('Identity module must be initialized before use.');
    }

    if (application === undefined) {
        throw new ClientError('Unexpected state: Identity module is initialized, but application is not defined.');
    }

    retryOptions = retryOptions ?? DefaultRetryOptions();
    const account = getActiveAccount();

    // Although we already include the account, MSAL SSO is more reliable with the loginHint.
    // More information on login hint: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#account-apis
    const loginHint = account?.username;
    const authority = getAuthority(account?.tenantId);

    const request = { account, scopes, authority, loginHint };

    try {
        const response = await requestAndRetry(
            {
                ...retryOptions,
                onRetry: onRetryAcquireTokenSilent,
                retryOnExceptionPredicate: retryAcquireTokenSilentOnExceptionPredicate,
            },
            () => attemptAcquireTokenSilent(request)
        );

        trackMetric(Metric.GetAccessToken, 1, {
            properties: {
                [Property.Outcome]: 'Success',
                [Property.IsRedirecting]: false,
            },
        });

        return {
            data: {
                accessToken: response.accessToken,
                isRedirecting: false,
                scopes: response.scopes,
            },
            succeeded: true,
        };
    } catch (error) {
        if (!(error instanceof AuthError)) {
            // All non-AuthError errors are logged and re-thrown immediately
            throw new ClientError(error);
        } else if (
            error instanceof AuthError &&
            (onlyUseSilentRequests || !canRedirectFromErrorCode(error.errorCode))
        ) {
            // All AuthErrors that can't be fixed with redirection have their errorCodes returned
            const { errorCode, errorMessage } = error;
            trackTrace(`AuthError cannot be fixed with redirection: ${errorCode}: ${errorMessage}`, {
                severity: Severity.Warning,
            });
            trackMetric(Metric.GetAccessToken, 1, {
                properties: {
                    [Property.Outcome]: 'Failure',
                    [Property.ErrorCode]: errorCode,
                },
            });

            return FailureResponse({
                code: errorCode,
                message: errorMessage,
                operation: FailureOperation.GetAccessToken,
            });
        }

        // Attempt redirection
        const authError = error as AuthError;

        try {
            trackTrace(`Could not fetch token silently (code: ${authError.errorCode}); redirecting`);
            await application.acquireTokenRedirect(request);

            trackMetric(Metric.GetAccessToken, 1, {
                properties: {
                    [Property.Outcome]: 'Success',
                    [Property.IsRedirecting]: true,
                },
            });

            return {
                data: {
                    isRedirecting: true,
                },
                succeeded: true,
            };
        } catch (interactiveError) {
            // If this is another auth error, return its info
            if (interactiveError instanceof AuthError) {
                const { errorCode, errorMessage } = interactiveError;
                trackTrace(`Interactive error while attempting to redirect: ${errorCode}: ${errorMessage}`, {
                    severity: Severity.Warning,
                });
                trackMetric(Metric.GetAccessToken, 1, {
                    properties: {
                        [Property.Outcome]: 'Failure',
                        [Property.ErrorCode]: errorCode,
                    },
                });

                return FailureResponse({
                    code: errorCode,
                    message: errorMessage,
                    operation: FailureOperation.GetAccessToken,
                });
            }

            // Otherwise, throw
            throw new ClientError(interactiveError);
        }
    }
};

export const getActiveAccount = (): AccountInfo | undefined => application?.getActiveAccount() ?? undefined;

export const getAuthenticationErrorCode = (): string | undefined => authenticationError?.errorCode;

export const getAuthenticationErrorMessage = (): string | undefined => authenticationError?.errorMessage;

export const getAuthority = (tenantId?: string): string | undefined =>
    isNotUndefinedOrWhiteSpace(tenantId) ? `${Settings.AuthorityBaseUrl}/${encodeURIComponent(tenantId)}` : undefined;

export const getTenantIdSearchParameter = (): string | undefined => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(SearchParameter.SignInTenantId)?.trim() ?? undefined;
};

export const initialize = async (configuration?: Configuration): Promise<void> => {
    if (isInitialized) {
        return;
    }

    // If no config is given, use the default one
    if (!configuration) {
        const cacheLocation = await getPreferredStorageTypeForBrowser();

        configuration = {
            auth: {
                authority: `${Settings.AuthorityBaseUrl}/${Settings.UnknownTenant}`,
                clientId: Settings.ClientId,
                navigateToLoginRequestUrl: true,
                postLogoutRedirectUri: `https://${window.location.host}${Settings.SignOutRedirectPath}`,
                redirectUri: `https://${window.location.host}${Settings.SignInRedirectPath}`,
            },
            cache: {
                cacheLocation,
                // In most browsers, we don't want to use cookies for auth state. However, Edge (non-Chromium) needs to
                // leverage cookies for auth state due to security zone issues, else it could end up in auth redirects.
                // More info: https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-known-issues-ie-edge-browsers#issues-due-to-security-zones
                storeAuthStateInCookie: browserName === 'edge' ? true : false,
            },
            system: {
                loggerOptions: {
                    loggerCallback: authLoggerCallback,
                    piiLoggingEnabled: false,
                },
            },
        };
    }

    application = new PublicClientApplication(configuration);

    try {
        // Configure post-redirect behavior
        const response = await application.handleRedirectPromise();

        if (response !== null) {
            // response is non-null -> just returned from redirect; set active account
            application.setActiveAccount(response.account);
        } else if (!getActiveAccount()) {
            // response is null, active account not set: set it to the first account we have in the account list
            const accounts = application.getAllAccounts();

            if (accounts.length > 1) {
                trackTrace(
                    `Expected 1 account to be authenticated, but ${accounts.length} are authenticated. Account at index 0 will be set to the active account.`,
                    { severity: Severity.Warning }
                );
            }

            application.setActiveAccount(accounts[0]);
        }

        isInitialized = true;
    } catch (error) {
        // Handle AuthError instances
        if (error instanceof AuthError) {
            trackTrace(`${error.errorCode}: ${error.errorMessage}`, { severity: Severity.Error });
            authenticationError = error;

            // If error is recoverable, no need to rethrow
            if (error.errorCode === IdentityErrorCode.LoginRequired) {
                return;
            }
        }

        // Rethrow any other errors we don't know how to handle
        throw new ClientError(error);
    }
};

export const isAuthenticated = (): boolean => {
    if (!application) {
        return false;
    }

    // If the last authentication failure was LoginRequested, return false
    if (authenticationError !== undefined && authenticationError.errorCode === IdentityErrorCode.LoginRequired) {
        return false;
    }

    // Otherwise, we're considered authenticated if the user has signed-in accounts and one account is active
    const accounts = application.getAllAccounts();
    const activeAccount = application.getActiveAccount();
    const requestedSignInTenantId = getRequestedTenantIdFromCurrentLocation();
    const isSignedIntoRequestedTenant = requestedSignInTenantId ? isSignedInTenant(requestedSignInTenantId) : true;

    return accounts.length > 0 && activeAccount !== null && isSignedIntoRequestedTenant;
};

export const signIn = async (options: Partial<RedirectRequest> = {}): Promise<void> => {
    if (!isInitialized) {
        throw new ClientError('Identity module must be initialized before use.');
    }

    if (application === undefined) {
        throw new ClientError('Unexpected state: Identity module is initialized, but application is not defined.');
    }

    try {
        const account = getActiveAccount();
        const tenantId = getTenantIdForSignIn();
        const authority = getAuthority(tenantId);
        // Note: account is for login hints. Scenario: user is trying to sign into another tenant w/ same account
        const loginRequest: RedirectRequest = { ...defaultLoginRequest, account, authority, ...options };

        await application.loginRedirect(loginRequest);
    } catch (error) {
        throw new ClientError(error);
    }
};

export const signOut = async (skipServerSignOut = false, options?: Partial<EndSessionRequest>): Promise<void> => {
    if (!isInitialized) {
        throw new ClientError('Identity module must be initialized before use.');
    }

    if (application === undefined) {
        throw new ClientError('Unexpected state: Identity module is initialized, but application is not defined.');
    }

    try {
        const account = getActiveAccount();
        const authority = getAuthority(account?.tenantId);
        const logoutRequest: EndSessionRequest = {
            authority,
            account,
            // Having onRedirectNavigate return false will only sign out the user locally
            ...(skipServerSignOut ? { onRedirectNavigate: (_url) => false } : {}),
            ...(options || {}),
        };

        await application.logoutRedirect(logoutRequest);
    } catch (error) {
        throw new ClientError(error);
    }
};
