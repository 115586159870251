import { initializeIcons } from '@fluentui/react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import ErrorBoundary from './components/error-boundary';
import { Property } from './constants/telemetry';
import { initialize as initializeAuthenticationModule } from './data/services/identity';
import { initializeExtendedIcons } from './icons/initialize-extended-icons';
import './index.css';
import { getLocalizationConfiguration } from './language/languages';
import { ClientError, FailureOperation } from './models/common';
import { initialize as initializeStore } from './redux/store';
import { TelemetryStore } from './redux/store/telemetry-store';
import { isInIframe, willFailMsalRedirectPreflightCheck } from './utilities/browser';
import { doAsync } from './utilities/do-async';
import { initialize as initializeTelemetryChannel, trackException, trackTrace } from './utilities/telemetry/channel';

const init = async (): Promise<void> => {
    // First batch of work: initialize application store, localization, and telemetry.
    // Happens concurrently because telemetry is dependent on the store for global properties.
    const [resultFromLocalizationAndStore, resultFromTelemetry] = await Promise.allSettled([
        doAsync(() => {
            // Initialize localization module and get configuration details for telemetry
            const { isUsingFallbackLocalization, language, locale, market, messages } = getLocalizationConfiguration();

            // Bootstrap the application store with properties that are known immediately.
            const store = initializeStore({
                // Telemetry: populate some global properties so all telemetry in application lifetime carries them
                telemetryStore: TelemetryStore({
                    globalProperties: {
                        [Property.IsInIframe]: isInIframe(),
                        [Property.IsUsingFallbackLocalization]: isUsingFallbackLocalization,
                        [Property.Language]: language,
                        [Property.Locale]: locale,
                        [Property.Market]: market,
                        [Property.PortalHostname]: window.location.hostname,
                        [Property.PortalPathname]: window.location.pathname,
                        [Property.PortalSearch]: window.location.search,
                    },
                }),
            });

            return { locale, messages, store };
        }),
        doAsync(initializeTelemetryChannel),
    ]);

    // If initializing localization or the store failed, rethrow the error that occurred
    if (resultFromLocalizationAndStore.status === 'rejected') {
        const { reason } = resultFromLocalizationAndStore;
        throw reason;
    }

    // If initializing telemetry failed, log what happened, but try to keep going
    if (resultFromTelemetry.status === 'rejected') {
        const { reason } = resultFromTelemetry;
        console.warn(`Telemetry failed to initialize and will not be available`, reason);
    }

    const { value } = resultFromLocalizationAndStore;
    const { locale, messages, store } = value;

    // Context: when redirecting from sign-in and sign-out, MSAL will load the application in a hidden iframe to wrap up
    // the relevant auth flow. Most of the time, users won't observe this. However, the app WILL behave as it normally
    // would, including sending telemetry and trying to execute fetch requests, which inevitably result in errors. This
    // creates a confusing experience for our on-call when trying to make sense of the aggregate user-impacting failure
    // rates.
    // To deal with this, we evaluate in advance whether the app will fail MSAL's redirection-blocking preflight checks.
    // If they will, we execute a much simpler page load - finish loading the auth module and render nothing - to avoid
    // errors being logged. This preflight check mostly comes down to whether the app is in an iframe or not.
    const preflightCheckWillFail = willFailMsalRedirectPreflightCheck();

    if (preflightCheckWillFail) {
        trackTrace('Detected load within iframe; initialize storage, auth, and exit.');
        await initializeAuthenticationModule();
        return;
    }

    // Second batch of work: initialize icons and extended icons.
    try {
        await Promise.all([doAsync(initializeIcons), doAsync(initializeExtendedIcons)]);
    } catch (e) {
        trackException(new ClientError(e, FailureOperation.InitializeIcons));
    }

    const root = document.getElementById('root');

    const content = (
        <ErrorBoundary outsideOfProviders>
            <BrowserRouter>
                <HelmetProvider>
                    <Provider store={store}>
                        <App locale={locale} messages={messages} />
                    </Provider>
                </HelmetProvider>
            </BrowserRouter>
        </ErrorBoundary>
    );

    if (process.env.NODE_ENV === 'development') {
        const searchParams = new URLSearchParams(window.location.search);
        const enableAccessibilityPlugin = searchParams.get('axePlugin') === 'true';
        if (enableAccessibilityPlugin) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const axe = require('@axe-core/react').default;
                axe(React, ReactDOM, 1000);
            } catch (e) {
                console.warn('Unable to load the @axe-core/react plugin.');
            }

            // can't use the axe plugin with React.StrictMode on
            ReactDOM.render(<>{content}</>, root);
            return;
        }
    }

    ReactDOM.render(<React.StrictMode>{content}</React.StrictMode>, root);
};

init();
