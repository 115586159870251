import { FontSizes, Icon, ITheme, makeStyles as legacyMakeStyles, Shimmer } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useDynamicMakeStyles } from '../../../hooks/styling';
import { useHorizontalStackStyles } from '../../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../../utilities/styles';
import { ShimmeredMetadataItemWidth } from './models';

interface OldMetadataItemProps {
    content: React.ReactNode | (() => React.ReactNode);
    highlight?: boolean;
    iconAriaLabel: string;
    iconName: string;
    marginBottom?: number;
    marginTop?: number;
}

interface MetadataItemProps {
    content: React.ReactNode | (() => React.ReactNode);
    icon: JSX.Element;
}

export interface ShimmeredMetadataItemProps {
    /** If no width is provided, defaults to ShimmeredMetadataItemWidth.Large */
    width?: ShimmeredMetadataItemWidth;
}

const isRenderFunction = (value: React.ReactNode | (() => React.ReactNode)): value is () => React.ReactNode =>
    typeof value === 'function';

/**
 * Styles
 */

const metadataItemContainerStylesFactory = (marginBottom?: number, marginTop?: number, textColor?: boolean) =>
    legacyMakeStyles((theme: ITheme) => ({
        root: {
            height: 16,
            gap: '8px',
            flexShrink: 1,
            ...(marginBottom !== undefined ? { marginBottom } : {}),
            ...(marginTop !== undefined ? { marginTop } : {}),
            ...(textColor ? { color: getSemanticColor(theme, 'metadataItemHighlightedTextColor') } : {}),
            minWidth: 0,
        },
    }));

const useMetadataItemStyles = makeStyles({
    content: {
        fontSize: FontSizes.size12,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
    },
    icon: {
        fontSize: FontSizes.size16,
        height: '16px',
        verticalAlign: 'bottom',
        width: '16px',
    },
});

const useItemStyles = makeStyles({
    root: {
        gap: '8px',
    },
    icon: {
        paddingTop: '3px',
    },
});

const shimmerStylesFactory = (width?: ShimmeredMetadataItemWidth) => {
    switch (width) {
        case ShimmeredMetadataItemWidth.XSmall:
            return useXSmallShimmerStyles;
        case ShimmeredMetadataItemWidth.Small:
            return useSmallShimmerStyles;
        case ShimmeredMetadataItemWidth.Medium:
            return useMediumShimmerStyles;
        default:
            // default to large
            return useLargeShimmerStyles;
    }
};

const useXSmallShimmerStyles = makeStyles({
    root: {
        width: '60px',
    },
});

const useSmallShimmerStyles = makeStyles({
    root: {
        width: '90px',
    },
});

const useMediumShimmerStyles = makeStyles({
    root: {
        width: '140px',
    },
});

const useLargeShimmerStyles = makeStyles({
    root: {
        width: '208px',
    },
});

/**
 * END styles
 */

export const ShimmeredMetadataItem: React.FC<ShimmeredMetadataItemProps> = (props: ShimmeredMetadataItemProps) => {
    const { width } = props;

    const useMetadataItemStyles = useDynamicMakeStyles(metadataItemContainerStylesFactory);
    const metadataItemStyles = useMetadataItemStyles();
    const useShimmerStyles = useDynamicMakeStyles(shimmerStylesFactory, width);
    const shimmerStyles = useShimmerStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    return (
        <div className={mergeClasses(horizontalStackStyles.root, metadataItemStyles.root)}>
            <Shimmer styles={shimmerStyles} />
        </div>
    );
};

export const OldMetadataItem: React.FC<OldMetadataItemProps> = (props: OldMetadataItemProps) => {
    const { content, highlight, iconAriaLabel, iconName, marginBottom, marginTop } = props;

    // Style hooks
    const useMetadataItemContainerStyles = useDynamicMakeStyles(
        metadataItemContainerStylesFactory,
        marginBottom,
        marginTop,
        highlight
    );
    const metadataContainerStyles = useMetadataItemContainerStyles();
    const metadataItemStyles = useMetadataItemStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    return (
        <div className={mergeClasses(horizontalStackStyles.root, metadataContainerStyles.root)}>
            <Icon aria-label={iconAriaLabel} className={metadataItemStyles.icon} iconName={iconName} />
            <span className={metadataItemStyles.content}>{isRenderFunction(content) ? content() : content}</span>
        </div>
    );
};

export const MetadataItem: React.FC<MetadataItemProps> = (props: MetadataItemProps) => {
    const { content, icon } = props;

    // Style hooks
    const horizontalStackStyles = useHorizontalStackStyles();
    const itemStyles = useItemStyles();

    return (
        <div className={mergeClasses(horizontalStackStyles.root, itemStyles.root)}>
            <div className={mergeClasses(horizontalStackStyles.item, itemStyles.icon)}>{icon}</div>
            <div className={horizontalStackStyles.item}>{isRenderFunction(content) ? content() : content}</div>
        </div>
    );
};

export default MetadataItem;
