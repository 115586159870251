import {
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    FluentProvider,
    Label,
    makeStyles,
    mergeClasses,
    tokens,
    typographyStyles,
} from '@fluentui/react-components';
import { Database16Regular, DeveloperBoard16Regular, Dismiss24Regular, Memory16Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDevBoxDetailsPanelContext } from '../../../hooks/context/panels';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { HardwareProfile, HibernateSupport, ImageReference, StorageProfile } from '../../../models/pool';
import { AzureLocation } from '../../../models/resource-manager';
import { getLocations } from '../../../redux/selector/subscription-selectors';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { SerializableMap } from '../../../types/serializable-map';
import { isNotUndefinedOrWhiteSpace, isUndefinedOrWhiteSpace } from '../../../utilities/string';
import MetadataList from '../../common/metadata/metadata-list';
import { MetadataItemViewModel } from '../../common/metadata/models';
import { CPUMetadata, DiskSizeMetadata, MemoryMetadata } from '../dev-box-metadata/dev-box-metadata';

export interface DevBoxDetailsPanelProps {
    isOpen: boolean;
    name: string;
    poolName: string;
    locations: SerializableMap<AzureLocation>;
    createdOn?: Date;
    location?: string;
    imageReference?: ImageReference;
    hardwareProfile?: HardwareProfile;
    storageProfile?: StorageProfile;
    scheduledStopTime?: string;
    onDismiss: () => void;
    hibernateSupport: HibernateSupport;
}

const messages = defineMessages({
    devBoxDetailsPanelCloseButtonLabel: {
        id: 'DevBoxDetails_Panel_Close_Label',
        defaultMessage: 'Close dev box details panel',
        description: 'Aria label for the dev box details panel close button label',
    },
    devBoxDetailsPanelPoolLocationText: {
        id: 'DevBoxDetails_Panel_PoolLocation_Text',
        defaultMessage: '{poolName} ({location})',
        description:
            'Text for the dev box details panel pool and location value text. Do not localize {poolName} or {location}.',
    },
    devBoxDetailsPanelPoolText: {
        id: 'DevBoxDetails_Panel_Pool_Text',
        defaultMessage: '{poolName}',
        description: 'Text for the dev box details panel image value text. Do not localize {poolName}.',
    },
    devBoxDetailsPanelImageText: {
        id: 'DevBoxDetails_Panel_Image_Text',
        defaultMessage: '{imageName}',
        description: 'Text for the dev box details panel image value text. Do not localize {imageName}.',
    },
    devBoxDetailsPanelImageWithVersionText: {
        id: 'DevBoxDetails_Panel_ImageWithVersion_Text',
        defaultMessage: '{imageName} (version {imageVersion})',
        description:
            'Text for the dev box details panel image value text when we have a version. Do not localize {imageName} and {imageVersion}.',
    },
    cpuIconAriaLabel: {
        id: 'DevBoxDetails_Panel_CpuIcon_AriaLabel',
        defaultMessage: 'Number of cores',
        description: 'Aria label for CPU icon in dev box details panel.',
    },
    diskIconAriaLabel: {
        id: 'DevBoxDetails_Panel_DiskIcon_AriaLabel',
        defaultMessage: 'Total storage in GB',
        description: 'Aria label for disk icon in dev box details panel.',
    },
    memoryIconAriaLabel: {
        id: 'DevBoxDetails_Panel_MemoryIcon_AriaLabel',
        defaultMessage: 'Total RAM in GB',
        description: 'Aria label for memory icon in dev box details panel.',
    },
});

/**
 * Styles
 */

const useMetadataListStyles = makeStyles({
    root: {
        gap: '10px',
    },
});

const useContentStyles = makeStyles({
    root: {
        gap: '31px',
        fontSize: '14px',
    },
});

const useItemStyles = makeStyles({
    root: {
        gap: '11px',
    },
    item: {
        width: '272px',
        wordWrap: 'break-word',
    },
});

const useDevBoxStyles = makeStyles({
    name: typographyStyles.subtitle2,
    date: {
        ...typographyStyles.caption1,
        color: tokens.colorNeutralForeground2,
    },
});

/**
 * End Styles
 */

const DevBoxDetailsPanelComponent: React.FC<DevBoxDetailsPanelProps> = (props: DevBoxDetailsPanelProps) => {
    const { isOpen, onDismiss, name, createdOn, imageReference, scheduledStopTime, hardwareProfile, storageProfile } =
        props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const theme = useCurrentFluent2Theme();

    // Style hooks
    const stackStyles = useStackStyles();
    const contentStyles = useContentStyles();
    const itemStyles = useItemStyles();
    const devBoxStyles = useDevBoxStyles();

    const imageInformation = React.useMemo(() => {
        if (!imageReference || isUndefinedOrWhiteSpace(imageReference.name)) {
            return undefined;
        }

        const { name: imageName, version: imageVersion } = imageReference;

        return isNotUndefinedOrWhiteSpace(imageVersion)
            ? formatMessage(messages.devBoxDetailsPanelImageWithVersionText, {
                  imageName,
                  imageVersion,
              })
            : formatMessage(messages.devBoxDetailsPanelImageText, { imageName });
    }, [imageReference, formatMessage]);

    // Resource card metadata
    const cpuMetadata: MetadataItemViewModel = React.useMemo(
        () => ({
            description: formatMessage(messages.cpuIconAriaLabel),
            icon: <DeveloperBoard16Regular />,
            key: 'cpu',
            primary: false,
            value: <CPUMetadata cpuCount={hardwareProfile?.vCPUs} />,
        }),
        [hardwareProfile?.vCPUs, formatMessage]
    );

    const diskSizeMetadata: MetadataItemViewModel = React.useMemo(
        () => ({
            description: formatMessage(messages.diskIconAriaLabel),
            icon: <Database16Regular />,
            key: 'disk-size',
            primary: false,
            value: <DiskSizeMetadata diskSizeInGb={storageProfile?.osDisk?.diskSizeGB} />,
        }),
        [storageProfile?.osDisk?.diskSizeGB, formatMessage]
    );

    const memoryMetadata: MetadataItemViewModel = React.useMemo(
        () => ({
            description: formatMessage(messages.memoryIconAriaLabel),
            icon: <Memory16Regular />,
            key: 'memory',
            primary: false,
            value: <MemoryMetadata memoryInGb={hardwareProfile?.memoryGB} />,
        }),
        [hardwareProfile?.memoryGB, formatMessage]
    );

    const metadata = React.useMemo(
        () => [cpuMetadata, memoryMetadata, diskSizeMetadata],
        [cpuMetadata, diskSizeMetadata, memoryMetadata]
    );

    return (
        <FluentProvider theme={theme}>
            <Drawer open={isOpen} position="end" onOpenChange={onDismiss}>
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label={formatMessage(messages.devBoxDetailsPanelCloseButtonLabel)}
                                icon={<Dismiss24Regular />}
                                onClick={onDismiss}
                            />
                        }
                    >
                        <FormattedMessage
                            id="DevBoxDetails_Panel_Header_Text"
                            defaultMessage="Dev box details"
                            description="Text for the dev box details panel header."
                        />
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <div className={mergeClasses(stackStyles.root, contentStyles.root)}>
                        {createdOn && (
                            <div className={stackStyles.item}>
                                <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                                    <div className={mergeClasses(stackStyles.item, devBoxStyles.name)}>
                                        <Label>{name}</Label>
                                    </div>
                                    <div className={mergeClasses(stackStyles.item, devBoxStyles.date)}>
                                        <FormattedMessage
                                            id="DevBoxDetails_Panel_Description_CreatedDate"
                                            defaultMessage="Created {date}"
                                            description="Text for the created date for the dev box. Do not localize {date}."
                                            values={{
                                                date: (
                                                    <FormattedDate
                                                        value={createdOn}
                                                        year="numeric"
                                                        month="short"
                                                        day="numeric"
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <Divider />
                        {isNotUndefinedOrWhiteSpace(imageInformation) && (
                            <div className={stackStyles.item}>
                                <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                                    <div className={stackStyles.item}>
                                        <Label weight="semibold">
                                            <FormattedMessage
                                                id="DevBoxDetails_Panel_Image_Submenu_Title"
                                                defaultMessage="Source image"
                                                description="Text for the dev box details panel image submenu title"
                                            />
                                        </Label>
                                    </div>
                                    <div className={mergeClasses(stackStyles.item, itemStyles.item)}>
                                        {imageInformation}
                                    </div>
                                </div>
                            </div>
                        )}
                        {isNotUndefinedOrWhiteSpace(scheduledStopTime) && (
                            <div className={stackStyles.item}>
                                <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                                    <div className={stackStyles.item}>
                                        <Label weight="semibold">
                                            <FormattedMessage
                                                id="DevBoxDetails_Panel_Schedule_Submenu_Title"
                                                defaultMessage="Scheduled shut down time"
                                                description="Text for the dev box details panel schedule submenu title"
                                            />
                                        </Label>
                                    </div>
                                    <div className={stackStyles.item}>{scheduledStopTime}</div>
                                </div>
                            </div>
                        )}
                        <Divider />
                        <div className={stackStyles.item}>
                            <MetadataList metadataList={metadata} useContainerStyles={useMetadataListStyles} />
                        </div>
                    </div>
                </DrawerBody>
            </Drawer>
        </FluentProvider>
    );
};

const DevBoxDetailsPanel: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useDevBoxDetailsPanelContext();

    const locations = useSelector(getLocations);
    const onDismiss = React.useCallback(() => closePanel(), [closePanel]);

    return <DevBoxDetailsPanelComponent {...properties} isOpen={isOpen} onDismiss={onDismiss} locations={locations} />;
};

export const DevBoxDetailsPanelContainer: React.FC = () => {
    // Context hooks
    const { isOpen } = useDevBoxDetailsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <DevBoxDetailsPanel />;
};

export default DevBoxDetailsPanelContainer;
