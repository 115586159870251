import { FontSizes, makeStyles as legacyMakeStyles, Link } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Language, SupportedMarket } from '../../constants/localization';
import { SupportSectionLinks } from '../../constants/support-section-links';
import { getLocalizationConfiguration } from '../../language/languages';
import messages from '../../language/messages';
import { useHorizontalStackStyles } from '../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../utilities/styles';

interface FooterComponentProps {
    currentLanguage: Language;
    currentMarket: string;
}

/**
 * Styles
 */
const useFooterStyles = legacyMakeStyles((theme) => ({
    container: {
        height: 'fit-content',
        backgroundColor: getSemanticColor(theme, 'footerBackground'),
        fontSize: FontSizes.size12,
        lineHeight: '14px',
    },
    linkContainer: {
        paddingTop: '7px',
        paddingBottom: '7px',
        marginRight: '20px',
        gap: '24px',
    },
}));

const useFooterLinkStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'footerLinkText'),
    },
}));

const useMicrosoftCopyrightStyles = makeStyles({
    root: {
        marginRight: '24px',
    },
});

const useFooterStackStyles = makeStyles({
    root: {
        justifyContent: 'end',
    },
    item: {
        alignSelf: 'end',
    },
});

/**
 * End Styles
 */

const FooterComponent: React.FC<FooterComponentProps> = React.memo((props: FooterComponentProps) => {
    const { currentLanguage, currentMarket } = props;

    // Style hooks
    const footerStyles = useFooterStyles();
    const footerLinkStyles = useFooterLinkStyles();
    const microsoftCopyrightStyles = useMicrosoftCopyrightStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const footerStackStyles = useFooterStackStyles();

    // Memoization hooks
    const currentDate = React.useMemo(() => new Date(), []);

    // For Korean language and market, we have to a different and very specific string for this link.
    // More info here: https://liquid.microsoft.com/Web/Object/Read/MS.Privacy.MPS/Requirements/03.03.01.03
    const privacyLinkMessageDescriptor =
        currentLanguage === Language.KO || currentMarket === SupportedMarket.KR
            ? messages.privacyKoreanLanguageLinkText
            : messages.privacyLinkText;

    return (
        <div
            role="contentinfo"
            className={mergeClasses(horizontalStackStyles.root, footerStackStyles.root, footerStyles.container)}
        >
            <div className={mergeClasses(horizontalStackStyles.item, footerStackStyles.item)}>
                <div className={mergeClasses(horizontalStackStyles.root, footerStyles.linkContainer)}>
                    <div className={horizontalStackStyles.item}>
                        <Link href={SupportSectionLinks.Privacy} target="_blank" styles={footerLinkStyles}>
                            <FormattedMessage {...privacyLinkMessageDescriptor} />
                        </Link>
                    </div>
                    <div className={horizontalStackStyles.item}>
                        <Link href={SupportSectionLinks.ProductTerms} target="_blank" styles={footerLinkStyles}>
                            <FormattedMessage {...messages.productTermsLinkText} />
                        </Link>
                    </div>
                    <div className={mergeClasses(horizontalStackStyles.item, microsoftCopyrightStyles.root)}>
                        &copy; Microsoft <FormattedDate value={currentDate} year="numeric" />
                    </div>
                </div>
            </div>
        </div>
    );
});

export const FooterContainer: React.FC = () => {
    const { language, market } = getLocalizationConfiguration();

    return <FooterComponent currentLanguage={language} currentMarket={market} />;
};

export default FooterContainer;
