import {
    CustomizationTaskExecutionAccount,
    DevBoxesGetCustomizationTaskLogResponse,
    CustomizationGroup as GeneratedCustomizationGroupStable,
    CustomizationGroupStatus as GeneratedCustomizationGroupStatusStable,
    CustomizationTaskDefinitionParameter as GeneratedCustomizationTaskDefinitionParameterStable,
    CustomizationTaskDefinitionParameterType as GeneratedCustomizationTaskDefinitionParameterTypeStable,
    CustomizationTaskDefinition as GeneratedCustomizationTaskDefinitionStable,
    CustomizationTaskList as GeneratedCustomizationTaskListStable,
    CustomizationTaskListValidationError as GeneratedCustomizationTaskListValidationErrorStable,
    CustomizationTaskListValidationOperationResult as GeneratedCustomizationTaskListValidationOperationResultStable,
    CustomizationTaskListValidationResult as GeneratedCustomizationTaskListValidationResultStable,
    CustomizationTaskListValidationStatus as GeneratedCustomizationTaskListValidationStatusStable,
    CustomizationTask as GeneratedCustomizationTaskStable,
    CustomizationTaskStatus as GeneratedCustomizationTaskStatusStable,
    DevBoxesValidateCustomizationTasksActionResponse as GeneratedDevBoxesValidateCustomizationTasksActionResponseStable,
} from 'devcenter-internal-stable';
import { RequiredProperty } from '../../types/required-property';
import { UnionMap } from '../../types/union-map';

/**
 * Data Plane SDK shim models
 */

// We'll use this contract for any response back from the API representing a customization task definition
// (For now, this only specifies `uri`, but other property names can be added if we're certain they'll be defined)
export type CustomizationTaskDefinitionContract = RequiredProperty<GeneratedCustomizationTaskDefinitionStable, 'uri'>;

export type CustomizationTaskDefinitionParameterContract = GeneratedCustomizationTaskDefinitionParameterStable;
export type CustomizationTaskDefinitionParameterTypeContract = GeneratedCustomizationTaskDefinitionParameterTypeStable;

export const CustomizationTaskDefinitionParameterTypeContract: UnionMap<CustomizationTaskDefinitionParameterTypeContract> =
    {
        string: 'string',
        number: 'number',
        boolean: 'boolean',
    };

export type StableCustomizationGroupContract = GeneratedCustomizationGroupStable;

// Note: making a distinction here between request and response contracts, which are treated as the same by code gen.
//      - CustomizationGroupContract: response contract, in which readonly props (e.g. name, uri) are always available
//      - PutCustomizationGroupBodyContract: PUT request contract, in which readonly props aren't required
export type CustomizationGroupContract = Omit<
    RequiredProperty<GeneratedCustomizationGroupStable, 'uri' | 'name'>,
    'tasks'
> & { tasks?: CustomizationTaskContract[] };

export type PutCustomizationGroupBodyContract = Omit<GeneratedCustomizationGroupStable, 'tasks'> & {
    tasks?: PutCustomizationTaskBodyContract[];
};

export type CustomizationGroupStatusContract = GeneratedCustomizationGroupStatusStable;

export const CustomizationGroupStatusContract: UnionMap<CustomizationGroupStatusContract> = {
    NotStarted: 'NotStarted',
    Running: 'Running',
    Succeeded: 'Succeeded',
    Failed: 'Failed',
    ValidationFailed: 'ValidationFailed',
};

export type StableCustomizationTaskContract = GeneratedCustomizationTaskStable;

// Note: making a distinction here between request and response contracts, which are treated as the same by code gen.
//      - CustomizationTaskContract: response contract, in which readonly props (e.g. logUri, id) are always available
//      - PutCustomizationTaskBodyContract: PUT request contract, in which readonly props aren't required
export type CustomizationTaskContract = RequiredProperty<StableCustomizationTaskContract, 'logUri' | 'id'>;
export type PutCustomizationTaskBodyContract = StableCustomizationTaskContract;

export type CustomizationTaskStatusContract = GeneratedCustomizationTaskStatusStable;

export const CustomizationTaskStatusContract: UnionMap<CustomizationTaskStatusContract> = {
    NotStarted: 'NotStarted',
    Running: 'Running',
    Succeeded: 'Succeeded',
    FailedValidation: 'FailedValidation',
    Skipped: 'Skipped',
    TimedOut: 'TimedOut',
    Failed: 'Failed',
    WaitingForUserInputUac: 'WaitingForUserInputUac',
    WaitingForUserSession: 'WaitingForUserSession',
};

export type CustomizationTaskListContract = Omit<GeneratedCustomizationTaskListStable, 'tasks'> & {
    tasks: PutCustomizationTaskBodyContract[];
};

export type CustomizationTaskListValidationOperationResponseContract = RequiredProperty<
    GeneratedDevBoxesValidateCustomizationTasksActionResponseStable,
    'operationLocation'
>;

export type CustomizationTaskListValidationOperationResultContract =
    GeneratedCustomizationTaskListValidationOperationResultStable;

export type CustomizationTaskListValidationResultContract = GeneratedCustomizationTaskListValidationResultStable;

export type CustomizationTaskListValidationStatusContract = GeneratedCustomizationTaskListValidationStatusStable;

export const CustomizationTaskListValidationStatusContract: UnionMap<CustomizationTaskListValidationStatusContract> = {
    Succeeded: 'Succeeded',
    Failed: 'Failed',
};

export type CustomizationTaskListValidationErrorContract = GeneratedCustomizationTaskListValidationErrorStable;

export type CustomizationTaskExecutionAccountContract = CustomizationTaskExecutionAccount;

export const CustomizationTaskExecutionAccountContract: UnionMap<CustomizationTaskExecutionAccountContract> = {
    System: 'System',
    User: 'User',
};

export type GetCustomizationTaskLogResponseContract = DevBoxesGetCustomizationTaskLogResponse;
