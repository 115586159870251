import {
    AzureCoreFoundationsErrorBody,
    FailureCode,
    FailureOperation,
    FailureResponse,
    hasClientErrorLikeCode,
    hasClientErrorLikeDetails,
    isAzureCoreFoundationsErrorBody,
} from '../models/common';
import { getMessageFromThrown } from './error';
import { tryOrDefault } from './try-or-default';

export const aggregateFailureResponses = (
    operation: FailureOperation,
    ...failures: FailureResponse[]
): FailureResponse =>
    FailureResponse({
        code: FailureCode.MultipleFailures,
        details: failures,
        operation,
    });

export const createFailureResponseFromAzureCoreFoundationsErrorBody = (
    errorBody: AzureCoreFoundationsErrorBody,
    operation?: FailureOperation,
    statusCode?: number,
    headers?: Headers
): FailureResponse => {
    const { code, details, message } = errorBody;

    return FailureResponse({
        code,
        details: details
            ?.filter(isAzureCoreFoundationsErrorBody)
            ?.map((error) => createFailureResponseFromAzureCoreFoundationsErrorBody(error)),
        headers,
        message,
        operation,
        statusCode,
    });
};

export const createFailureResponseFromAzureCoreFoundationsErrorBodyOrDefault = (
    errorBody: AzureCoreFoundationsErrorBody | undefined,
    operation?: FailureOperation,
    statusCode?: number
): FailureResponse => {
    if (!errorBody) {
        return FailureResponse({ operation, statusCode });
    }

    return createFailureResponseFromAzureCoreFoundationsErrorBody(errorBody, operation, statusCode);
};

export const createFailureResponseFromThrown = (
    error: unknown,
    operation: FailureOperation = FailureOperation.Unknown
): FailureResponse =>
    FailureResponse({
        code: hasClientErrorLikeCode(error) ? error.code : undefined,
        details: hasClientErrorLikeDetails(error) ? error.details : undefined,
        message: getMessageFromThrown(error),
        operation,
    });

export const parseErrorResponse = async (response: Response): Promise<AzureCoreFoundationsErrorBody> => {
    const errorResponse = await response.json();
    return errorResponse;
};

export const tryParseErrorResponse = tryOrDefault(parseErrorResponse);
