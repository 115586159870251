import { FileContent } from 'use-file-picker/dist/interfaces';
import { CustomizationOperationName } from '../constants/customization';
import {
    CustomizationGroupContract,
    CustomizationGroupStatusContract,
    CustomizationTaskContract,
    CustomizationTaskDefinitionContract,
    CustomizationTaskExecutionAccountContract,
    CustomizationTaskListContract,
    CustomizationTaskListValidationErrorContract,
    CustomizationTaskListValidationOperationResultContract,
    CustomizationTaskListValidationResultContract,
    CustomizationTaskListValidationStatusContract,
    CustomizationTaskStatusContract,
    PutCustomizationTaskBodyContract,
} from '../data/contracts/customization';
import { UnionMap } from '../types/union-map';
import { Failure } from './common';

/**
 * Application models
 */

export type CustomizationGroup = CustomizationGroupContract;

export type CustomizationGroupStatus = CustomizationGroupStatusContract;

export const CustomizationGroupStatus: UnionMap<CustomizationGroupStatus> = {
    ...CustomizationGroupStatusContract,
};

export interface CustomizationFailure {
    operationName: CustomizationOperationName;
    failure: Failure;
}

export type CustomizationTask = CustomizationTaskContract;
export type PutCustomizationTask = PutCustomizationTaskBodyContract;
export type CustomizationTaskStatus = CustomizationTaskStatusContract;

export const CustomizationTaskStatus: UnionMap<CustomizationTaskStatus> = {
    ...CustomizationTaskStatusContract,
};

export type CustomizationTaskList = CustomizationTaskListContract;

export type CustomizationTaskDefinition = CustomizationTaskDefinitionContract;

export type CustomizationTaskListValidationOperationResult = CustomizationTaskListValidationOperationResultContract;

export type CustomizationTaskListValidationResult = CustomizationTaskListValidationResultContract;

export type CustomizationTaskListValidationStatus = CustomizationTaskListValidationStatusContract;

export const CustomizationTaskListValidationStatus: UnionMap<CustomizationTaskListValidationStatus> = {
    ...CustomizationTaskListValidationStatusContract,
};

export type OverallCustomizationGroup = Pick<CustomizationGroup, 'tasks' | 'status' | 'startTime' | 'endTime'>;

export type CustomizationTaskListValidationError = CustomizationTaskListValidationErrorContract;

export type CustomizationTaskExecutionAccount = CustomizationTaskExecutionAccountContract;

export const CustomizationTaskExecutionAccount: UnionMap<CustomizationTaskExecutionAccount> = {
    ...CustomizationTaskExecutionAccountContract,
};

export interface UploadedFileContent {
    fileContent: FileContent<string>;
    plainFile: File;
}

export interface RepoSourcedFileContent {
    name: string;
    content: string;
}
