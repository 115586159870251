import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { FeatureFlagName } from '../../constants/features';
import { Severity } from '../../constants/telemetry';
import {
    createEnvironmentDataPlaneUri,
    getTokensFromEnvironmentDataPlaneUri,
    tryGetTokensFromEnvironmentDataPlaneUri,
} from '../../ids/environment';
import { createEnvironmentDefinitionDataPlaneUri } from '../../ids/environment-definition';
import { getTokensFromEnvironmentOperationDataPlaneUri } from '../../ids/environment-operation';
import { createProjectDataPlaneUri } from '../../ids/project';
import { Status } from '../../models/common';
import { Environment, EnvironmentOperation, EnvironmentOperationLogUri } from '../../models/environment';
import { EnvironmentDefinition, EnvironmentDefinitionParameter } from '../../models/environment-definition';
import { ProjectFromDiscoveryService, ProjectResource, ProjectResourceMap } from '../../models/project';
import { StoreStateSelector, isStatusTerminal, isTerminalLoadingState } from '../../redux/selector/common';
import {
    getEnvironmentDefinitions,
    getStatusesForListEnvironmentDefinitions,
} from '../../redux/selector/environment-definition-selectors';
import {
    getEnvironmentOperationLogs,
    getEnvironmentOperations,
} from '../../redux/selector/environment-operation-selectors';
import {
    getEnvironments,
    getFailuresOnEnvironments,
    getPendingStatesForEnvironments,
} from '../../redux/selector/environment-selectors';
import { getGraphDirectoryObjects, getStatusesForGetGraphDirectoryObject } from '../../redux/selector/graph-selectors';
import { getProjectsByDiscoveryServiceURI } from '../../redux/selector/project-from-discovery-service-selectors';
import { getProjectsByDataPlaneId } from '../../redux/selector/project-selectors';
import { getStatusForLoadEnvironmentCardContent } from '../../redux/selector/sub-applications/home-selectors';
import { getDisplayTime } from '../../redux/selector/time-selectors';
import { AsyncState } from '../../redux/store/common-state';
import { SerializableMap } from '../../types/serializable-map';
import { compact, sortByInPlace } from '../../utilities/array';
import { getEnvironmentPendingProvisioningState } from '../../utilities/environment';
import { isFeatureFlagEnabled } from '../../utilities/features';
import {
    ResourceIdComponentName,
    tryGetResourceIdComponent,
} from '../../utilities/resource-manager/get-resource-id-components';
import { get, values } from '../../utilities/serializable-map';
import { areStringsEquivalent, compareStrings } from '../../utilities/string';
import { trackTrace } from '../../utilities/telemetry/channel';
import {
    EnvironmentDefinitionViewModel,
    EnvironmentExpirationDateOffset,
    EnvironmentExpirationDateOffsetUnit,
    EnvironmentOperationViewModel,
    EnvironmentViewModel,
} from './models';
import {
    EnvironmentDefinitionParameterValue,
    EnvironmentDefinitionParameterViewModel,
} from './parameters-field/models';

const getEnvironmentDeploymentLogsOperations = (
    environmentOperationsMap: SerializableMap<EnvironmentOperation[]>,
    environmentOperationLogsMap: SerializableMap<EnvironmentOperationLogUri>,
    environmentUri: string
): EnvironmentOperationViewModel[] => {
    const filteredOperations: EnvironmentOperation[] = [];

    // Filter for operations belonging to current environment
    values(environmentOperationsMap).map((operations) => {
        const results = operations.filter((operation) => {
            const { devCenter, environmentName, projectName, user } = getTokensFromEnvironmentOperationDataPlaneUri(
                operation.uri
            );
            const uri = createEnvironmentDataPlaneUri({ devCenter, environmentName, projectName, user });
            return environmentUri === uri;
        });

        filteredOperations.push(...results);
    });

    // Map to view model object
    return filteredOperations.map((operation) => ({
        kind: operation.kind,
        operationUri: operation.uri,
        operationId: operation.operationId,
        status: operation.status,
        createdByObjectId: operation.createdByObjectId,
        startTime: operation.startTime,
        endTime: operation.endTime,
        logBlobUri: get(environmentOperationLogsMap, operation.uri)?.logBlobUri,
    }));
};

const getEnvironmentDefinition = (
    environmentDefinitions: SerializableMap<EnvironmentDefinition>,
    environment: Environment
): EnvironmentDefinitionViewModel | undefined => {
    const { catalogName, environmentDefinitionName, uri } = environment;

    // These won't be undefined, but environment typedef says they can be
    if (!catalogName || !environmentDefinitionName) {
        return undefined;
    }

    const { devCenter, projectName } = getTokensFromEnvironmentDataPlaneUri(uri);

    const environmentDefinitionId = createEnvironmentDefinitionDataPlaneUri({
        catalogName,
        devCenter,
        environmentDefinitionName,
        projectName,
    });

    const environmentDefinition = get(environmentDefinitions, environmentDefinitionId);

    if (!environmentDefinition) {
        return undefined;
    }

    return getEnvironmentDefinitionViewModel(environmentDefinition);
};

// If environment card content discovery is complete, all environment card content is ready
const getIsEnvironmentCardContentReady = (
    environment: Environment,
    statusesForGetGraphDirectoryObject: SerializableMap<Status>,
    statusesForListEnvironmentDefinitions: SerializableMap<Status>,
    statusForLoadEnvironmentCardContent: Status
): boolean => {
    if (isStatusTerminal(statusForLoadEnvironmentCardContent)) {
        return true;
    }

    const { user, uri } = environment;

    const isOwnerReady = isStatusTerminal(get(statusesForGetGraphDirectoryObject, user));
    const { devCenter, projectName } = getTokensFromEnvironmentDataPlaneUri(uri);
    const projectId = createProjectDataPlaneUri({ devCenter, projectName });
    const isEnvironmentDefinitionReady = isStatusTerminal(get(statusesForListEnvironmentDefinitions, projectId));

    return isOwnerReady && isEnvironmentDefinitionReady;
};

export const getEnvironmentProjectName = (projects: ProjectResourceMap, id: string): string => {
    const { projectName } = tryGetTokensFromEnvironmentDataPlaneUri(id) ?? {};
    const selectedProject = values(projects).find((project) => areStringsEquivalent(projectName, project.name, true));

    if (!selectedProject) {
        trackTrace('Failed to find matching project, something is rotten in the application state', {
            severity: Severity.Warning,
        });
        return '';
    }

    return selectedProject.name;
};

export const getEnvironmentProjectNameFromDiscoveryService = (
    projects: SerializableMap<ProjectFromDiscoveryService>,
    id: string
): string => {
    const { projectName } = tryGetTokensFromEnvironmentDataPlaneUri(id) ?? {};
    const selectedProject = values(projects).find((project) => areStringsEquivalent(projectName, project.name, true));
    if (!selectedProject) {
        trackTrace('Failed to find matching project, something is rotten in the application state', {
            severity: Severity.Warning,
        });
        return '';
    }

    return selectedProject.name;
};

/* TODO: Task 1914883: Create mapping of dev center endpoints to their display names
 * Work item link: https://dev.azure.com/devdiv/OnlineServices/_workitems/edit/1914883 */
const getDevCenterNameFromProject = (environmentId: string, projects: SerializableMap<ProjectResource>): string => {
    const { devCenter, projectName } = getTokensFromEnvironmentDataPlaneUri(environmentId);
    const projectId = createProjectDataPlaneUri({ devCenter, projectName });
    const project = get(projects, projectId);

    if (!project) {
        return devCenter;
    }

    const { properties } = project;
    const { devCenterId } = properties;

    return tryGetResourceIdComponent(devCenterId, ResourceIdComponentName.ResourceName) ?? devCenter;
};

const getDevCenterNameFromDevcenterUri = (uri: string): string => {
    const regex = /https:\/\/[a-f0-9-]+-([a-z0-9-]+)\./;

    // Extract the name part
    const match = uri.match(regex);
    const devCenterName = match ? match[1] : undefined;
    return devCenterName ?? '';
};

const getDevCenterNameFromDiscoveryServiceProject = (
    environmentId: string,
    projects: SerializableMap<ProjectFromDiscoveryService>
): string => {
    const { devCenter, projectName } = getTokensFromEnvironmentDataPlaneUri(environmentId);
    const projectId = createProjectDataPlaneUri({ devCenter, projectName });
    const project = get(projects, projectId);

    if (!project) {
        return devCenter;
    }

    const { devCenterUri } = project;

    return getDevCenterNameFromDevcenterUri(devCenterUri);
};

const convertParameterValueToNumber = (value: string): number => Number(value);

const convertParameterValueToBoolean = (value: string | undefined): boolean | undefined => {
    if (!value) {
        return undefined;
    }

    switch (value) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            return undefined;
    }
};

/**
 * Application state selectors
 */

export const getEnvironmentViewModels: StoreStateSelector<EnvironmentViewModel[]> = createSelector(
    [
        getEnvironments,
        getFailuresOnEnvironments,
        getPendingStatesForEnvironments,

        getProjectsByDataPlaneId,
        getProjectsByDiscoveryServiceURI,

        getGraphDirectoryObjects,
        getStatusesForGetGraphDirectoryObject,

        getEnvironmentDefinitions,
        getStatusesForListEnvironmentDefinitions,

        getEnvironmentOperations,
        getEnvironmentOperationLogs,

        getStatusForLoadEnvironmentCardContent,
        getDisplayTime,
    ],
    (
        environments,
        failuresOnEnvironments,
        pendingStatesForEnvironments,
        projectsByDataPlaneId,
        projectsByDiscoveryServiceURI,
        graphDirectoryObjects,
        statusesForGetGraphDirectoryObject,
        environmentDefinitions,
        statusesForListEnvironmentDefinitions,
        environmentOperationsMap,
        environmentOperationLogsMap,
        statusForLoadEnvironmentCardContent,
        currentDate
    ) => {
        const viewModels = environments.map((environment) => {
            const { expirationDate, uri, user: ownerId } = environment;
            const pendingState = get(pendingStatesForEnvironments, uri);
            const state = pendingState ?? getEnvironmentPendingProvisioningState(environment.provisioningState);

            // Get owner display name
            const owner = !!ownerId ? get(graphDirectoryObjects, ownerId) : undefined;
            const ownerLoadingState = !!ownerId
                ? get(statusesForGetGraphDirectoryObject, ownerId)?.state
                : AsyncState.Success;

            // Get environment definition
            const environmentDefinition = getEnvironmentDefinition(environmentDefinitions, environment);

            // Get environment operations and logs
            const environmentOperations = getEnvironmentDeploymentLogsOperations(
                environmentOperationsMap,
                environmentOperationLogsMap,
                uri
            );

            //Get dev center name
            const devCenterName = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
                ? getDevCenterNameFromDiscoveryServiceProject(uri, projectsByDiscoveryServiceURI)
                : getDevCenterNameFromProject(uri, projectsByDataPlaneId);

            const expirationDateOffset = !!expirationDate
                ? getEnvironmentExpirationDateOffset(expirationDate, currentDate)
                : undefined;

            const isCardContentReady = getIsEnvironmentCardContentReady(
                environment,
                statusesForGetGraphDirectoryObject,
                statusesForListEnvironmentDefinitions,
                statusForLoadEnvironmentCardContent
            );

            const failureOnEnvironment = get(failuresOnEnvironments, uri);

            // Get project name
            const projectName = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
                ? getEnvironmentProjectNameFromDiscoveryService(projectsByDiscoveryServiceURI, uri)
                : getEnvironmentProjectName(projectsByDataPlaneId, uri);

            const { devCenter } = getTokensFromEnvironmentDataPlaneUri(uri);

            const projectId = createProjectDataPlaneUri({ devCenter, projectName });

            const projectDisplayName = isFeatureFlagEnabled(FeatureFlagName.EnableDiscoveryService)
                ? get(projectsByDiscoveryServiceURI, projectId)?.displayName
                : get(projectsByDataPlaneId, projectId)?.properties.displayName;

            return {
                resource: environment,
                key: uri,
                state,
                ownerDisplayName: owner?.displayName ?? ownerId,
                ownerIsReady: isTerminalLoadingState(ownerLoadingState),
                environmentDefinition,
                isCardContentReady,
                projectName,
                failureOnEnvironment,
                devCenterName,
                expirationDateOffset,
                projectDisplayName,
                environmentOperations,
            };
        });

        return sortByInPlace(
            viewModels,
            (item) => item.resource.name ?? '',
            (a, b) => compareStrings(a, b, true)
        );
    }
);

/**
 * Other selectors
 */

export const getEnvironmentDefinitionParameterViewModels = (
    parameters: EnvironmentDefinitionParameter[] | undefined
): EnvironmentDefinitionParameterViewModel[] => {
    if (!parameters) {
        return [];
    }

    const parameterViewModels = parameters.map((parameter): EnvironmentDefinitionParameterViewModel | undefined => {
        const { name: parameterName, id, type, default: parameterDefault, allowed: parameterAllowed } = parameter;

        const name = parameterName ?? id;

        let defaultValue: EnvironmentDefinitionParameterValue | undefined = parameterDefault;
        let allowed: EnvironmentDefinitionParameterValue[] | undefined = parameterAllowed;

        switch (type) {
            case 'boolean':
                defaultValue = convertParameterValueToBoolean(parameterDefault);
                // Providing allowed values for a boolean param is a misconfiguration, so we will ignore them if provided
                // and let request validation catch invalid input
                allowed = undefined;
                break;
            case 'integer':
            case 'number':
                if (parameterDefault) {
                    defaultValue = convertParameterValueToNumber(parameterDefault);
                }
                allowed = parameterAllowed?.map((value) => convertParameterValueToNumber(value));
                break;
            default:
                break;
        }

        if (allowed && allowed.length === 0) {
            allowed = undefined;
        }

        return { ...parameter, name, id, default: defaultValue, allowed };
    });

    return sortByInPlace(
        compact(parameterViewModels),
        (option) => option.name,
        (a, b) => compareStrings(a, b, true)
    );
};

export const getEnvironmentDefinitionViewModel = (
    environmentDefinition: EnvironmentDefinition
): EnvironmentDefinitionViewModel => {
    const { description, parameters } = environmentDefinition;

    const parameterViewModels = getEnvironmentDefinitionParameterViewModels(parameters);

    return {
        description,
        parameters: parameterViewModels,
    };
};

export const getEnvironmentViewModelsInProject = (
    environments: EnvironmentViewModel[],
    selectedProjectId: string
): EnvironmentViewModel[] =>
    environments.filter((environment) => {
        const { devCenter, projectName } = getTokensFromEnvironmentDataPlaneUri(environment.resource.uri);
        const projectId = createProjectDataPlaneUri({ devCenter, projectName });

        return areStringsEquivalent(projectId, selectedProjectId, true);
    });

export const getEnvironmentExpirationDateOffset = (
    expirationDate: Date,
    currentDate: Date
): EnvironmentExpirationDateOffset | undefined => {
    const inputDateTime = DateTime.fromJSDate(expirationDate);
    const currentDateTime = DateTime.fromJSDate(currentDate);

    if (inputDateTime < currentDateTime) {
        return undefined;
    }

    const difference = inputDateTime.diff(currentDateTime, ['years', 'months', 'weeks', 'days', 'hours', 'minutes']);

    const { years, months, weeks, days, hours, minutes } = difference;

    if (years >= 1) {
        return {
            offset: years,
            unit: EnvironmentExpirationDateOffsetUnit.Years,
        };
    } else if (months >= 1) {
        return {
            offset: months,
            unit: EnvironmentExpirationDateOffsetUnit.Months,
        };
    } else if (weeks >= 1) {
        return {
            offset: weeks,
            unit: EnvironmentExpirationDateOffsetUnit.Weeks,
        };
    } else if (days >= 1) {
        return {
            offset: days,
            unit: EnvironmentExpirationDateOffsetUnit.Days,
        };
    } else if (hours >= 1) {
        return {
            offset: hours,
            unit: EnvironmentExpirationDateOffsetUnit.Hours,
        };
    } else if (minutes >= 1) {
        return {
            offset: minutes,
            unit: EnvironmentExpirationDateOffsetUnit.Minutes,
        };
    } else {
        return {
            offset: 0,
            unit: EnvironmentExpirationDateOffsetUnit.Seconds,
        };
    }
};
