import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { EnvironmentOperation, EnvironmentOperationLogUri } from '../../models/environment';
import { createInitializer } from '../../utilities/initializer';
import { statusAdapter } from '../adapters/common/status-adapter';
import { environmentOperationAdapter, environmentOperationLogAdapter } from '../adapters/environment-operation-adapter';
import { IndexedStatusStore } from './indexed-status-store';
import { StatusStore } from './status-store';

type StatusName = 'discoverEnvironmentOperationsForEnvironment';
type IndexedStatusName = 'listEnvironmentOperations' | 'getEnvironmentOperationLog';

export interface EnvironmentOperationDataStore {
    operations: EntityState<Entity<EnvironmentOperation[]>>;
    logUris: EntityState<Entity<EnvironmentOperationLogUri>>;
}

export const EnvironmentOperationDataStore = createInitializer<EnvironmentOperationDataStore>({
    operations: environmentOperationAdapter.getInitialState(),
    logUris: environmentOperationLogAdapter.getInitialState(),
});

export type EnvironmentOperationStatusStore = StatusStore<StatusName> & IndexedStatusStore<IndexedStatusName>;

export const EnvironmentOperationStatusStore = createInitializer<EnvironmentOperationStatusStore>({
    discoverEnvironmentOperationsForEnvironment: Status(),
    getEnvironmentOperationLog: statusAdapter.getInitialState(),
    listEnvironmentOperations: statusAdapter.getInitialState(),
});

export interface EnvironmentOperationsStore {
    data: EnvironmentOperationDataStore;
    status: EnvironmentOperationStatusStore;
}

export const EnvironmentOperationsStore = createInitializer<EnvironmentOperationsStore>({
    data: EnvironmentOperationDataStore(),
    status: EnvironmentOperationStatusStore(),
});
