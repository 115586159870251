import { SagaIterator } from '@redux-saga/types';
import { all, call, put } from 'redux-saga/effects';
import {
    ListEnvironmentOperationsResponse,
    listEnvironmentOperations,
} from '../../../data/services/data-plane-api/environment-operation';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import {
    getEnvironmentOperationLog,
    listEnvironmentOperations as listEnvironmentOperationsActionCreator,
    listEnvironmentOperationsError,
    listEnvironmentOperationsFailed,
    listEnvironmentOperationsSuccess,
} from '../../actions/environment-operation/environment-operation-action-creators';
import { ListEnvironmentOperationsAction } from '../../actions/environment-operation/environment-operation-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeEvery } from '../../effects/take';

export function* listEnvironmentOperationsSaga(action: ListEnvironmentOperationsAction): SagaIterator {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { id } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const response: ListEnvironmentOperationsResponse = yield call(
            listEnvironmentOperations,
            id,
            accessToken,
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(listEnvironmentOperationsFailed({ failure: response, id }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { data } = response;

        yield put(listEnvironmentOperationsSuccess({ id, result: data }, meta));
        yield resolveAction(action, response);

        // Get log for all environment operations
        yield all(data.map((operation) => put(getEnvironmentOperationLog({ id: operation.uri }))));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListEnvironmentOperations);
        yield put(listEnvironmentOperationsError({ error, id }, meta));
        yield rejectAction(action, error);
    }
}

export function* listEnvironmentOperationsListenerSaga(): SagaIterator {
    yield takeEvery(listEnvironmentOperationsActionCreator, listEnvironmentOperationsSaga);
}
