import { ApiVersion } from '../../../constants/azure';
import { FeatureFlagName } from '../../../constants/features';
import { getTokensFromDevBoxDataPlaneUri } from '../../../ids/dev-box';
import { getTokensFromDevBoxActionDataPlaneUri } from '../../../ids/dev-box-action';
import { DataResponse, FailureOperation } from '../../../models/common';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { DevBoxActionContract, DevBoxActionDelayResultContract } from '../../contracts/dev-box-action';
import { getCommonOptions, sendIterableRequest, sendRequest } from './common';

export type DelayAllDevBoxActionsResponse = DataResponse<DevBoxActionDelayResultContract[]>;
export type DelayDevBoxActionResponse = DataResponse<DevBoxActionContract>;
export type GetDevBoxActionResponse = DataResponse<DevBoxActionContract>;
export type ListDevBoxActionsResponse = DataResponse<DevBoxActionContract[]>;
export type SkipDevBoxActionResponse = DataResponse;

export const delayAllDevBoxActions = async (
    id: string,
    until: Date,
    accessToken: string,
    activityId?: string
): Promise<DelayAllDevBoxActionsResponse> => {
    const { devCenter, devBoxName, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        operation: FailureOperation.DelayAllDevBoxActions,

        whenUsingStableClient: (client) =>
            client.devBoxes.listDelayActions(
                projectName,
                user,
                devBoxName,
                until,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingBetaClient: (client) =>
            client.devBoxes.listDelayActions(
                projectName,
                user,
                devBoxName,
                until,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const delayDevBoxAction = async (
    id: string,
    until: Date,
    accessToken: string,
    activityId?: string
): Promise<DelayDevBoxActionResponse> => {
    const { devBoxActionName, devBoxName, devCenter, projectName, user } = getTokensFromDevBoxActionDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.DelayDevBoxAction,

        whenUsingStableClient: (client) =>
            client.devBoxes.delayAction(
                projectName,
                user,
                devBoxName,
                devBoxActionName,
                until,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingBetaClient: (client) =>
            client.devBoxes.delayAction(
                projectName,
                user,
                devBoxName,
                devBoxActionName,
                until,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const getDevBoxAction = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<GetDevBoxActionResponse> => {
    const { devBoxActionName, devBoxName, devCenter, projectName, user } = getTokensFromDevBoxActionDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.GetDevBoxAction,

        whenUsingStableClient: (client) =>
            client.devBoxes.getAction(
                projectName,
                user,
                devBoxName,
                devBoxActionName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingBetaClient: (client) =>
            client.devBoxes.getAction(
                projectName,
                user,
                devBoxName,
                devBoxActionName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};

export const listDevBoxActions = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<ListDevBoxActionsResponse> => {
    const { devBoxName, devCenter, projectName, user } = getTokensFromDevBoxDataPlaneUri(id);

    const result = await sendIterableRequest(devCenter, {
        apiVersion: isFeatureFlagEnabled(FeatureFlagName.EnableOffloadDevBox)
            ? ApiVersion.DevCenterDataPlanePrivatePreview
            : undefined,
        operation: FailureOperation.ListDevBoxActions,

        whenUsingStableClient: (client) =>
            client.devBoxes.listActions(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),

        whenUsingBetaClient: (client) =>
            client.devBoxes.listActions(projectName, user, devBoxName, getCommonOptions(accessToken, activityId)),
    });

    return result;
};

export const skipDevBoxAction = async (
    id: string,
    accessToken: string,
    activityId?: string
): Promise<SkipDevBoxActionResponse> => {
    const { devBoxActionName, devBoxName, devCenter, projectName, user } = getTokensFromDevBoxActionDataPlaneUri(id);

    const result = await sendRequest(devCenter, {
        operation: FailureOperation.SkipDevBoxAction,

        whenUsingStableClient: (client) =>
            client.devBoxes.skipAction(
                projectName,
                user,
                devBoxName,
                devBoxActionName,
                getCommonOptions(accessToken, activityId)
            ),

        whenUsingBetaClient: (client) =>
            client.devBoxes.skipAction(
                projectName,
                user,
                devBoxName,
                devBoxActionName,
                getCommonOptions(accessToken, activityId)
            ),
    });

    return result;
};
