import { Link, makeStyles, TreeItemLayout } from '@fluentui/react-components';
import { ArrowDownload20Regular } from '@fluentui/react-icons';
import { Duration, Interval } from 'luxon';
import * as React from 'react';
import { FormattedNumber } from 'react-intl';

interface CustomizedTreeHeaderProps {
    statusIcon?: JSX.Element;
    name: string;
    startTime?: Date;
    endTime?: Date;
    logUrl?: string;
    logFileName?: string;
}

interface ActionContentProps {
    downloadAction?: JSX.Element;
}

interface AsideContentProps {
    completionTime?: JSX.Element;
}

/**
 * Styles
 */

const useHeaderStyles = makeStyles({
    root: {
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    info: {
        paddingTop: '2px',
    },
});

/**
 * End Styles
 */

const AsideContent: React.FC<AsideContentProps> = React.memo((props: AsideContentProps) => {
    const { completionTime } = props;

    const headerStyles = useHeaderStyles();

    return <div className={headerStyles.info}>{completionTime}</div>;
});

const ActionContent: React.FC<ActionContentProps> = React.memo((props: ActionContentProps) => {
    const { downloadAction } = props;

    const headerStyles = useHeaderStyles();

    return <div className={headerStyles.info}>{downloadAction}</div>;
});

export const CustomizedTreeHeader: React.FC<CustomizedTreeHeaderProps> = (props: CustomizedTreeHeaderProps) => {
    const { name, statusIcon, startTime, endTime, logUrl, logFileName } = props;

    const headerStyles = useHeaderStyles();

    const timeTaken = React.useMemo(
        () =>
            !!endTime && !!startTime
                ? Interval.fromDateTimes(startTime, endTime).toDuration(['minutes', 'seconds'])
                : 0,
        [startTime, endTime]
    );

    const downloadAction = React.useMemo(
        () =>
            startTime &&
            endTime && (
                <Link href={logUrl} download={logFileName} target="_blank" rel="noreferrer">
                    <ArrowDownload20Regular />
                </Link>
            ),
        [startTime, endTime, logUrl, logFileName]
    );

    const completionTime = React.useMemo(() => {
        if (startTime === undefined || endTime === undefined) {
            return <></>;
        }

        const minutes = Math.round((timeTaken as Duration).minutes);
        const seconds = Math.round((timeTaken as Duration).seconds);

        const formattedSeconds = <FormattedNumber value={seconds} style="unit" unit="second" unitDisplay="narrow" />;

        if (minutes !== 0) {
            const formattedMinutes = (
                <FormattedNumber value={minutes} style="unit" unit="minute" unitDisplay="narrow" />
            );
            return (
                <>
                    {formattedMinutes} {formattedSeconds}
                </>
            );
        }

        return formattedSeconds;
    }, [startTime, endTime, timeTaken]);

    const getActionContent = React.useMemo(
        () => <ActionContent downloadAction={downloadAction} />,
        [downloadAction, headerStyles]
    );

    const getAsideContent = React.useMemo(
        () => <AsideContent completionTime={completionTime} />,
        [completionTime, headerStyles]
    );

    return (
        <TreeItemLayout
            className={headerStyles.root}
            iconBefore={statusIcon}
            aside={getAsideContent}
            actions={getActionContent}
        >
            {name}
        </TreeItemLayout>
    );
};
