import {
    Label,
    Link,
    makeStyles,
    mergeClasses,
    Radio,
    RadioGroup,
    RadioGroupOnChangeData,
    Switch,
    SwitchOnChangeData,
    typographyStyles,
} from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { FeatureFlagName } from '../../../constants/features';
import { Metric, Property } from '../../../constants/telemetry';
import { WindowsStoreLinks } from '../../../constants/windows-store-links';
import { setUserSettings } from '../../../redux/actions/user-settings/user-settings-action-creator';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { ThemeMode } from '../../../themes/themes';
import { ReturnVoid } from '../../../types/return-void';
import { RemoteAppOption, UserSettings } from '../../../types/user-settings';
import { isFeatureFlagEnabled } from '../../../utilities/features';
import { trackMetric } from '../../../utilities/telemetry/channel';

const messages = defineMessages({
    themeModeCheckboxAriaLabel: {
        id: 'SettingsFormFieldGroup_ThemeModeCheckbox_AriaLabel',
        defaultMessage: 'Change theme mode',
        description: 'Aria label for "change theme mode" checkbox',
    },
    multiMonitorCheckboxAriaLabel: {
        id: 'SettingsFormFieldGroup_MultiMonitorCheckbox_AriaLabel',
        defaultMessage: 'Enable Multiple Monitor Support',
        description: 'Aria label for "Enable Multi Monitor Support" checkbox',
    },
    useWindowsAppCheckboxAriaLabel: {
        id: 'SettingsFormFieldGroup_UseWindowsAppCheckbox_AriaLabel',
        defaultMessage: 'Enable use windows Application for remote connection',
        description: 'Aria label for "use windows app" checkbox',
    },
    connectViaWindowsAppButtonLabelText: {
        id: 'SettingsFormFieldGroup_ConnectViaWindowsAppButtonLabel_Text',
        defaultMessage: 'Connect via {appName}',
        description: '{appName} should not be localized',
    },
    connectViaRemoteDesktopLegacyButtonLabelText: {
        id: 'SettingsFormFieldGroup_ConnectViaRemoteDesktopLegacyButtonLabel_Text',
        defaultMessage: 'Connect via {appName} (legacy)',
        description: '{appName} should not be localized',
    },
    useSingleMonitorText: {
        id: 'SettingsFormFieldGroup_UseSingleMonitor_Text',
        defaultMessage: 'Use a single monitor',
        description: 'Label for choice group option "Use a single monitor"',
    },
    useMultiMonitorText: {
        id: 'SettingsFormFieldGroup_UseMultiMonitor_Text',
        defaultMessage: 'Use multiple monitors',
        description: 'Label for choice group option "Use a multiple monitors"',
    },
    windowsAppNameText: {
        id: 'SettingsFormFieldGroup_WindowsAppName_Text',
        defaultMessage: 'Windows App',
        description: 'Label for choice group option "Windows App"',
    },
    remoteDesktopAppNameText: {
        id: 'SettingsFormFieldGroup_RemoteDesktopAppName_Text',
        defaultMessage: 'Remote Desktop',
        description: 'Label for choice group option "Remote Desktop"',
    },
});

/**
 * Styles
 */

const useHeaderStyles = makeStyles({
    root: { ...typographyStyles.subtitle2, gap: '8px', paddingTop: '12px' },
});

const useFieldGroupStyles = makeStyles({
    root: {
        gap: '12px',
    },
});

/**
 * END Styles
 */

type UserSettingsPanelComponentProps = {
    currentUserSettings: UserSettings;
    submit: ReturnVoid<typeof setUserSettings>;
};

const handleRemoteDesktopLinkClick = () => {
    trackMetric(Metric.InstallRemoteClientLinkClicked, 1, {
        properties: {
            [Property.Link]: WindowsStoreLinks.RemoteDesktop,
        },
    });
};

const handleWindowsAppLinkClick = () => {
    trackMetric(Metric.InstallRemoteClientLinkClicked, 1, {
        properties: {
            [Property.Link]: WindowsStoreLinks.WindowsApp,
        },
    });
};

export const UserSettingsFormFieldGroup: React.FC<UserSettingsPanelComponentProps> = (
    props: UserSettingsPanelComponentProps
) => {
    const { currentUserSettings, submit } = props;

    // Styles
    const stackStyles = useStackStyles();
    const headerStyles = useHeaderStyles();
    const fieldGroupStyles = useFieldGroupStyles();

    // Intl hooks
    const { formatMessage } = useIntl();

    const remoteDesktopLinkedAppTitle = React.useMemo(
        () =>
            formatMessage(messages.connectViaRemoteDesktopLegacyButtonLabelText, {
                appName: (
                    <Link href={WindowsStoreLinks.RemoteDesktop} target="_blank" onClick={handleRemoteDesktopLinkClick}>
                        {formatMessage(messages.remoteDesktopAppNameText)}
                    </Link>
                ),
            }),
        [formatMessage]
    );

    const windowsAppLinkedAppTitle = React.useMemo(
        () =>
            formatMessage(messages.connectViaWindowsAppButtonLabelText, {
                appName: (
                    <Link href={WindowsStoreLinks.WindowsApp} target="_blank" onClick={handleWindowsAppLinkClick}>
                        {formatMessage(messages.windowsAppNameText)}
                    </Link>
                ),
            }),
        [formatMessage]
    );

    const connectionOptions: JSX.Element[] = React.useMemo(
        () => [
            <Radio
                key={RemoteAppOption.useWindowsApp}
                value={RemoteAppOption.useWindowsApp}
                label={windowsAppLinkedAppTitle as string}
            />,
            <Radio
                key={RemoteAppOption.useAvd}
                value={RemoteAppOption.useAvd}
                label={remoteDesktopLinkedAppTitle as string}
            />,
        ],
        [windowsAppLinkedAppTitle, remoteDesktopLinkedAppTitle]
    );

    const monitorOptions: JSX.Element[] = React.useMemo(
        () => [
            <Radio key="false" value="false" label={formatMessage(messages.useSingleMonitorText)} />,
            <Radio key="true" value="true" label={formatMessage(messages.useMultiMonitorText)} />,
        ],
        [formatMessage]
    );

    const onMultiMonitorChange = React.useCallback(
        (_ev: React.FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) => {
            const selectedKey = data.value === 'true';
            const newSettings: UserSettings = {
                ...currentUserSettings,
                useMultiMonitor: selectedKey,
            };
            submit(newSettings);
        },
        [submit, currentUserSettings]
    );

    const onPreferredRemoteAppChange = React.useCallback(
        (_ev: React.FormEvent<HTMLDivElement>, data: RadioGroupOnChangeData) => {
            const newSettings: UserSettings = {
                ...currentUserSettings,
                preferredRemoteApp:
                    RemoteAppOption.useAvd === data.value ? RemoteAppOption.useAvd : RemoteAppOption.useWindowsApp,
            };
            submit(newSettings);
        },
        [submit, currentUserSettings]
    );

    const onThemeModeChange = React.useCallback(
        (_ev: React.ChangeEvent<HTMLInputElement>, data: SwitchOnChangeData) => {
            const newSettings: UserSettings = {
                ...currentUserSettings,
                themeMode: data.checked ? ThemeMode.DarkMode : ThemeMode.LightMode,
            };
            submit(newSettings);
        },
        [submit, currentUserSettings]
    );

    return (
        <div className={mergeClasses(stackStyles.root, fieldGroupStyles.root)}>
            {isFeatureFlagEnabled(FeatureFlagName.EnableThemeModeToggle) && (
                <div className={stackStyles.item}>
                    <div className={stackStyles.root}>
                        <Label>
                            <FormattedMessage
                                id="SettingsFormFieldGroup_ThemeModeCheckbox_Label"
                                defaultMessage="Dark mode"
                                description='Label for "theme mode checkbox" checkbox'
                            />
                        </Label>
                        <Switch
                            aria-label={formatMessage(messages.themeModeCheckboxAriaLabel)}
                            role="checkbox"
                            onChange={onThemeModeChange}
                            defaultChecked={currentUserSettings?.themeMode === ThemeMode.DarkMode}
                            labelPosition="above"
                        />
                    </div>
                </div>
            )}
            <div className={mergeClasses(stackStyles.item, headerStyles.root)}>
                <FormattedMessage
                    id="SettingsFormFieldGroup_DevBoxSectionHeader"
                    defaultMessage="Dev box"
                    description="Header for the section with dev box specific settings"
                />
            </div>
            <div className={stackStyles.item}>
                <Label>
                    <FormattedMessage
                        id="SettingsFormFieldGroup_UseWindowsAppCheckbox_Label"
                        defaultMessage="Connections"
                        description='Label for "Use Windows app " checkbox'
                    />
                </Label>
                <RadioGroup
                    aria-label={formatMessage(messages.useWindowsAppCheckboxAriaLabel)}
                    onChange={onPreferredRemoteAppChange}
                    value={currentUserSettings.preferredRemoteApp}
                >
                    {connectionOptions}
                </RadioGroup>
            </div>
            <div className={stackStyles.item}>
                <Label>
                    <FormattedMessage
                        id="SettingsFormFieldGroup_MultiMonitorPlaneCheckbox_Label"
                        defaultMessage="Display configuration"
                        description='Label for "Enable multi monitor support" checkbox'
                    />
                </Label>
                <RadioGroup
                    aria-label={formatMessage(messages.multiMonitorCheckboxAriaLabel)}
                    onChange={onMultiMonitorChange}
                    value={currentUserSettings.useMultiMonitor.toString()}
                >
                    {monitorOptions}
                </RadioGroup>
            </div>
        </div>
    );
};
