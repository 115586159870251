import {
    CheckboxVisibility,
    DetailsList,
    DetailsListLayoutMode,
    ICalloutProps,
    IColumn,
    IDetailsHeaderProps,
    IObjectWithKey,
    ISelection,
    Selection,
    SelectionMode,
    TextField,
    TooltipHost,
    TooltipOverflowMode,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useFormatter } from '../../../hooks/localization';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { ImageKey, ImageViewModel } from '../models';
import { selectDevBoxImageDetailsListMessages } from './messages';
import { ImageDetailsListItem } from './models';
import {
    formatLastImageUpdate,
    getImageDetailsListColumns,
    getImageDetailsListItems,
    getImageViewModelKey,
} from './selectors';

interface SelectDevBoxImageDetailsListProps {
    images: ImageViewModel[];
    selectedImage: ImageViewModel | undefined;
    setSelectedImage: (item: ImageViewModel | undefined) => void;
}

/**
 * Styles
 */

const useFilterTextFieldStyles = makeStyles({
    root: {
        width: '320px',
    },
});

const useDetailsListContainerStyles = makeStyles({
    root: {
        minHeight: '509px',
        maxHeight: '509px',
        overflowY: 'auto',
    },
});

const useDetailsListHeaderStyles = makeStyles({
    root: {
        paddingTop: 0,
    },
});

const useTooltipHostStyles = makeStyles({
    root: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const useContainerStyles = makeStyles({
    root: {
        gap: '51px',
    },
});

/**
 * End Styles
 */

const tooltipCalloutProps: ICalloutProps = { gapSpace: 0 };

export const SelectDevBoxImageDetailsList: React.FC<SelectDevBoxImageDetailsListProps> = (
    props: SelectDevBoxImageDetailsListProps
) => {
    const { images, selectedImage, setSelectedImage } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const detailsListContainerStyles = useDetailsListContainerStyles();
    const filterTextFieldStyles = useFilterTextFieldStyles();
    const headerStyles = useDetailsListHeaderStyles();
    const tooltipHostStyles = useTooltipHostStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();

    // State hooks
    const [filterText, setFilterText] = React.useState<string>('');
    const [isSortedDescending, setIsSortedDescending] = React.useState<boolean>(false);
    const [sortKey, setSortKey] = React.useState<ImageKey>(ImageKey.Name);

    // Formatter hooks
    const lastImageUpdateFormatter = useFormatter(formatLastImageUpdate);

    // Callback hooks
    const onRenderItem = React.useCallback(
        (item: ImageDetailsListItem, _index?: number, column?: IColumn): JSX.Element | null => {
            if (!column) {
                return null;
            }

            const { key } = column;
            const { columns } = item;
            const value = columns[key as ImageKey];

            if (isUndefinedOrWhiteSpace(value)) {
                return <span>--</span>;
            }

            switch (key) {
                // Wrap longer text fields in tooltips
                case ImageKey.ImageName:
                case ImageKey.Name:
                    return (
                        <TooltipHost
                            calloutProps={tooltipCalloutProps}
                            content={value}
                            overflowMode={TooltipOverflowMode.Self}
                            styles={tooltipHostStyles}
                        >
                            <span>{value}</span>
                        </TooltipHost>
                    );
                default:
                    return <span>{value}</span>;
            }
        },
        [tooltipHostStyles]
    );

    const onFilterImagesChange = React.useCallback(
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
            setFilterText(newValue ?? '');
        },
        []
    );

    const onColumnClick = React.useCallback(
        (_ev: unknown, column: IColumn): void => {
            const { key: clickedColumnKey } = column;

            // If sorted column is changing, ensure it's initially sorted ascending
            setIsSortedDescending(clickedColumnKey === sortKey ? !isSortedDescending : false);
            setSortKey(clickedColumnKey as ImageKey);
        },
        [sortKey, isSortedDescending]
    );

    const onSelectedImageChange = React.useCallback(
        (selectedItems: ImageDetailsListItem[]): void => {
            setSelectedImage(selectedItems[0]?.value);
        },
        [setSelectedImage]
    );

    const onRenderDetailsHeader = React.useCallback(
        (
            headerProps?: IDetailsHeaderProps,
            defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null
        ): JSX.Element | null => {
            if (!headerProps || !defaultRender) {
                return null;
            }

            return defaultRender({
                ...headerProps,
                styles: headerStyles,
            });
        },
        [headerStyles]
    );

    // Memo hooks
    const columns: IColumn[] = React.useMemo(
        () => getImageDetailsListColumns(isSortedDescending, formatMessage, sortKey, onColumnClick, onRenderItem),
        [sortKey, formatMessage, isSortedDescending, onColumnClick, onRenderItem]
    );

    const items: ImageDetailsListItem[] = React.useMemo(
        () => getImageDetailsListItems(sortKey, isSortedDescending, filterText, lastImageUpdateFormatter, images),
        [filterText, isSortedDescending, lastImageUpdateFormatter, images, sortKey]
    );

    const selection: ISelection<ImageDetailsListItem> = React.useMemo(() => {
        return new Selection<ImageDetailsListItem>({
            onSelectionChanged: () => {
                onSelectedImageChange(selection.getSelection());
            },
        });
    }, [onSelectedImageChange]);

    // keeping the selection state in sync with our form state on first load
    React.useEffect(() => {
        if (selectedImage !== undefined) {
            selection.setKeySelected(getImageViewModelKey(selectedImage), true, false);
        }
    }, []);

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <div className={stackStyles.item}>
                <TextField
                    placeholder={formatMessage(selectDevBoxImageDetailsListMessages.filterInputPlaceholder)}
                    ariaLabel={formatMessage(selectDevBoxImageDetailsListMessages.filterInputAriaLabel)}
                    autoFocus
                    onChange={onFilterImagesChange}
                    styles={filterTextFieldStyles}
                />
            </div>
            <div data-is-scrollable="true" className={mergeClasses(stackStyles.root, detailsListContainerStyles.root)}>
                <DetailsList
                    items={items}
                    compact={false}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    setKey="single"
                    selectionPreservedOnEmptyClick={true}
                    // Need this type coercion because DetailsLists don't support generics and while an ImageDetailsListItem is an IObjectWithKey an IObjectWithKey is not an ImageDetailsListItem
                    selection={selection as any as ISelection<IObjectWithKey>} // eslint-disable-line @typescript-eslint/no-explicit-any
                    checkboxVisibility={CheckboxVisibility.hidden}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                />
            </div>
        </div>
    );
};

export default SelectDevBoxImageDetailsList;
