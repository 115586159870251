import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { rootSaga as applicationSaga } from './application/root-saga';
import { rootSaga as azureDevOpsSaga } from './azure-dev-ops/root-saga';
import { rootSaga as customizationSaga } from './customization/root-saga';
import { rootSaga as devBoxActionSaga } from './dev-box-action/root-saga';
import { rootSaga as devBoxOperationSaga } from './dev-box-operation/root-saga';
import { rootSaga as devBoxRegionRecommendationSaga } from './dev-box-region-recommendation/root-saga';
import { rootSaga as devBoxSaga } from './dev-box/root-saga';
import { rootSaga as devCenterSaga } from './dev-center/root-saga';
import { rootSaga as environmentDefinitionSaga } from './environment-definition/root-saga';
import { rootSaga as environmentOperationSaga } from './environment-operation/root-saga';
import { rootSaga as environmentSaga } from './environment/root-saga';
import { rootSaga as featuresSaga } from './features/root-saga';
import { rootSaga as graphSaga } from './graph/root-saga';
import { rootSaga as identitySaga } from './identity/root-saga';
import { rootSaga as longRunningOperationSaga } from './long-running-operation/root-saga';
import { rootSaga as mocksSaga } from './mocks/root-saga';
import { rootSaga as permissionSaga } from './permission/root-saga';
import { rootSaga as poolSaga } from './pool/root-saga';
import { rootSaga as projectEnvironmentTypeAbilitiesSaga } from './project-environment-type-abilities/root-saga';
import { rootSaga as projectEnvironmentTypeFromDataPlaneSaga } from './project-environment-type-from-dataplane/root-saga';
import { rootSaga as projectEnvironmentTypeSaga } from './project-environment-type/root-saga';
import { rootSaga as projectsSaga } from './project/root-saga';
import { rootSaga as remoteConnectionSaga } from './remote-connection/root-saga';
import { rootSaga as scheduleSaga } from './schedule/root-saga';
import { rootSaga as snapshotSaga } from './snapshot/root-saga';
import { rootSaga as storageSaga } from './storage/root-saga';
import { rootSaga as subApplicationsSaga } from './sub-applications/root-saga';
import { rootSaga as subscriptionSaga } from './subscription/root-saga';
import { rootSaga as tenantSaga } from './tenant/root-saga';
import { rootSaga as timeSaga } from './time/root-saga';
import { rootSaga as userSettingsSaga } from './user-settings/root-saga';

export function* rootSaga(): SagaIterator {
    try {
        yield all([
            fork(applicationSaga),
            fork(devBoxSaga),
            fork(devBoxOperationSaga),
            fork(devCenterSaga),
            fork(environmentDefinitionSaga),
            fork(environmentOperationSaga),
            fork(environmentSaga),
            fork(featuresSaga),
            fork(graphSaga),
            fork(identitySaga),
            fork(mocksSaga),
            fork(permissionSaga),
            fork(poolSaga),
            fork(projectEnvironmentTypeSaga),
            fork(remoteConnectionSaga),
            fork(scheduleSaga),
            fork(snapshotSaga),
            fork(storageSaga),
            fork(subApplicationsSaga),
            fork(subscriptionSaga),
            fork(tenantSaga),
            fork(devBoxActionSaga),
            fork(timeSaga),
            fork(customizationSaga),
            fork(longRunningOperationSaga),
            fork(azureDevOpsSaga),
            fork(userSettingsSaga),
            fork(devBoxRegionRecommendationSaga),
            fork(projectsSaga),
            fork(projectEnvironmentTypeFromDataPlaneSaga),
            fork(projectEnvironmentTypeAbilitiesSaga),
        ]);
    } catch (err) {
        // yield put(err);
    }
}
