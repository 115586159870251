import { KeyValuePair } from '../../../types/key-value-pair';
import { groupAndReduceBy } from '../../../utilities/array';
import { getNumericDateStringFromDate } from '../../../utilities/time';
import { EnvironmentOperationViewModel } from '../models';

// Format date into MM/DD/YY and group operations by date
export const getGroupedOperationsByDate = (
    operations: EnvironmentOperationViewModel[]
): KeyValuePair<string, EnvironmentOperationViewModel[]>[] => {
    const key = (operation: EnvironmentOperationViewModel): string => {
        const options: Intl.DateTimeFormatOptions = {
            year: '2-digit',
        };

        return getNumericDateStringFromDate(operation.startTime, 'en-US', options) ?? '';
    };

    const reducer = (
        previousValue: EnvironmentOperationViewModel[],
        currentValue: EnvironmentOperationViewModel
    ): EnvironmentOperationViewModel[] => {
        return [...previousValue, currentValue];
    };

    const initialValue: EnvironmentOperationViewModel[] = [];

    return groupAndReduceBy(operations, key, reducer, initialValue);
};
