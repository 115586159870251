import { makeStyles, Spinner, tokens } from '@fluentui/react-components';
import {
    Checkmark20Regular,
    Circle20Regular,
    ErrorCircle20Regular,
    SubtractCircle20Regular,
} from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getTokensFromCustomizationGroupDataPlaneUri } from '../../ids/customization-group';
import { CustomizationTask, CustomizationTaskStatus } from '../../models/customization';
import { getCustomizationTaskLogs } from '../../redux/selector/customization-selectors';
import { SerializableMap } from '../../types/serializable-map';
import { get } from '../../utilities/serializable-map';
import { CustomizedTreeHeader } from '../common/customized-tree-header';

interface CustomizationTaskDetailsContainerProps {
    task?: CustomizationTask;
    customizationGroupUri: string;
    name: string;
}

interface CustomizationTaskDetailsProps extends CustomizationTaskDetailsContainerProps {
    taskLogs: SerializableMap<string>;
}

interface TaskIconProps {
    status?: CustomizationTaskStatus;
}

const messages = defineMessages({
    customizationTaskLogFileName: {
        id: 'CustomizationTaskDetails_CustomizationTaskLogFile_Name',
        defaultMessage: '{devBoxName}_{customizationGroupName}_{taskName}',
        description:
            'Name for the downloaded customization task log file. {devBoxName}, {customizationGroupName} and {taskName} should not be localized.',
    },
});

/**
 * Styles
 */

const useIconStyles = makeStyles({
    queued: {
        color: tokens.colorNeutralForeground3,
    },
    skipped: {
        color: tokens.colorNeutralForeground3,
    },
    error: {
        color: tokens.colorStatusDangerForeground1,
    },
    success: {
        color: tokens.colorStatusSuccessForeground1,
    },
});

/**
 * End Styles
 */

const TaskIcon: React.FC<TaskIconProps> = React.memo((props: TaskIconProps) => {
    const { status } = props;

    const iconStyles = useIconStyles();

    switch (status) {
        case CustomizationTaskStatus.Running:
            return <Spinner aria-label={'spinner'} size="extra-tiny" />;
        case CustomizationTaskStatus.Succeeded:
            return <Checkmark20Regular className={iconStyles.success} />;
        case CustomizationTaskStatus.FailedValidation:
        case CustomizationTaskStatus.TimedOut:
        case CustomizationTaskStatus.Failed:
            return <ErrorCircle20Regular className={iconStyles.error} />;
        case CustomizationTaskStatus.Skipped:
            return <SubtractCircle20Regular className={iconStyles.skipped} />;
        case CustomizationTaskStatus.NotStarted:
        default:
            return <Circle20Regular className={iconStyles.queued} />;
    }
});

export const CustomizationTaskHeader: React.FC<CustomizationTaskDetailsProps> = React.memo(
    (props: CustomizationTaskDetailsProps) => {
        const { task, customizationGroupUri, taskLogs, name } = props;
        const { status, endTime, startTime, logUri } = task ?? {};
        const { devBoxName, customizationGroupName } =
            getTokensFromCustomizationGroupDataPlaneUri(customizationGroupUri);

        // Intl hooks
        const { formatMessage } = useIntl();

        const taskLogUrl = React.useMemo(() => get(taskLogs, logUri), [taskLogs, logUri]);

        const getTaskLogFileName = React.useMemo(
            () =>
                formatMessage(messages.customizationTaskLogFileName, {
                    devBoxName,
                    customizationGroupName,
                    taskName: task?.name,
                }),
            [task, formatMessage]
        );

        const getStatusIcon = React.useMemo(() => {
            return <TaskIcon status={status} />;
        }, [status]);

        return (
            <CustomizedTreeHeader
                statusIcon={getStatusIcon}
                name={name}
                startTime={startTime}
                endTime={endTime}
                logUrl={taskLogUrl}
                logFileName={getTaskLogFileName}
            />
        );
    }
);

export const CustomizationTaskHeaderContainer: React.FC<CustomizationTaskDetailsContainerProps> = (
    props: CustomizationTaskDetailsContainerProps
) => {
    const { task, customizationGroupUri, name } = props;

    const taskLogs = useSelector(getCustomizationTaskLogs);

    return (
        <CustomizationTaskHeader
            taskLogs={taskLogs}
            task={task}
            customizationGroupUri={customizationGroupUri}
            name={name}
        />
    );
};

export default CustomizationTaskHeaderContainer;
