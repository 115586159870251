import { ContentType, Method, StatusCode } from '../../constants/http';
import { DataResponse, FailureOperation, FailureResponse } from '../../models/common';
import Settings from '../../settings/settings';
import { tryParseJson } from '../../utilities/resource-manager/parse-json';
import { AzureCoreFoundationsErrorContract } from '../contracts/common';
import { FetchOptions, FetchRetryOptions, fetchRequest } from './fetch-request';

export interface ResourceRequestOptions extends Omit<FetchOptions, 'accept' | 'retryOptions'> {
    operation?: FailureOperation;
    retryOptions?: Partial<FetchRetryOptions>;
}

// Note: using blank string for the message so that we don't accidentally propagate non-loc'd message to UI
const defaultIsErrorResponse = (response: Response) => response.status !== 200;

// TODO: Task 2088728: Devportal: Modify requests methods in DataLayer to consume URL instead of base and path seperately
export const resourceRequest = async (
    path: string,
    method: Method,
    accessToken: string,
    options?: ResourceRequestOptions,
    baseURL?: string,
    isErrorResponse?: (response: Response) => boolean
): Promise<DataResponse<Response>> => {
    const { retryOptions: providedRetryOptions } = options ?? {};
    const { statusCodes } = providedRetryOptions ?? {};
    const retryOptions: FetchRetryOptions = {
        retries: 3,
        // Note: This value may need to be modified per resource request type as necessary.
        // Currently this was based on the average response from ARM.
        backoff: 1000,
        backoffFactor: 2,
        ...options?.retryOptions,
        statusCodes: [StatusCode.ServiceUnavailable].concat(statusCodes ?? []),
    };

    const url = baseURL ?? Settings.AzureResourceBaseUrl;

    const result = await fetchRequest(`${url}${path}`, method, {
        ...options,
        accept: ContentType.ApplicationJson,
        retryOptions,
        accessToken,
    });

    const errorResponsePredicate = isErrorResponse ?? defaultIsErrorResponse;

    if (errorResponsePredicate(result)) {
        const errorText = await result.text();
        const errorResponse = tryParseJson<AzureCoreFoundationsErrorContract>(errorText);

        return FailureResponse({
            code: errorResponse?.error?.code,
            message: errorResponse?.error?.message,
            operation: options?.operation,
            statusCode: result.status,
        });
    }

    return {
        data: result,
        succeeded: true,
    };
};
