import { FeatureFlagName } from '../constants/features';
import { createInitializer } from '../utilities/initializer';

export type FeatureFlagSet = { [key in FeatureFlagName]: boolean };

export const FeatureFlagSet = createInitializer<FeatureFlagSet>({
    EnableBetaDataPlane: false,
    UnderDevelopment: false,
    UseNonPreauthorizedScopes: false,
    LogVerbose: false,
    EnableThemeModeToggle: false,
    SingleDevCenterMode: false,
    DisplayNames: false,
    RoundTripTime: false,
    EnableDiscoveryService: false,
    EnableIntrinsicTasksForCustomization: false,
    EnableGuestUser: false,
    EnableDevBoxRestoration: false,
    EnableOffloadDevBox: false,
});
