import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../../constants/telemetry';
import {
    AggregatedFailure,
    AggregatedResult,
    AggregatedSuccess,
    ClientError,
    DataResponse,
    FailureOperation,
    isAggregatedFailure,
    isAggregatedSuccess,
} from '../../../../models/common';
import { OneOrMany } from '../../../../types/one-or-many';
import { combineResults, getErrorCodes } from '../../../../utilities/aggregated-result';
import { aggregateFailureResponses } from '../../../../utilities/failure';
import { toMany } from '../../../../utilities/one-or-many';
import { trackTimedPerformanceMetric } from '../../../../utilities/telemetry/channel';
import { discoverEnvironmentDefinitionsForEnvironments } from '../../../actions/environment-definition/environment-definition-action-creators';
import { discoverEnvironmentOperationsForEnvironment } from '../../../actions/environment-operation/environment-operation-action-creators';
import { getGraphDirectoryObject } from '../../../actions/graph/directory-objects-action-creators';
import {
    loadEnvironmentCardContent,
    loadEnvironmentCardContentError,
    loadEnvironmentCardContentFailed,
    loadEnvironmentCardContentSuccess,
} from '../../../actions/sub-applications/home/home-action-creators';
import { LoadEnvironmentCardContentAction } from '../../../actions/sub-applications/home/home-actions';
import { createSagaError } from '../../../effects/create-saga-error';
import { putAndAwait } from '../../../effects/put-and-await';
import { rejectAction } from '../../../effects/reject-action';
import { resolveAction } from '../../../effects/resolve-action';
import { takeLeading } from '../../../effects/take';
import { AsyncOutcome } from '../../../store/common-state';
import { getOwnerIdsFromEnvironments } from './selectors';

function* loadGraphDirectoryObjects(): SagaIterator<AggregatedResult> {
    // Figure out which Graph object IDs we need to request metadata for
    const ownerIds: string[] = yield select(getOwnerIdsFromEnvironments);

    // If there aren't any owner IDs, avoid doing anything and immediately succeed
    if (ownerIds.length < 1) {
        return AggregatedSuccess();
    }

    const responses: OneOrMany<DataResponse> = yield putAndAwait(
        getGraphDirectoryObject(ownerIds.map((id) => ({ id })))
    );

    return combineResults(toMany(responses));
}

function* terminateWithFailure(
    action: LoadEnvironmentCardContentAction,
    aggregatedFailure: AggregatedFailure,
    startTime: Date
): SagaIterator {
    const { failures } = aggregatedFailure;
    const failure = aggregateFailureResponses(FailureOperation.LoadEnvironmentCardContent, ...failures);
    yield put(loadEnvironmentCardContentFailed({ failure }));
    yield resolveAction(action, aggregatedFailure);
    yield call(
        trackTimedPerformanceMetric,
        PerformanceMetric.LoadEnvironmentCardContent,
        startTime,
        AsyncOutcome.Failed,
        { errorCodes: getErrorCodes(aggregatedFailure) }
    );
}

export function* loadEnvironmentCardContentSaga(action: LoadEnvironmentCardContentAction): SagaIterator {
    const startTime = new Date();

    try {
        const [objectIdResult, environmentDefinitionsResult, environmentOperationsResult]: [
            AggregatedResult,
            AggregatedResult,
            AggregatedResult
        ] = yield all([
            call(loadGraphDirectoryObjects),
            putAndAwait(discoverEnvironmentDefinitionsForEnvironments()),
            putAndAwait(discoverEnvironmentOperationsForEnvironment()),
        ]);

        if (isAggregatedFailure(objectIdResult)) {
            yield call(terminateWithFailure, action, objectIdResult, startTime);
            return;
        }

        if (isAggregatedFailure(environmentDefinitionsResult)) {
            yield call(terminateWithFailure, action, environmentDefinitionsResult, startTime);
            return;
        }

        if (isAggregatedFailure(environmentOperationsResult)) {
            yield call(terminateWithFailure, action, environmentOperationsResult, startTime);
            return;
        }

        const completeResult = combineResults([
            objectIdResult,
            environmentDefinitionsResult,
            environmentOperationsResult,
        ]);

        yield put(loadEnvironmentCardContentSuccess());
        yield resolveAction(action, completeResult);

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadEnvironmentCardContent,
            startTime,
            completeResult.outcome,
            isAggregatedSuccess(completeResult) ? undefined : { errorCodes: getErrorCodes(completeResult) }
        );
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadEnvironmentCardContent);
        yield put(loadEnvironmentCardContentError({ error }));
        yield rejectAction(action, error);
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadEnvironmentCardContent,
            startTime,
            AsyncOutcome.Error,
            { errorCodes: [error.code] }
        );
    }
}

export function* loadEnvironmentCardContentListenerSaga(): SagaIterator {
    yield takeLeading(loadEnvironmentCardContent, loadEnvironmentCardContentSaga);
}
