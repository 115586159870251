import { UnionMap } from '../../../types/union-map';

export type RepairOperationResultOutcome = 'FixApplied' | 'IssuesDetected' | 'NoIssuesDetected';

export const RepairOperationResultOutcome: UnionMap<RepairOperationResultOutcome> = {
    FixApplied: 'FixApplied',
    IssuesDetected: 'IssuesDetected',
    NoIssuesDetected: 'NoIssuesDetected',
};

export type DevBoxCardInfoBannerMessageKind =
    | 'RepairOperationInProgressMessage'
    | 'RestoreOperationInProgressMessage'
    | 'CustomizeOperationInProgressMessage'
    | 'NoIssuesDetectedMessage'
    | 'OffloadDevBoxMessage'
    | 'None';

export const DevBoxCardInfoBannerMessageKind: UnionMap<DevBoxCardInfoBannerMessageKind> = {
    RepairOperationInProgressMessage: 'RepairOperationInProgressMessage',
    RestoreOperationInProgressMessage: 'RestoreOperationInProgressMessage',
    CustomizeOperationInProgressMessage: 'CustomizeOperationInProgressMessage',
    NoIssuesDetectedMessage: 'NoIssuesDetectedMessage',
    OffloadDevBoxMessage: 'OffloadDevBoxMessage',
    None: 'None',
};

export type DevBoxCardSuccessBannerMessageKind =
    | 'SkipStateSuccessMessage'
    | 'DelayStateSuccessMessage'
    | 'DelayStateDayAfterNextSuccessMessage'
    | 'DelayStateNextDaySuccessMessage'
    | 'IssuesFixedMessage'
    | 'None';

export const DevBoxCardSuccessBannerMessageKind: UnionMap<DevBoxCardSuccessBannerMessageKind> = {
    SkipStateSuccessMessage: 'SkipStateSuccessMessage',
    DelayStateSuccessMessage: 'DelayStateSuccessMessage',
    DelayStateDayAfterNextSuccessMessage: 'DelayStateDayAfterNextSuccessMessage',
    DelayStateNextDaySuccessMessage: 'DelayStateNextDaySuccessMessage',
    IssuesFixedMessage: 'IssuesFixedMessage',
    None: 'None',
};

export type DevBoxStatusImageKind = 'Running' | 'Stopped' | 'Stopping';

export const DevBoxStatusImageKind: UnionMap<DevBoxStatusImageKind> = {
    Running: 'Running',
    Stopped: 'Stopped',
    Stopping: 'Stopping',
};
