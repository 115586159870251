import {
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    FluentProvider,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../../hooks/action-creator';
import { useUserSettingsPanelContext } from '../../../hooks/context/panels';
import { useCurrentFluent2Theme } from '../../../hooks/styling';
import { setUserSettings } from '../../../redux/actions/user-settings/user-settings-action-creator';
import { getUserSettingsSelector } from '../../../redux/selector/user-settings-selector';
import { ReturnVoid } from '../../../types/return-void';
import { UserSettings } from '../../../types/user-settings';
import { getUserSettingsPanelInitialValues } from '../selectors';
import { UserSettingsFormFieldGroup } from './user-settings-form-field-group';

type UserSettingsPanelComponentProps = {
    currentUserSettings: UserSettings;
    isOpen: boolean;
    onDismiss: () => void;
    onSubmit: ReturnVoid<typeof setUserSettings>;
};

const messages = defineMessages({
    userSettingsPanelCloseButtonAriaLabel: {
        id: 'UserSettingsPanel_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for user settings panel close button',
    },
});

const UserSettingsPanelComponent: React.FC<UserSettingsPanelComponentProps> = (
    props: UserSettingsPanelComponentProps
) => {
    const { currentUserSettings, isOpen, onDismiss, onSubmit } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const theme = useCurrentFluent2Theme();

    // Memoized data
    const initialValues = React.useMemo(
        () => getUserSettingsPanelInitialValues(currentUserSettings),
        [currentUserSettings]
    );

    return (
        <FluentProvider theme={theme}>
            <Drawer open={isOpen} position="end" onOpenChange={onDismiss}>
                <DrawerHeader>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label={formatMessage(messages.userSettingsPanelCloseButtonAriaLabel)}
                                icon={<Dismiss24Regular />}
                                onClick={onDismiss}
                            />
                        }
                    >
                        <FormattedMessage
                            id="UserSettingsPanel_Header_Text"
                            defaultMessage="User settings"
                            description="Header for the user settings menu"
                        />
                    </DrawerHeaderTitle>
                </DrawerHeader>
                <DrawerBody>
                    <UserSettingsFormFieldGroup currentUserSettings={initialValues} submit={onSubmit} />
                </DrawerBody>
            </Drawer>
        </FluentProvider>
    );
};

const UserSettingsPanelContainer: React.FC = () => {
    // Application state hooks
    const currentUserSettings = useSelector(getUserSettingsSelector);

    // Action hooks
    const onSubmit = useActionCreator(setUserSettings);

    // Context hooks
    const { closeSurface: closePanel, isOpen } = useUserSettingsPanelContext();

    return (
        <UserSettingsPanelComponent
            currentUserSettings={currentUserSettings}
            isOpen={isOpen}
            onDismiss={closePanel}
            onSubmit={onSubmit}
        />
    );
};

export const UserSettingsPanelContextWrapper: React.FC = () => {
    const { isOpen } = useUserSettingsPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <UserSettingsPanelContainer />;
};

export default UserSettingsPanelContextWrapper;
